import { Portal } from "@mui/material";
import React, { useState } from "react";
import useSnackbar from "src/components/common/hooks/useSnackbar";
import { INITIATE_CALL_WENT_WRONG, RECEIVE_CALL_SHORTLY } from "src/phrasing";
import { voiceService } from "src/services";
import { SEVERITY_VARIANTS } from "src/utils";
import CallMeDialog from "./CallMeDialog";
import HyroFeedbackMessage from "src/components/hyro-components/HyroFeedbackMessage";
import useAppStore from "src/stores/appStore";
import { pageNameSelector, selectedAccountIdSelector, assistantIdSelector } from "src/stores/selectors/appSelectors";
import { datadogRum } from "@datadog/browser-rum";
import { CUSTOM_ACTIONS } from "src/analytics/notifyCustomActions";

const CallMeManager = ({ phoneDialogOpen, setPhoneDialogOpen, branchName, hideBackdrop, PaperProps, variant }) => {
  const [callRequestSent, setCallRequestSent] = useState(false);
  const pageName = useAppStore(pageNameSelector);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [
    isSnackbarOpen,
    snackbarMessage,
    addSnackbarMessage,
    exitSnackbar,
    closeSnackbar
  ] = useSnackbar();
  const accountId = useAppStore(selectedAccountIdSelector);
  const assistantId = useAppStore(assistantIdSelector);

  const handleCallMe = async ({ phoneNumber, callerId, site }) => {
    try {
      datadogRum.addAction(CUSTOM_ACTIONS.CALL_ME_CLICKED, { accountId, assistantId });

      setCallRequestSent(true);
      const data = { phoneNumber, callerId, site, branchName };
      await voiceService.initiateCall(data, { pageName });
      setCallRequestSent(false);
      setPhoneDialogOpen?.(false);
      setSnackbarSeverity(SEVERITY_VARIANTS.SUCCESS);
      addSnackbarMessage(RECEIVE_CALL_SHORTLY);
    } catch (e) {
      setCallRequestSent(false);
      setSnackbarSeverity(SEVERITY_VARIANTS.ERROR);
      addSnackbarMessage(INITIATE_CALL_WENT_WRONG);
    }
  };

  return (
    <>
      {phoneDialogOpen ? (
        <CallMeDialog
          callRequestSent={callRequestSent}
          isOpen={phoneDialogOpen}
          onClose={() => setPhoneDialogOpen?.(false)}
          handleCallMe={handleCallMe}
          hideBackdrop={hideBackdrop}
          PaperProps={PaperProps}
          variant={variant}
        />
      ) : null}
      <Portal>
        <HyroFeedbackMessage
          message={snackbarMessage}
          severity={snackbarSeverity}
          showFeedback={isSnackbarOpen}
          onExited={exitSnackbar}
          closeFeedback={closeSnackbar}
        />
      </Portal>
    </>
  );
};

export default CallMeManager;
