import { Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import SearchBar from "src/components/common/SearchBar";
import { useIsMobile, useStatusBarDisplayed } from "src/hooks";
import { APP_STATUS_BAR_HEIGHT } from "src/layout/consts";

const StyledHeader = styled("div")(({ theme, showStatusBar }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  flexWrap: "wrap",
  gap: theme.spacing(2),
  maxWidth: 1640,
  width: "100%",
  margin: `${showStatusBar ? `${APP_STATUS_BAR_HEIGHT - 2}px`: "8px"} 0 16px 0`
}));

const StyledWelcome = styled("span")(({ theme }) => ({
  fontSize: "24px",
  fontWeight: "bold",
  color: theme.palette.primary.main
}));

const StyledName = styled("span")(({ theme }) => ({
  fontSize: "26px",
  fontWeight: "bold",
  fontStyle: "italic",
  fontFamily: "Source Serif Pro",
  color: theme.palette.secondary.main
}));

const StyledHelloIcon = styled("span")(() => ({
  fontSize: "24px"
}));

const StyledSearchBar = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%"
  }
}));

const Header = ({ firstName, accountName, showSearch, setSearchText }) => {
  const isMobile = useIsMobile();
  const showStatusBar = useStatusBarDisplayed();

  return (
    <StyledHeader showStatusBar={showStatusBar}>
      <Box>
        <Typography variant="h4" component="h1">
          <StyledWelcome>Welcome{" "}</StyledWelcome>
          <StyledName>{firstName}{" "}</StyledName>
          <StyledHelloIcon>👋</StyledHelloIcon>
        </Typography>
        <Typography variant="body2" color="text.secondary">Here's an overview of your {accountName} assistants</Typography>
      </Box>

      {showSearch && <StyledSearchBar>
        <SearchBar stayOpen={isMobile} customWidth={isMobile ? "100%" : null} onChange={setSearchText} />
        </StyledSearchBar>
      }
    </StyledHeader>
  );
};

export default Header;
