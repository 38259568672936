
import { Typography } from "@mui/material";
import React from "react";
import { getLanguageIcon } from "src/components/collections/consts";
import { getMandatoryStar } from "src/components/collections/helpers";
import { StyledTextField } from "src/components/common/styles";
import { capitalizeAndCleanUnderscore, toLowerCaseAndCapitalize } from "src/utils";
import StatusChip from "src/components/collections/StatusChip";
const MultiLanguageDrawer = ({ value, title, errorText, textFieldProps, isReadOnly, onChange, currentSelectedLanguage, collectionLanguages, fieldType, index, testId, isMandatory }) => (
  <>
    {collectionLanguages?.length > 1
      ?
      Object.keys(value).map((language, index) => {
        const LanguageIcon = getLanguageIcon(language);
        const hasError = !!errorText?.[language];

        return (
          <div key={index}>
            {index !== 0 &&
              <Typography variant={"body2"} color={"text.primary"} sx={{ pb: 1 }}>
                {toLowerCaseAndCapitalize(language) + " " + capitalizeAndCleanUnderscore(title)}{getMandatoryStar(isMandatory)}
              </Typography>}
            <StyledTextField
              {...textFieldProps}
              helperText={hasError ?
                <Typography sx={{ whiteSpace: "pre-line" }} variant="caption">{errorText[language]}</Typography> :
                <StatusChip status={textFieldProps.status}/>}
              error={hasError}
              inputProps={{ autoComplete: "off", "data-testid": `${testId ? `${testId}-`:""}${fieldType}-${language}-${index}` }}
              InputProps={{
                sx: { borderRadius: "4px" },
                readOnly: isReadOnly,
                endAdornment: <LanguageIcon style={{ marginLeft: "12px" }}/>
              }}
              value={value?.[language] ?? ""}
              onChange={({ target }) => !isReadOnly && onChange({ ...value, [language]: target.value }, language)}
              onBlur={({ target }) => !isReadOnly && onChange({ ...value, [language]: target.value.trim() }, language)}
            />
          </div>);
      })
      :
      <StyledTextField
        {...textFieldProps}
        helperText={textFieldProps?.error ?
          <Typography sx={{ whiteSpace: "pre-line" }} variant="caption">{errorText[currentSelectedLanguage]}</Typography> :
          <StatusChip status={textFieldProps.status}/>}
        inputProps={{ autoComplete: "off", "data-testid": `${testId ? `${testId}-`:""}${fieldType}-${index}` }}
        InputProps={{
          sx: { borderRadius: "4px" },
          readOnly: isReadOnly
        }}
        value={value?.[currentSelectedLanguage] ?? ""}
        onChange={({ target }) => !isReadOnly && onChange({ ...value, [currentSelectedLanguage]: target.value }, currentSelectedLanguage)}
        onBlur={({ target }) => !isReadOnly && onChange({ ...value, [currentSelectedLanguage]: target.value.trim() }, currentSelectedLanguage)}
      />
    }
  </>
);

export default MultiLanguageDrawer;
