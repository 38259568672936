import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import PlaceholderPage from "./PlaceholderPage";
import useAppStore from "../stores/appStore";
import {
  assistantsSelector,
  fetchingAssistantsSelector,
  selectedAccountIdSelector,
  setSelectedAssistantByIdSelector,
  setHeaderSkeletonSelector,
  setSystemModeSelector,
  userDataSelector, setSelectedAccountSelector
} from "../stores/selectors/appSelectors";
import { SYSTEM_MODES } from "../stores/slices/createAppSlice";
import { ASSISTANT_TYPES, assistantComparator } from "../utils";
import { useAccounts } from "src/queries/hooks/accounts";
import Header from "src/components/widgets/my-assistants/Header";
import EmptyState from "src/components/widgets/my-assistants/EmptyState";
import AssistantsList from "src/components/widgets/my-assistants/AssistantsList";
import debounce from "lodash/debounce";

const MIN_ASSISTANTS_FOR_SEARCH = 6;

// eslint-disable-next-line complexity, max-lines-per-function
const AccountAssistants = () => {
  const accounts = useAccounts()?.data;
  const selectedAccountId = useAppStore(selectedAccountIdSelector);
  const accountIds = accounts?.map(account => account.accountId);
  const appAssistants = useAppStore(assistantsSelector);
  const fetchingAssistants = useAppStore(fetchingAssistantsSelector);
  const setShowHeaderSkeleton = useAppStore(setHeaderSkeletonSelector);
  const setSystemMode = useAppStore(setSystemModeSelector);
  const setSelectedAssistantId = useAppStore(setSelectedAssistantByIdSelector);
  const setSelectedAccount = useAppStore(setSelectedAccountSelector);
  const accountId = useAppStore(selectedAccountIdSelector);
  const userData = useAppStore(userDataSelector);
  const [searchText, setSearchText] = useState("");
  const [sortedAssistants, setSortedAssistants] = useState([]);
  const theme = useTheme();
  const matchMediaQuery = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`);

  const debouncedSetSearchText = useMemo(() => debounce(setSearchText, 300), []);

  const filteredAssistants = useMemo(() => sortedAssistants?.filter((assistant) => {
      const searchVal = searchText.toLowerCase().trim();

      return assistant.assistantName?.toLowerCase().includes(searchVal) || assistant.id.includes(searchVal) || assistant.assistantFolder.includes(searchVal);
    }), [sortedAssistants, searchText]);

  const [activeAssistants, nonActiveAssistants] = useMemo(() => {
    const active = [];
    const nonActive = [];

    filteredAssistants.forEach((assistant) => {
      if (assistant.type === ASSISTANT_TYPES.NO_LONGER_ACTIVE) {
        nonActive.push(assistant);
      } else {
        active.push(assistant);
      }
    });
    return [active, nonActive];
  }, [filteredAssistants]);

  useEffect(() => () => {
    debouncedSetSearchText.cancel();
  }, [debouncedSetSearchText]);

  useEffect(() => {
    if (appAssistants?.length) {
      const tempAssistants = appAssistants
        .map((assistant) => ({
          ...assistant,
          assistantName: assistant.name,
          assistantChannel: assistant.channel
        }))
        .map(({ name, channel, ...rest }) => rest)
        .sort(assistantComparator);

      setSortedAssistants(tempAssistants);
    }
  }, [appAssistants]);

  useEffect(() => {
    if (accountId && appAssistants.length === 0) { setSelectedAccount(accountId); }
  }, [accountId, appAssistants, setSelectedAccount]);

  useEffect(() => {
    setSystemMode(SYSTEM_MODES.READ_ONLY);
    setSelectedAssistantId(null);

    if (appAssistants?.length === 0) {
      setShowHeaderSkeleton(false);
    }
  }, [appAssistants, setShowHeaderSkeleton, setSystemMode, setSelectedAssistantId]);

  if (appAssistants?.length === 0 && !fetchingAssistants) {
    return <PlaceholderPage height={"calc(100vh - 330px)"} />;
  }

  const firstName = userData.given_name ? userData.given_name : userData.name?.split(" ")[0];
  const accountName = accounts?.find(({ accountId }) => accountId === selectedAccountId)?.name;
  const showSkeleton = ((accountIds?.length && !appAssistants) || fetchingAssistants) && !activeAssistants?.length;
  const showEmptyState = !filteredAssistants?.length && !!searchText && !showSkeleton;
  const skeletonAssistants = Array(matchMediaQuery ? 8 : 6).fill({}).map((_, index) => ({ id: index + "" }));

  return (
    <>
      <Header firstName={firstName} accountName={accountName} showSearch={appAssistants?.length > MIN_ASSISTANTS_FOR_SEARCH} setSearchText={debouncedSetSearchText} />

      <div>
        {showSkeleton ? <AssistantsList assistants={skeletonAssistants} showSkeleton /> : <AssistantsList assistants={activeAssistants} testId="active-assistants-container" />}

        {!!nonActiveAssistants?.length && <>
          <Typography sx={{ maxWidth: 1640, margin: "auto" }} variant="subtitle2" color="grey.700">
            Deactivated assistants
          </Typography>
          <AssistantsList assistants={nonActiveAssistants} testId="non-active-assistants-container" />
        </>}
      </div>

      {showEmptyState && <EmptyState />}
    </>
  );
};

export default AccountAssistants;
