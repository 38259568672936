import {
  buttonBaseClasses,
  Checkbox,
  Divider, paperClasses,
  Typography
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { capitalizeAndCleanUnderscore } from "../../../utils";
import styled from "@emotion/styled";
import { StyledTextField } from "../styles";
import ChevronDown from "../../../icons/ChevronDown";
import { noOptionList } from "src/components/collections/helpers";
import SearchBar from "src/components/common/SearchBar";
import MenuItem from "@mui/material/MenuItem";
import ChipContainer from "src/pages/KnowledgeEditor/Table/ChipContainer";
import { shouldShowSearchField } from "src/components/common/fields/utils";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const indeterminateIcon = <IndeterminateCheckBoxIcon fontSize="small" />;

// eslint-disable-next-line max-lines-per-function, complexity
const MultipleOptions = ({
  id,
  options,
  label,
  value = [],
  onChange,
  error,
  isReadOnly,
  helperText,
  disabled,
  isFieldReadOnlyFromSpec,
  showLabel,
  testId,
  open = false,
  onFocus,
  onBlur,
  SelectProps = {},
  sx
}) => {
  const getLocalValues = () => {
    // if value comes as a list of strings (happens in inline table editing) then we want to turn the local values into a list of objects
    if (value && value.length > 0 && typeof value[0] === "string") {
      return value.map((val) => options.find(({ key, display_key }) => key.toLowerCase() === val.toLowerCase() || display_key.toLowerCase() === val.toLowerCase())).filter(Boolean);
    }
    return value;
  };

  const [menuOpen, setMenuOpen] = useState(open);
  const [searchText, setSearchText] = useState("");
  const inputRef = useRef(null);

  const localValue = getLocalValues();

  const filteredOptions = useMemo(() => options.filter(option => option.display_key.toLowerCase().includes(searchText.toLowerCase())),
    [options, searchText]);

  const textValues = localValue?.map((valueItem) => capitalizeAndCleanUnderscore(valueItem?.display_key)) || [];

  const handleBlur = () => {
    setMenuOpen(false);
  };

  const handleFocus = () => {
    setMenuOpen(true);
  };

  const optionsExist = options.length > 0;
  const showSearch = shouldShowSearchField(options);
  const noOptions = noOptionList();

  useEffect(() => {
    if (menuOpen) {
      onFocus?.(inputRef.current);
    } else {
      onBlur?.();
    }
  }, [menuOpen, onFocus, onBlur]);

  return (
    <>
      <StyledTextField
        disabled={disabled}
        readOnly={isReadOnly}
        isFieldReadOnlyFromSpec={isFieldReadOnlyFromSpec}
        data-testid={testId}
        InputProps={{
          sx: { borderRadius: "8px", backgroundColor: "white" },
          readOnly: isReadOnly
        }}
        SelectProps={{
          multiple: true,
          renderValue: () => <ChipContainer values={textValues} testId={testId} isReadOnly={isReadOnly} showAllChips={isReadOnly} />,
          onChange: (e) => {
            if (isReadOnly) return;

            if (e.target.value.includes("select-all")) {
              onChange(localValue.length === filteredOptions.length ? [] : onChange(filteredOptions));
            } else {
              onChange(e.target.value);
            }
          },
          inputRef,
          MenuProps: {
            autoFocus: false,
            sx: { ...MenuSx, ...SelectProps?.MenuProps?.sx },
            elevation: 3,
            PaperProps: {
              style: {
                ...SelectProps?.MenuProps?.PaperProps?.style,
                maxHeight: "315px"
              }
            }
          },
          IconComponent: (props) => <ChevronDown {...props} style={{ top: "unset", right: 12 }} />,
          open: menuOpen,
          onClose: () => {
            handleBlur();
            setSearchText("");
          },
          onOpen: handleFocus
        }}
        label={showLabel ? label : ""}
        error={!!error}
        FormHelperTextProps={{ "data-testid": `${testId}-helper` }}
        helperText={helperText ?? ""}
        select
        fullWidth
        sx={sx}
        id={id}
        value={localValue || ""}
      >
        {showSearch && <StyledSearchBar>
          <SearchBar stayOpen onChange={setSearchText} customWidth="100%"/>
        </StyledSearchBar>}

        {optionsExist && filteredOptions.length !== 0 && <StyledMenuItem
          value={"select-all"}
          data-testid={`${testId}-option-select-all`}>
          <Checkbox
            icon={icon}
            disableRipple
            checkedIcon={checkedIcon}
            indeterminateIcon={indeterminateIcon}
            indeterminate={filteredOptions.length > localValue.length && localValue.length > 0}
            style={{ marginRight: 4 }}
            checked={localValue.length === filteredOptions.length}
          />
          <Typography variant="body1">
           Select All ({filteredOptions.length})
          </Typography>
        </StyledMenuItem>}

        {optionsExist && filteredOptions.length !== 0 && <Divider sx={{ borderColor: "rgba(45, 44, 68, 0.12)", marginBlock: "4px !important" }}/>}

        {filteredOptions?.map((option, index) => (
          <StyledMenuItem
            key={option.key}
            data-testid={`${testId}-option-${index}`}
            value={option}>
            <Checkbox
              icon={icon}
              disableRipple
              checkedIcon={checkedIcon}
              style={{ marginRight: 4 }}
              checked={localValue.some(val => val.key === option.key)}
            />
            <Typography variant="body1">
              {capitalizeAndCleanUnderscore(option.display_key)}
            </Typography>
          </StyledMenuItem>
        ))}

        {(filteredOptions.length === 0 || !optionsExist) && noOptions.map((option) => (
          <StyledMenuItem
            key={option.key}
            data-testid={"no-options"}
            disabled={option.disabled}
            error={option.error}>
            <Typography variant="body1">
              {option.display_key}
            </Typography>
          </StyledMenuItem>
        ))}

      </StyledTextField>
    </>
  );
};

const MenuSx = {
  marginTop: "4px",
  [`.${buttonBaseClasses.root}[aria-selected='true']`]: {
    background: "white"
  },
  [`.${buttonBaseClasses.root}[aria-selected='true']:hover`]: {
    background: "white"
  }
};

const StyledMenuItem = styled(MenuItem)(({ theme, error }) => ({
  "color": error && theme.palette.error.main,
  padding: "3px 4px",
  borderRadius: "8px",
  "&:not(:last-child)": {
    marginBottom: "2px"
  }
}));

const StyledSearchBar = styled("div")({
  padding: "0px 8px 4px 8px",
  width: "100%"
});
export default MultipleOptions;
