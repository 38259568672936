
export const BRANCH_DEPLOYER_FIELDS = [
  { id: "releaseName", label: "Release Name" },
  { id: "dashboardClientTag", label: "Dashboard Client" },
  { id: "dashboardServerTag", label: "Dashboard Server" },
  { id: "configurationServiceTag", label: "Configuration Service" },
  { id: "bot", label: "Assistants Name" },
  { id: "assistantsTag", label: "Assistants" },
  { id: "nluRuntimeTag", label: "NLU Runtime" },
  { id: "widgetServerTag", label: "Widget Server" },
  { id: "widgetClientTag", label: "Widget Client" },
  { id: "nluRuntimeModes", label: "NLU Runtime Modes", options: ["preview", "dedicated", "api"] },
  { id: "ttlHours", label: "TTL (hours)" }
];

export const BRANCH_DEPLOYER_PRODUCTION_FIELDS = [
  { id: "releaseName", label: "Release Name" },
  { id: "bot", label: "Assistants Name" },
  { id: "assistantsTag", label: "Assistants" },
  { id: "ttlHours", label: "TTL (hours)" }
];

export const VIEW_DETAILS_FIELDS = [
  { id: "dashboardServerUrl", label: "Dashboard Server url" },
  { id: "dashboardClientUrl", label: "Dashboard Client url" },
  { id: "assistantsApiUrl", label: "Assistants API url" },
  { id: "assistantsPreviewUrl", label: "Assistants preview url" },
  { id: "assistantsUrl", label: "Assistants url" },
  { id: "widgetServerUrl", label: "Widget Server url" },
  { id: "widgetClientUrl", label: "Widget Client url" }
];

export const BRANCH_DEPLOYER_INITIAL_DATA = {
  releaseName: "",
  dashboardClientTag: "",
  dashboardServerTag: "",
  configurationServiceTag: "",
  bot: "",
  assistantsTag: "",
  nluRuntimeTag: "",
  widgetServerTag: "",
  widgetClientTag: "",
  nluRuntimeModes: [],
  ttlHours: "48"
};

export const FIELD_TYPES = {
  RELEASE_NAME: "releaseName",
  DASHBOARD_CLIENT: "dashboardClientTag",
  DASHBOARD_SERVER: "dashboardServerTag",
  CONFIGURATION_SERVICE: "configurationServiceTag",
  BOT: "bot",
  ASSISTANTS: "assistantsTag",
  NLU_RUNTIME: "nluRuntimeTag",
  WIDGET_SERVER: "widgetServerTag",
  WIDGET_CLIENT: "widgetClientTag",
  NLU_RUNTIME_MODES: "nluRuntimeModes",
  TTL: "ttlHours"
};

export const COLUMNS = {
  releaseName: { id: "releaseName", label: "Release Name" },
  link: { id: "dashboardClientUrl", label: "Dashboard" },
  chat: { id: "snippet", label: "Snippet" },
  voice: { id: "snippet", label: "Voice" },
  expired: { id: "expired", label: "Expires in" },
  services: { id: "services", label: "Services" },
  userEmail: { id: "createdBy", label: "Creator" },
  editorEmail: { id: "lastUpdatedBy", label: "Last Editor" },
  status: { id: "status", label: "Status" }
};

export const KEYS_TO_FILTER = Object.keys(BRANCH_DEPLOYER_INITIAL_DATA);
export const DIALOG_TYPES = {
  create: { id: "create", display: "Create" },
  edit: { id: "edit", display: "Edit" },
  reactivate: { id: "reactivate", display: "Reactivate" },
  viewDetails: { id: "viewDetails", display: "View Details" },
  deactivate: { id: "deactivate", display: "Deactivate" },
  refresh:{ id: "refresh", display: "Refresh Status" }
};

export const CREATE_DIALOG = {
  title: "Create new branch deployer",
  subtitle: "Please enter the following details to create a new branch deployer.",
  buttonText: "Create"
};

export const EDIT_DIALOG = {
  title: "Update selected branch deployer",
  subtitle: "You are about to make some changes to a branch deployer",
  buttonText: "Save"
};

export const REACTIVATE_DIALOG = {
  title: "Reactivate selected branch deployer",
  subtitle: "You are about to reactivate the branch deployer $branchDeployerName.",
  buttonText: "Reactivate"
};

export const DEACTIVATE_DIALOG = {
  title: "Deactivate selected branch deployer",
  subtitle: "Are you sure you want to deactivate the branch deployer $branchDeployerName?",
  buttonText: "Deactivate"
};

export const VIEW_DETAILS_DIALOG = {
  title: "Branch Deployer Details",
  subtitle: "Here are the details of the branch deployer $branchDeployerName."
};

export const COPY_SNIPPET_SUCCESS_MESSAGE = "Successfully copied snippet";

export const STATUS_TYPES = {
  LIVE: "Live",
  PENDING: "Pending",
  EXPIRED: "Expired",
  TERMINATED: "Terminated",
  KG_UPDATE: "KG Update",
  FAILED: "Failed"
};
export const BD_STATUS_TO_CHIP = {
  [STATUS_TYPES.LIVE]: {
    backgroundColorPath: ["success", "alert", "background"],
    textColorPath: ["success", "dark"]
  },
  [STATUS_TYPES.PENDING]: {
    backgroundColorPath: ["warning", "light"],
    textColorPath: ["warning", "dark"]
  },
  [STATUS_TYPES.EXPIRED]: {
    backgroundColorPath: ["grey", "200"],
    textColorPath: ["text", "disabled"]
  },
  [STATUS_TYPES.TERMINATED]: {
    backgroundColorPath: ["error", "light"],
    textColorPath: ["error", "dark"]
  },
  [STATUS_TYPES.KG_UPDATE]: {
    backgroundColorPath: ["warning", "light"],
    textColorPath: ["warning", "dark"]
  },
  [STATUS_TYPES.FAILED]: {
    backgroundColorPath: ["error", "light"],
    textColorPath: ["error", "dark"]
  }
};
