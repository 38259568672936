import { createTheme } from "@mui/material";
import { APP_HEADER_HEIGHT, APP_MOBILE_HEADER_HEIGHT } from "src/layout/consts";

const theme = createTheme();
export default createTheme({
  breakpoints:{
    desktop: 1920
  },
  mixins: {
    toolbar: {
      [theme.breakpoints.down("sm")]: {
        minHeight: APP_MOBILE_HEADER_HEIGHT
      },
      [theme.breakpoints.up("sm")]: {
        minHeight: APP_HEADER_HEIGHT
      },
      backgroundColor: "#ffffff"
    }
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: "0px"
        },
        paper: {
          padding: "8px"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          padding: "8px 12px",
          fontSize: "14px"
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: "0px 4px 8px 0px #0000001F, 0px 2px 2px 2px #00000003"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          paddingInline: "16px"
        }
      }
    }
  },
  palette: {
    action: {
      active: "rgba(45, 44, 68, 0.7)",
      hover: "rgba(45, 44, 68, 0.04)",
      selected: "rgba(45, 44, 68, 0.08)",
      disabled: "rgba(45, 44, 68, 0.26)",
      disabledBackground: "rgba(45, 44, 68, 0.12)",
      focus: "rgba(45, 44, 68, 0.12)"
    },
    error: {
      contrastText: "#FFFFFF",
      dark: "#902931",
      light: "#EF9AA1",
      main: "#EF404B"
    },
    info: {
      contrastText: "#FFFFFF",
      dark: "#41689E",
      light: "#8DBBF2",
      main: "#5f98e8",
      alert: {
        background: "#EDF5FF"
      }
    },
    primary: {
      contrastText: "#FFFFFF",
      dark: "#161453",
      light: "#EBEBFF",
      main: "#4548c4"
    },
    secondary: {
      contrastText: "#FFFFFF",
      dark: "#4548C4",
      light: "#EBEBFF",
      main: "#7583F7",
      lightHover: "#E0E0FF"
    },
    success: {
      contrastText: "#FFFFFF",
      dark: "#428E6E",
      light: "#A8D8C4",
      main: "#4caf85",
      alert: {
        background: "#DDF8EF"
      }
    },
    text: {
      primary: "rgba(54, 53, 84, 1)",
      secondary: "rgba(45, 44, 68, 0.7)",
      disabled: "rgba(45, 44, 68, 0.4)"
    },
    warning: {
      contrastText: "#FFFFFF",
      dark: "#C39214",
      light: "#FDDD8B",
      main: "#f1b00a",
      alert: {
        background: "#FFF2CA",
        content: "#B08310"
      }
    },
    grey: {
      50: "#FAFAFA",
      100: "#F5F5F5",
      200: "#EEEEEE",
      300: "#E0E0E0",
      400: "#BDBDBD",
      500: "#9E9E9E",
      700: "#616161"
    }
  },
  typography: {
    fontFamily: "inter,sans-serif",
    button: {
      textTransform: "none"
    },
    overline: {
      fontWeight: 500,
      fontSize: 11,
      lineHeight: "29px",
      textTransform: "uppercase",
      fontFamily: "inter,sans-serif"
    },
    caption: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: "18px",
      fontFamily: "inter,sans-serif"
    },
    body2: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "20px",
      fontFamily: "inter,sans-serif"
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "24px",
      fontFamily: "inter,sans-serif"
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "22px",
      fontFamily: "inter,sans-serif"
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "20px",
      fontFamily: "inter,sans-serif"
    },
    h6: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: "32px",
      fontFamily: "inter,sans-serif"
    },
    h5: {
      fontWeight: 500,
      fontSize: 24,
      lineHeight: "32px",
      fontFamily: "inter,sans-serif"
    },
    h4: {
      fontWeight: 500,
      fontSize: 28,
      lineHeight: "34px",
      fontFamily: "inter,sans-serif"
    },
    h3: {
      fontWeight: 500,
      fontSize: 48,
      lineHeight: "56px",
      fontFamily: "inter,sans-serif"
    },
    h2: {
      fontWeight: 500,
      fontSize: 60,
      lineHeight: "72px",
      fontFamily: "inter,sans-serif"
    },
    h1: {
      fontWeight: 500,
      fontSize: 72,
      lineHeight: "86px",
      fontFamily: "inter,sans-serif"
    },
    buttonLarge: {
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: 500,
      fontFamily: "inter,sans-serif"
    },
    buttonMedium: {
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 500,
      fontFamily: "inter,sans-serif"
    },
    buttonSmall: {
      fontSize: 12,
      lineHeight: "16px",
      fontWeight: 500,
      fontFamily: "inter,sans-serif"
    },
    tableHeader: {
      fontSize: 14,
      lineHeight: "24px",
      fontWeight: 600,
      fontFamily: "inter,sans-serif"
    },
    avatarLetter: {
      fontSize: 20,
      lineHeight: "20px",
      fontWeight: 400,
      fontFamily: "inter,sans-serif"
    },
    inputLabel: {
      fontSize: 12,
      lineHeight: "12px",
      fontWeight: 400,
      fontFamily: "inter,sans-serif"
    },
    inputText: {
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: 400,
      fontFamily: "inter,sans-serif"
    },
    helperText: {
      fontSize: 12,
      lineHeight: "20px",
      fontWeight: 400,
      fontFamily: "inter,sans-serif"
    },
    tooltip: {
      fontSize: 11,
      lineHeight: "14px",
      fontWeight: 500,
      fontFamily: "inter,sans-serif"
    }
  },
  shape: {
    borderRadius: 8
  },
  shadows: [
    "none",
    "0px 1px 1px 0px rgba(0, 0, 0, 0.16)",
    "0px 2px 2px 0px rgba(0, 0, 0, 0.12)",
    "0px 4px 8px 0px rgba(0, 0, 0, 0.12)",
    "0px 8px 8px 0px rgba(0, 0, 0, 0.12)",
    "0px 12px 12px 0px rgba(0, 0, 0, 0.12)",
    "0px 16px 16px 0px rgba(0, 0, 0, 0.12)",
    ...theme.shadows.slice(7)
  ]
});
