import { Tooltip } from "@mui/material";
import React from "react";
import { isAssistantPreviewableSelector } from "src/stores/selectors/appSelectors";
import { isUpdatingCollectionSelector } from "src/stores/selectors/collectionsSelectors";
import { buildStatusPollingInProgressSelector } from "src/stores/selectors/previewSelectors";
import { SYSTEM_MODES } from "src/stores/slices/createAppSlice";
import { BUILD_STATUSES } from "src/utils";
import PhoneRingingIcon from "src/icons/PhoneRingingIcon";
import useAppStore from "src/stores/appStore";
import { StyledButton, StyledIconButton } from "src/layout/Header/HeaderStyles";

// eslint-disable-next-line complexity
const PreviewButtonVoice = ({ isButton, isActive, onClick }) => {
  const systemMode = useAppStore((state) => state.systemMode);
  const draft = useAppStore((state) => state.draft);
  const isAssistantPreviewable = useAppStore(isAssistantPreviewableSelector);
  const buildStatusPollingInProgress = useAppStore(buildStatusPollingInProgressSelector);
  const isUpdatingCollection = useAppStore(isUpdatingCollectionSelector);
  const buildDraftResponse = useAppStore((state) => state.buildDraftResponse);

  const isPreviewDisabled =
    (draft && systemMode === SYSTEM_MODES.EDIT && buildDraftResponse?.status !== BUILD_STATUSES.LIVE) ||
    (!draft && systemMode === SYSTEM_MODES.EDIT) ||
    buildStatusPollingInProgress ||
    !isAssistantPreviewable ||
    isUpdatingCollection;

  return (
    <>
      {isButton ? (
        <StyledButton
          data-testid="page-preview-voice-button"
          disabled={isPreviewDisabled}
          variant="outlined"
          startIcon={
            <PhoneRingingIcon size={20} disabled={isPreviewDisabled} />
          }
          onClick={onClick}
        >
          Preview
        </StyledButton>
      ) : (
        <Tooltip title={"Conversation preview"}>
          <span>
            <StyledIconButton
              variant="text"
              aria-label="call"
              disabled={isPreviewDisabled}
              isActive={isActive}
              onClick={onClick}
              data-testid="page-preview-voice-button"
            >
              <PhoneRingingIcon disabled={isPreviewDisabled} />
            </StyledIconButton>
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default PreviewButtonVoice;
