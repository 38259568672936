import Chip from "@hyro-ui/Chip";
import { Skeleton, Typography, Stack, Box, chipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { ASSISTANT_TYPE_TO_ICON, CHANNEL_TO_DISPLAY_NAME } from "../../../utils";
import dayjs from "dayjs";
import PhoneIcon from "src/static/images/phone_icon.svg";
import ChatIcon from "src/static/images/chat_icon.svg";

const AssistantName = styled(props => <Typography variant="subtitle1" {...props} />)(
  ({ theme }) => ({
    color: theme.palette.text.primary,
    marginBlock: "12px 4px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  })
);

const StyledChip = styled(Chip)(() => ({
  borderRadius: "8px",
  fontSize: "12px",
  pointerEvents: "none",
  height: "26px",
  [`& .${chipClasses.label}`]: {
    padding: "6px 12px"
  }
}));

const CardContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "showSkeleton"
})(({ theme, showSkeleton }) => ({
  background: "#ffffff",
  "padding": "20px 20px 16px",
  "display": "flex",
  "flexDirection": "column",
  "justifyContent": "space-between",
  "border": "1px solid" + theme.palette.grey["200"],
  "borderRadius": "12px",
  "boxShadow": "0px 1px 8px 0px rgba(0, 0, 0, 0.04), 0px 1px 2px 0px rgba(0, 0, 0, 0.01)",
  "transition": "0.1s",
  "&:hover": showSkeleton ? {} : {
    cursor: "pointer",
    boxShadow: "0px 6px 8px 4px #00000005, 0px 4px 5px 2px #00000005",
    "& img": {
      transform: "scale(1.1)"
    }
  }
}));

const StyledIconContainer = styled(Stack)(() => ({
  justifyContent: "center",
  alignItems: "center",
  width: "48px",
  height: "48px",
  borderRadius: "12px",
  border: "1px solid #DEDFFF",
  backgroundColor: "#F3F3FF"
}));

const StyledIcon = styled("img")(() => ({
  transition: "150ms ease-in-out"
}));

const CardHeader = styled("div")(() => ({
  display: "grid",
  marginBottom: "20px"
}));

const CardFooter = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: theme.spacing(2),
  borderTop: "1px solid #EEEEEE"
}));

// eslint-disable-next-line complexity
const AssistantCard = (
  {
    assistantId,
    assistantName,
    date,
    dateType,
    channel,
    assistantType,
    onClick,
    showSkeleton
  }
) => (<CardContainer onClick={onClick} showSkeleton={showSkeleton} data-testid={assistantId}>
  <CardHeader>
    {showSkeleton
      ? (
        <Skeleton
          variant="rounded"
          sx={{ borderRadius: "8px" }}
          width={48}
          height={48}
        />
      )
      :
        <StyledIconContainer>
          {
            (channel === CHANNEL_TO_DISPLAY_NAME.Web || channel === CHANNEL_TO_DISPLAY_NAME.Phone) && <StyledIcon src={channel === CHANNEL_TO_DISPLAY_NAME.Web ? ChatIcon : PhoneIcon} alt="" width={24} height={24} />
          }
        </StyledIconContainer>
      }

    {showSkeleton
      ? (
        <Skeleton variant="text" sx={{ fontSize: "20px", width: "120px" }} />
      )
      : (
        <AssistantName data-testid={assistantName}>{assistantName}</AssistantName>
      )}

    {showSkeleton
      ? (
        <Skeleton variant="text" sx={{ fontSize: "20px", width: "120px" }} />
      )
      : (
        <Typography variant="body2" color="#363554">{channel} assistant</Typography>
      )}
  </CardHeader>
  <CardFooter>
    <Box>
      {showSkeleton
        ? (
          <Skeleton
            variant="rounded"
            sx={{ borderRadius: "500px" }}
            width={60}
            height={30}
          />
        )
        : (
          <StyledChip
            size="small"
            label={assistantType}
            textColorPath={ASSISTANT_TYPE_TO_ICON[assistantType]?.textColorPath}
            backgroundColorPath={
              ASSISTANT_TYPE_TO_ICON[assistantType]?.backgroundColorPath
            }
          />
        )}
      </Box>
      {showSkeleton
      ? (
        <Skeleton variant="text" sx={{ fontSize: "12px", width: "150px" }} />
      )
      : (
        <Typography variant="tooltip" color="#9E9E9E">
          {date && `${dateType} ${dayjs(date).format("D MMM, YYYY")}`}
        </Typography>
      )}
  </CardFooter>
</CardContainer>);

export default AssistantCard;
