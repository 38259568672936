import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

const CopyLinkIcon = ({ fontSize }) => (<SvgIcon
  fontSize={fontSize}
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="transparent"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    className="cls-2"
    d="M13.29,9.29l-4,4a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0-1.42-1.42Z"
  />
  <path
    className="cls-2"
    d="M12.28,17.4,11,18.67a4.2,4.2,0,0,1-5.58.4,4,4,0,0,1-.27-5.93l1.42-1.43a1,1,0,0,0,0-1.42,1,1,0,0,0-1.42,0L3.88,11.57a6.15,6.15,0,0,0-.67,8.07,6.06,6.06,0,0,0,9.07.6l1.42-1.42a1,1,0,0,0-1.42-1.42Z"
  />
  <path
    className="cls-2"
    d="M19.66,3.22a6.18,6.18,0,0,0-8.13.68L10.45,5a1.09,1.09,0,0,0-.17,1.61,1,1,0,0,0,1.42,0L13,5.3a4.17,4.17,0,0,1,5.57-.4,4,4,0,0,1,.27,5.95l-1.42,1.43a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l1.42-1.42A6.06,6.06,0,0,0,19.66,3.22Z"
  />
</SvgIcon>);

export default CopyLinkIcon;
