const NUMBER_OF_RETRIES = 2;

export const removeInactiveQueries = ({ queryKey, queryClient }) => {
  queryClient
    .getQueryCache()
    .findAll({ queryKey })
    .filter(query => query.isActive() === false)
    .forEach(query => queryClient.removeQueries(query));
};

export const retryIfServerError = (failureCount, error) => error?.response?.status === 500 && failureCount < NUMBER_OF_RETRIES;
