import React from "react";

const LocationBasedScheduling = () => (
  <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="25" r="23.5" fill="#F3F3FF" stroke="#DEDFFF" />
    <path
      d="M15 21.8C15 20.1198 15 19.2798 15.327 18.638C15.6146 18.0735 16.0735 17.6146 16.638 17.327C17.2798 17 18.1198 17 19.8 17H28.2C29.8802 17 30.7202 17 31.362 17.327C31.9265 17.6146 32.3854 18.0735 32.673 18.638C33 19.2798 33 20.1198 33 21.8V23H15V21.8Z"
      fill="#B3B7FF" />
    <path
      d="M33 24.5V21.8C33 20.1198 33 19.2798 32.673 18.638C32.3854 18.0735 31.9265 17.6146 31.362 17.327C30.7202 17 29.8802 17 28.2 17H19.8C18.1198 17 17.2798 17 16.638 17.327C16.0735 17.6146 15.6146 18.0735 15.327 18.638C15 19.2798 15 20.1198 15 21.8V30.2C15 31.8802 15 32.7202 15.327 33.362C15.6146 33.9265 16.0735 34.3854 16.638 34.673C17.2798 35 18.1198 35 19.8 35H24.5M33 23H15M28 15V19M20 15V19"
      stroke="#4548C4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M30 35C30.75 33.4 33 32.3673 33 30.2C33 28.4327 31.6569 27 30 27C28.3431 27 27 28.4327 27 30.2C27 32.3673 29.25 33.4 30 35Z"
      stroke="#EF4068" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="30" cy="30" r="1" fill="#EF4068" />
  </svg>
);

export default LocationBasedScheduling;
