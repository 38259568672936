import { Snackbar } from "@mui/material";
import React from "react";
import { HyroAlert } from "./index";

const HyroFeedbackMessage = (
  {
    message,
    severity,
    showFeedback,
    closeFeedback,
    onExited,
    sx
  }
) => (<Snackbar
  sx={sx}
  data-testid={`feedback-snackbar-${severity}`}
  open={showFeedback}
  onClose={closeFeedback}
  autoHideDuration={6000}
  TransitionProps={{ onExited }}
  // A centralized component defined on the app level will solve the position issue.
  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
>
  <HyroAlert
    alertText={message}
    dismissText="GOT IT"
    severity={severity}
    handleDismiss={closeFeedback}
  />
</Snackbar>);

export default HyroFeedbackMessage;
