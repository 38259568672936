import { post, get } from "src/services/api";

export const sharePreview = async ({ params, payload }) => {
  try {
    return await post(`/accounts/${params.accountId}/assistants/${params.assistantId}/preview/share`, payload);
  }
  catch (error) {
    console.error(error);
    throw new Error();
  }
};

export const getPreviewLink = async (params) => {
  try {
    return await get(`/accounts/${params.accountId}/assistants/${params.assistantId}/preview/link`, { branchName: params.branchName });
  }
  catch (error) {
    console.error(error);
    throw new Error();
  }
};
