import { GridCellModes } from "@mui/x-data-grid";
import { useCallback, useState } from "react";

// Custom hook to enable editing a cell with a single click
const useSingleClickEdit = () => {
  const [cellModesModel, setCellModesModel] = useState({});

  const handleCellClick = useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore clicks from components outside the cell
    if (event.target.nodeType === 1 && !event.currentTarget.contains(event.target)) {
      return;
    }

    setCellModesModel((prevModel) => ({
      // Revert the mode of the other cells from other rows
      ...Object.keys(prevModel).reduce(
        (acc, id) => ({
          ...acc,
          [id]: Object.keys(prevModel[id] || {}).reduce(
            (acc2, field) => ({
              ...acc2,
              [field]: { mode: GridCellModes.View }
            }),
            {}
          )
        }),
        {}
      ),
      [params.id]: {
        // Revert the mode of other cells in the same row
        ...Object.keys(prevModel[params.id] || {}).reduce(
          (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
          {}
        ),
        [params.field]: { mode: GridCellModes.Edit }
      }
    }));

    // Set the cell to "edit" mode using the API ref
    // apiRef.current.setCellMode(params.id, params.field, GridCellModes.Edit);
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  return { cellModesModel, handleCellClick, handleCellModesModelChange, setCellModesModel };
};

export default useSingleClickEdit;
