import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

// Keyframes matching the Lottie timing more closely
const layer2Animation = keyframes`
  0%, 33.33% { opacity: 0; }
  50% { opacity: 1; }
  66.66%, 100% { opacity: 0; }
`;

const layer3Animation = keyframes`
  0%, 50% { opacity: 0; }
  66.66% { opacity: 1; }
  83.33%, 100% { opacity: 0; }
`;

const layer4Animation = keyframes`
  0%, 40% { opacity: 0; }
  73.33% { opacity: 1; }
  100% { opacity: 0; }
`;

// Styled component for the SVG container
const SvgContainer = styled.svg`
  width: 75px; // Based on Lottie JSON dimensions
  height: 75px;
`;

// Styled component for the individual paths
const Path = styled.path`
  fill: ${({ color }) => color};
  animation: ${({ animation }) => animation} 1.33s linear infinite;
`;

// Animated SVG component
const AnimatedSVG = () => (
  <SvgContainer xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56">
    <Path
      color="#EF4269"
      animation={layer2Animation}
      d="M7.51416 38.036C7.25776 37.6955 5.18091 36.5913 4.69741 36.4218C4.18553 36.2737 4.07167 36.1815 4.26242 35.5488C4.45317 34.916 4.64486 34.8161 5.0499 34.8224C5.48334 34.8073 8.81557 34.566 8.92179 34.3526C9.04937 34.1676 10.0544 31.899 10.2885 31.6782C10.551 31.4361 11.6093 31.1284 11.7164 31.4479C11.8519 31.7461 11.7644 34.5244 11.8646 34.8937C11.9363 35.2843 15.6764 36.648 15.6984 37.0316C15.7204 37.4153 14.9763 38.5536 14.82 38.5824C14.6423 38.5827 11.3736 38.5539 11.0683 38.7393C10.7631 38.9246 9.25474 41.8835 9.03467 42.0048C8.78621 42.1474 7.93276 41.7228 7.86102 41.3322C7.71815 40.9061 7.89878 38.5468 7.51416 38.036Z"
    />
    <Path
      color="#4548C4"
      animation={layer3Animation}
      d="M26.3773 16.9294C25.8047 16.8217 22.6982 17.5781 22.056 17.8403C21.4052 18.1488 21.2056 18.1592 20.8112 17.3669C20.4167 16.5746 20.5166 16.3061 20.9288 15.9528C21.3498 15.5532 24.4703 12.3578 24.3793 12.0534C24.336 11.758 23.244 8.63232 23.2745 8.20719C23.3138 7.73581 24.0908 6.49354 24.4942 6.71301C24.9062 6.88624 27.3909 9.69932 27.8333 9.97401C28.267 10.2949 33.2828 8.31767 33.6601 8.67587C34.0373 9.03406 34.3447 10.8153 34.2144 10.9824C34.0365 11.1404 30.7297 14.0135 30.595 14.4669C30.4603 14.9203 31.6864 19.1724 31.5778 19.4871C31.4605 19.848 30.211 20.1876 29.7773 19.8667C29.2872 19.583 27.2362 17.0909 26.3773 16.9294Z"
    />
    <Path
      color="#8DBBF2"
      animation={layer4Animation}
      d="M45.258 45.2248C44.8817 45.2066 43.0587 46.1569 42.673 46.4474C42.2873 46.7378 42.1811 46.7574 41.8333 46.2954C41.4854 45.8334 41.4756 45.6599 41.7262 45.3505C41.9092 45.0317 43.4701 42.5292 43.3927 42.3462C43.3153 42.1632 42.2037 40.2855 42.1745 40.0059C42.1163 39.6878 42.4532 38.7706 42.7524 38.8469C43.0226 38.8847 44.9831 40.3645 45.2727 40.5084C45.5624 40.6522 48.4651 38.6475 48.7837 38.8299C49.0734 38.9737 49.5281 40.1395 49.4413 40.2651C49.3546 40.3907 47.6488 42.7007 47.6394 43.0094C47.6301 43.318 48.9837 45.9184 48.9549 46.121C48.9552 46.3621 48.2125 46.7404 47.8842 46.6256C47.4113 46.5594 45.8081 45.2329 45.258 45.2248Z"
    />
  </SvgContainer>
);

export default AnimatedSVG;
