import React from "react";

const CheckboxIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M14.8655 1.6665H5.13463C4.69535 1.66649 4.31636 1.66648 4.00423 1.69198C3.67477 1.7189 3.34709 1.77833 3.03177 1.93899C2.56137 2.17867 2.17892 2.56112 1.93923 3.03153C1.77857 3.34685 1.71914 3.67453 1.69222 4.00398C1.66672 4.31612 1.66673 4.69508 1.66675 5.13435V14.8653C1.66673 15.3046 1.66672 15.6836 1.69222 15.9957C1.71914 16.3252 1.77857 16.6528 1.93923 16.9681C2.17892 17.4386 2.56137 17.821 3.03177 18.0607C3.34709 18.2213 3.67477 18.2808 4.00423 18.3077C4.31637 18.3332 4.69533 18.3332 5.13461 18.3332H14.8656C15.3048 18.3332 15.6838 18.3332 15.9959 18.3077C16.3254 18.2808 16.6531 18.2213 16.9684 18.0607C17.4388 17.821 17.8212 17.4386 18.0609 16.9681C18.2216 16.6528 18.281 16.3252 18.3079 15.9957C18.3334 15.6836 18.3334 15.3046 18.3334 14.8653V5.13437C18.3334 4.69508 18.3334 4.31612 18.3079 4.00398C18.281 3.67453 18.2216 3.34685 18.0609 3.03153C17.8212 2.56112 17.4388 2.17867 16.9684 1.93899C16.6531 1.77833 16.3254 1.7189 15.9959 1.69198C15.6838 1.66648 15.3048 1.66649 14.8655 1.6665ZM14.3393 8.08909C14.6648 7.76366 14.6648 7.23602 14.3393 6.91058C14.0139 6.58515 13.4863 6.58515 13.1608 6.91058L8.75008 11.3213L6.83934 9.41058C6.5139 9.08514 5.98626 9.08514 5.66083 9.41058C5.33539 9.73602 5.33539 10.2637 5.66083 10.5891L8.16083 13.0891C8.48626 13.4145 9.0139 13.4145 9.33934 13.0891L14.3393 8.08909Z"
          fill="#4548C4"/>
  </svg>
);

export default CheckboxIcon;
