export const FIELD_TYPES = {
  ACTION: "ACTION",
  TEXT: "TEXT",
  PHONE: "PHONE",
  CHOOSE_ONE: "CHOOSE_ONE",
  CHOOSE_AT_LEAST_ONE: "CHOOSE_AT_LEAST_ONE",
  BOOL: "BOOL",
  LIST: "LIST",
  INTEGRATION: "INTEGRATION",
  URL: "URL",
  INT: "INT",
  COLLECTION_REF: "COLLECTION_REF",
  OBJECT: "OBJECT",
  SECRET: "SECRET",
  MULTI_LANGUAGE_TEXT: "MULTI_LANGUAGE_TEXT"
};

export const DEFAULT_REF_FIELD = {
  key: "none",
  display_key: "None"
};

export const VALIDATIONS = {
  [FIELD_TYPES.TEXT]: { TEST: /^[\s\S]{1,1000}$/, MESSAGE: "Maximum length is 1000 characters." },
  [FIELD_TYPES.MULTI_LANGUAGE_TEXT]: { TEST: /^[\s\S]{1,1000}$/, MESSAGE: "Maximum length is 1000 characters." },
  [FIELD_TYPES.PHONE]: {
    MESSAGE: "Invalid phone number format. Please enter a valid US number, such as +14844493841."
  },
  [FIELD_TYPES.URL]: {
    MESSAGE: "Invalid URL format. Please enter a valid web address, such as https://www.example.com."
  },
  [FIELD_TYPES.INT]: {
    TEST: /^-?\d+$/,
    MESSAGE: "Invalid input. Please enter a whole number without decimals."
  },
  [FIELD_TYPES.CHOOSE_AT_LEAST_ONE]: {
    MESSAGE: "Please select at least one option."
  },
  [FIELD_TYPES.INTEGRATION]: {
    MESSAGE: "Invalid UUID format. Please enter a valid UUID."
  },
  [FIELD_TYPES.COLLECTION_REF]: {
    MESSAGE: "Please select a collection."
  },
  [FIELD_TYPES.LIST]: {
    MESSAGE: "Please add at least one item."
  },
  [FIELD_TYPES.OBJECT]: {
    MESSAGE: "Identical table row exists, please keep rows unique."
  }
};

const NUM_ITEMS_FOR_SEARCH = 10;

export const shouldShowSearchField = (options) => {
  const optionsExist = options.length > 0;
  return optionsExist && options.length > NUM_ITEMS_FOR_SEARCH;
};
