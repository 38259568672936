import styled from "@emotion/styled";
import { IconButton, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import LockIcon from "../../../icons/LockIcon";
import LockOpenIcon from "../../../icons/LockOpenIcon";
import StatusChip from "../../collections/StatusChip";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";
import { StyledTextField } from "../styles";

// eslint-disable-next-line complexity
const Secret = ({ value, onChange, isReadOnly, isFieldReadOnlyFromSpec, status, label, error, fieldType, index }) => {
  const [isLocked, setIsLocked] = useState(!!value || isReadOnly);
  const [localValue, setLocalValue] = useState(value);
  const btnRef = useRef(null);

  useEffect(() => {
    setIsLocked(isReadOnly || !!value);
    // eslint-disable-next-line
  }, [isReadOnly]);

  const handleTextFieldFocus = () => {
    if (localValue?.startsWith("**") && !isLocked) {
      setLocalValue("");
    }
  };

  const handleTextFieldEmpty = () => {
    if (!isLocked) setIsLocked(true);

    if (!localValue?.trim()) {
      setLocalValue(value || "");
    }
  };

  const localOnChange = (value) => {
    if (!isLocked) {
      setLocalValue(value);
      onChange(value);
    }
  };

  const handleTextFieldClick = () => {
    if (isReadOnly || !isLocked) return;
    btnRef.current.start();
    setTimeout(() => btnRef.current.stop());
  };

  return (<div>
    <StyledTextField
      id={"locked-textfield" + label}
      onClick={handleTextFieldClick}
      fullWidth
      variant="outlined"
      isFieldReadOnlyFromSpec={isFieldReadOnlyFromSpec}
      readOnly={isReadOnly}
      type={isLocked || localValue?.startsWith("**") ? "password" : "text"}
      value={localValue}
      placeholder={label}
      error={!!error}
      helperText={(error ? <Typography variant="caption">{error}</Typography> :
        <StatusChip status={status} />)}
      FormHelperTextProps={{ "data-testid": `${fieldType}-${index}-helper` }}
      onFocus={handleTextFieldFocus}
      onBlur={handleTextFieldEmpty}
      onChange={({ target: { value } }) => localOnChange(value)}
      inputProps={{ "data-testid": `${fieldType}-${index}` }}
      InputProps={{
        endAdornment: (
          <IconButton
            data-testid={`${fieldType}-${index}-button-${isLocked ? "locked" : "unlocked"}`}
            disableRipple
            onClick={() => {
              if (isLocked) document.getElementById("locked-textfield" + label).focus();
              setIsLocked(!isLocked);
            }}
            disabled={isReadOnly}
            edge="end"
          >
            <StyledTouchRipple ref={btnRef} />
            {isLocked ? <LockIcon disabled={isReadOnly} /> : <LockOpenIcon />}
          </IconButton>),
        readOnly: isReadOnly || isLocked,
        disabled: isReadOnly
      }}
    />
  </div>);
};

const StyledTouchRipple = styled(TouchRipple)(({ theme }) => ({
  "color": theme.palette.primary.main
}));

export default Secret;
