import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Chip, chipClasses, Typography } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { darken, styled } from "@mui/material/styles";
import SvgIcon from "@mui/material/SvgIcon";
import * as React from "react";
import { VALIDATIONS_SEVERITY } from "src/components/collections/consts";
import { getValidationMessage } from "src/components/collections/helpers";

import { MandatoryBadge } from "../../components/common/MandatoryBadge";
import useAppStore from "../../stores/appStore";
import { validationResultsSelector } from "../../stores/selectors/collectionsSelectors";

const StyledChip = styled(Chip)`
  width: 46px;
  height: 20px;
  margin-left: 4px;
  .${chipClasses.label} {
    padding: 0;
  }

  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.16px;
`;

const StyledListItemButton = styled(ListItemButton)(
  ({ theme }) =>
    ({ selected }) => ({
      "minHeight": 36,
      "padding": `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
      "borderRadius": theme.shape.borderRadius,
      "svg": {
        fill: selected
          ? theme.palette.primary.main
          : theme.palette.text.secondary
      },
      "&:hover": {
        backgroundColor: darken(theme.palette.background.default, 0.04)
      },
      "&.Mui-selected": {
        color: selected
          ? theme.palette.primary.main
          : theme.palette.text.secondary,
        backgroundColor: theme.palette.primary.light
      }
    })
);

const StyledListItemText = styled(ListItemText)`
  & .MuiTypography-root {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: prop => prop !== "open" || prop !== "selected"
})(({ open, selected, theme }) => ({
  color: selected ? theme.palette.primary.main : theme.palette.text.secondary,
  minWidth: 0,
  marginRight: open ? theme.spacing(2) : "auto"
}));

// eslint-disable-next-line complexity
const ListItemContent = ({ navItem, open, navItemIsOpen, currentPage, showIcon }) => {
  const validationResults = useAppStore(validationResultsSelector);
  const hasValidationMessage = getValidationMessage(validationResults, navItem.key, VALIDATIONS_SEVERITY.ERROR);
  const isItemSelected = currentPage.includes(navItem.key) && !navItem.children;

  return (
    <StyledListItemButton open={open} selected={isItemSelected} data-testid={`navigation-item-${navItem.text}`}>
      {showIcon && (
        <StyledListItemIcon open={open} selected={isItemSelected}>
          {navItem.icon ? <navItem.icon /> : <SvgIcon />}
        </StyledListItemIcon>
      )}
      {open && (
        <>
          <StyledListItemText >
            <Typography variant="body2">{navItem.text}</Typography>
          </StyledListItemText>
          {navItem?.isNew ? <StyledChip color={"secondary"} label={"New!"}/> : null}
          {navItem.children
                    && (navItemIsOpen ? <ExpandLess /> : <ExpandMore />)}
        </>
      )}
      {hasValidationMessage && <MandatoryBadge close={!open} />}
    </StyledListItemButton>
  );
};

export default ListItemContent;
