
import React from "react";

const FileBasedIcon = () => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="24" cy="24" r="23.5" stroke="#E0E0E0"/>
<path d="M20.5262 13.5H16.0668C14.76 13.5 14.1066 13.5 13.6075 13.7543C13.1685 13.978 12.8115 14.335 12.5878 14.774C12.3335 15.2731 12.3335 15.9265 12.3335 17.2333V18.1667H25.1668L23.8654 15.5637C23.4908 14.8146 23.3035 14.44 23.0241 14.1664C22.777 13.9244 22.4792 13.7403 22.1522 13.6275C21.7825 13.5 21.3637 13.5 20.5262 13.5Z" fill="#B3B7FF"/>
<path d="M25.1668 18.1667L23.8654 15.5637C23.4908 14.8146 23.3035 14.44 23.0241 14.1664C22.777 13.9244 22.4792 13.7403 22.1522 13.6275C21.7825 13.5 21.3637 13.5 20.5262 13.5H16.0668C14.76 13.5 14.1066 13.5 13.6075 13.7543C13.1685 13.978 12.8115 14.335 12.5878 14.774C12.3335 15.2731 12.3335 15.9265 12.3335 17.2333V18.1667M12.3335 18.1667H30.0668C32.027 18.1667 33.0071 18.1667 33.7558 18.5481C34.4144 18.8837 34.9498 19.4191 35.2854 20.0777C35.6668 20.8264 35.6668 21.8065 35.6668 23.7667V28.9C35.6668 30.8602 35.6668 31.8403 35.2854 32.589C34.9498 33.2475 34.4144 33.783 33.7558 34.1185C33.0071 34.5 32.027 34.5 30.0668 34.5H17.9335C15.9733 34.5 14.9932 34.5 14.2445 34.1185C13.586 33.783 13.0505 33.2475 12.715 32.589C12.3335 31.8403 12.3335 30.8602 12.3335 28.9V18.1667Z" stroke="#4548C4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M20.5 26.3333L22.8333 28.6666L28.0833 23.4166" stroke="#4548C4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

export default FileBasedIcon;
