import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { pathOr, pipe } from "ramda";
import React, { useEffect, useState } from "react";

import useAppStore from "../stores/appStore";
import { assistantIdSelector, assistantsSelector } from "../stores/selectors/appSelectors";
import { objectByAssistantId } from "../utils";

// dayjs plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

const DateTimeContext = React.createContext(null);

const DEFAULT_DATE_RANGE = dayjs.duration(1, "w");
const DEFAULT_ET_TIME_ZONE = "America/New_York";

const DateTimeProvider = ({ children }) => {
  const assistants = useAppStore(assistantsSelector);
  const [timeZone, setTimeZone] = useState(DEFAULT_ET_TIME_ZONE);
  const selectedAssistantId = useAppStore(assistantIdSelector);

  const getTimeZoneDate = (timeString) => dayjs.tz(dayjs(timeString), timeZone);
  const localDate = (timeString) => dayjs(timeString);

  const formattedTimezone = (timeString) => getTimeZoneDate(timeString).format("L LT");

  const formattedTimezoneSeconds = (timeString) =>
    getTimeZoneDate(timeString).format("hh:mm:ss A");

  const formattedTimezoneMilliSeconds = (timeString) =>
    getTimeZoneDate(timeString).format("hh:mm:ss.SSS A");

  const [fromDate, setFromDate] = useState(
    getTimeZoneDate().subtract(DEFAULT_DATE_RANGE).startOf("d").toDate()
  );
  const [toDate, setToDate] = useState(getTimeZoneDate().toDate());

  const ctxValue = {
    timeZone,
    setTimeZone,
    getTimeZoneDate,
    localDate,
    formattedTimezone,
    formattedTimezoneSeconds,
    formattedTimezoneMilliSeconds,
    fromDate,
    toDate,
    setFromDate,
    setToDate
  };

  useEffect(() => {
    if (selectedAssistantId && assistants?.length > 0) {
      setTimeZone(
        pipe(
          objectByAssistantId,
          pathOr(DEFAULT_ET_TIME_ZONE, ["config", "analysis", "timezone"])
        )(selectedAssistantId, assistants)
      );
    }
  }, [assistants, selectedAssistantId, setTimeZone]);

  return (
    <DateTimeContext.Provider value={ctxValue}>
      {children}
    </DateTimeContext.Provider>
  );
};

export default DateTimeContext;

export { DateTimeProvider };
