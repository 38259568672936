import { buttonBaseClasses, CircularProgress, Divider, InputAdornment, selectClasses } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, { useState, useRef, useEffect, useMemo } from "react";
import { capitalizeAndCleanUnderscore } from "../../../utils";
import styled from "@emotion/styled";
import AddIcon from "../../../icons/AddIcon";
import ChevronDown from "../../../icons/ChevronDown";
import { StyledTextField } from "../styles";
import { noOptionList } from "src/components/collections/helpers";
import SearchBar from "src/components/common/SearchBar";
import { shouldShowSearchField } from "src/components/common/fields/utils";

const ADD_NEW = "add_new";

// eslint-disable-next-line max-lines-per-function, complexity
const SingleOption = ({
  id,
  value,
  showLabel,
  label,
  options = [],
  onChange,
  onFocus,
  onBlur,
  error,
  isReadOnly,
  onAddNewCollection,
  isFieldReadOnlyFromSpec,
  placeholder,
  loading,
  field,
  testId,
  open = false,
  helperText,
  SelectProps = {},
  ...rest
}) => {
  const [menuOpen, setMenuOpen] = useState(open);
  const [searchText, setSearchText] = useState("");
  const inputRef = useRef(null);

  const optionsExist = options.length > 0;
  const showSearch = shouldShowSearchField(options);

  const filteredOptions = useMemo(() => {
    const optionsFiltered = options.filter(option =>
      option.display_key.toLowerCase().includes(searchText.toLowerCase())
    );

    if (!optionsExist) return noOptionList(field?.spec?.display_key);

    return optionsFiltered.length === 0
      ? noOptionList()
      : optionsFiltered;
  }, [options, searchText, field?.spec?.display_key]);

  useEffect(() => {
    if (menuOpen) {
      onFocus?.(inputRef.current.node);
    } else {
      onBlur?.();
    }
  },
  [menuOpen, onFocus, onBlur]);

  const handleChange = ({ target }) => {
    if (target.value === ADD_NEW) {
      onAddNewCollection();
    } else {
      onChange(target.value);
    }
  };

  return (
    <>
      <StyledTextField
        disabled={loading}
        readOnly={isReadOnly}
        isFieldReadOnlyFromSpec={isFieldReadOnlyFromSpec}
        data-testid={testId}
        InputProps={{ sx: { borderRadius: "8px", backgroundColor: "white",
          ...(loading && {
            [`.${selectClasses.outlined}::after`]: {
              content: "'Loading...'",
              color: "rgba(45, 44, 68, 0.4)"
            }
          })
        }, readOnly: isReadOnly,
        endAdornment: loading ? <InputAdornment sx={{ position: "absolute", right: 12 }} position="end">
          <CircularProgress color='inherit' size={20} />
        </InputAdornment> : null
        }}
        SelectProps={{
          ["data-testid"]: `${testId}-select`,
          renderValue: () => options?.find(option => option.key === value)?.display_key ?? "",
          inputRef,
          sx: { ...SelectProps?.sx },
          MenuProps: {
            autoFocus: false,
            sx: {
              ...MenuSx,
              ...SelectProps?.MenuProps?.sx
            },
            PaperProps: {
              style: {
                width: inputRef.current ? inputRef.current.node.clientWidth : "auto",
                maxHeight: onAddNewCollection ? "315px" : "260px",
                ...SelectProps?.MenuProps?.PaperProps?.style
              }
            },
            elevation: 3
          },
          IconComponent: (props) => !loading ? <ChevronDown {...props} style={{ top: "unset", right: 12 }} /> : null,
          open: menuOpen,
          onClose: () => {
            setSearchText("");
            setMenuOpen(false);
          },
          onOpen: () => setMenuOpen(true)
        }}
        label={showLabel ? label : ""}
        onChange={handleChange}
        error={!!error}
        FormHelperTextProps={{ "data-testid": `${testId}-helper` }}
        helperText={helperText ?? ""}
        select
        fullWidth
        sx={{
          ...(placeholder && !loading ? {
            [`& .${selectClasses.select} span::before`]: {
              content: `"${placeholder}"`,
              color: "rgba(45, 44, 68, 0.4)"
            }
          } : {
          })
        }}
        id={id}
        value={value ?? ""}
        {...rest}
      >
        {showSearch && <StyledSearchBar>
          <SearchBar stayOpen onChange={setSearchText} customWidth="100%"/>
        </StyledSearchBar>}

        {onAddNewCollection &&
          <StyledMenuItem key={ADD_NEW} value={ADD_NEW}>
            <div style={{ display: "flex", marginRight: "8px" }}>
              <AddIcon color="#363554" />
            </div>
            Add New {label}
          </StyledMenuItem>}

        {filteredOptions?.length > 0 && optionsExist && onAddNewCollection && (
          <Divider sx={{ borderColor: "rgba(45, 44, 68, 0.12)", marginBlock: "4px !important" }}/>
        )}

        {!(!optionsExist && onAddNewCollection) && filteredOptions?.map((option, optionIndex) => (
          <StyledMenuItem key={`${option.key}-${optionIndex}`} value={option.key} disabled={option.disabled} error={option.error} data-testid={`${testId}-option-${optionIndex}`}>
            {capitalizeAndCleanUnderscore(option.display_key)}
          </StyledMenuItem>
        ))}

      </StyledTextField>
    </>
  );
};

const MenuSx = {
  marginTop: "4px",
  [`.${buttonBaseClasses.root}[aria-selected='true']`]: {
    background: "#EBEBFD"
  },
  [`.${buttonBaseClasses.root}[aria-selected='true']:hover`]: {
    background: "#EBEBFD"
  }
};

const StyledMenuItem = styled(MenuItem)(({ theme, error }) => ({
  "color": error && theme.palette.error.main,
  padding: "10px",
  borderRadius: "8px",
  "&:not(:last-child)": {
    marginBottom: "4px"
  }
}));

const StyledSearchBar = styled("div")({
  padding: "0px 8px 4px 8px",
  width: "100%"
});

export default SingleOption;
