import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

const AddSuggestionIcon = ({ color = "#FFF" }) => (<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 5L8.5 5C6.567 5 5 6.567 5 8.5L5 17.5C5 19.433 6.567 21 8.5 21L15.5 21C17.433 21 19 19.433 19 17.5L19 10.1765" fill="none" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
  <path d="M15 11.8262L9 11.8262M15 15.8262L9 15.8262" fill="none" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M17.5 3L17.5 5.5M17.5 5.5L17.5 8M17.5 5.5L15 5.5M17.5 5.5L20 5.5" fill="none" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</SvgIcon>);

export default AddSuggestionIcon;

