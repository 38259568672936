/* eslint-disable no-secrets/no-secrets */

import { styled } from "@mui/material/styles";
import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

import { APP_HEADER_HEIGHT } from "../../../layout/consts";
import { resolveEnvironmentSnippetLocation } from "../../../snippetGenerator";

export const SNIPPET_Z_INDEX = 10000000;

const Root = styled("span")`
  width: 100%;
  height: calc(100% - ${APP_HEADER_HEIGHT}px);
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 2;
  background: linear-gradient(180deg, rgba(45, 44, 68, 0.00) 0%, rgba(54, 53, 84, 0.4) 100%);

  .widgetRoot {
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const Snippet = ({ open, assistantId, bdWidgetServerUrl, usePreviewUrl, styles }) => {
  const container = useRef(null);

  useEffect(() => {
    open ? openSnippet() : closeSnippet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assistantId, open, usePreviewUrl]);

  useEffect(() => () => {
    // remove the widget from the DOM when the component is unmounted
    closeSnippet();
  }, []);

  const openSnippet = () => {
    container.current.innerHTML = "";

    const div = document.createElement("div");
    div.id = "widgetRoot";
    div.className = "widgetRoot";
    container.current.appendChild(div);

    const widgetIdScript = document.createElement("script");
    widgetIdScript.text = `window.HYRO_WIDGET_ID="${assistantId}";window._HYRO_USER_TESTING=true;`;

    const script = document.createElement("script");
    script.src
      = resolveEnvironmentSnippetLocation(import.meta.env.VITE_ENV)
      + "?id="
      + assistantId;
    const widgetServerUrl = usePreviewUrl ? bdWidgetServerUrl : import.meta.env.VITE_WIDGET_SERVER_UAT_URL;
    const widgetServerUrlScript = document.createElement("script");
    widgetServerUrlScript.text = `window.HYRO_WIDGET_SERVER_URL="${widgetServerUrl}";`;
    document.body.appendChild(widgetServerUrlScript);

    document.getElementById("widgetRoot").appendChild(widgetIdScript);
    document.getElementById("widgetRoot").appendChild(script);
  };

  const closeSnippet = () => {
    document.getElementById("widgetRoot")?.remove();
    document.getElementById("hyro-frame")?.remove();
    document.getElementById("parent-resizing")?.remove();
  };

  // When switching assistants, close the snippet of the previous assistant
  if (!window.location.href.includes(assistantId)) closeSnippet();

  if (!open) return null;
  return createPortal(
    <Root ref={container} data-testid="web-snippet" style={styles} />,
    document.body
  );
};

export default Snippet;
