import { useEffect, useState, useRef } from "react";

const useKeyboardNavigation = (items, isOpen, onSelect) => {
  const [focusedIndex, setFocusedIndex] = useState(0);
  const listRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setFocusedIndex(0);

      if (listRef.current) {
        listRef.current.focus();
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && listRef.current) {
      const activeItem = listRef.current.children[focusedIndex];

      if (activeItem) {
        activeItem.scrollIntoView({ block: "nearest" });
      }
    }
  }, [focusedIndex, isOpen]);

  const handleKeyDown = (event) => {
    if (!isOpen) return;

    if (event.key === "ArrowDown") {
      event.preventDefault();
      setFocusedIndex((prev) => (prev + 1) % items.length);
    }

    if (event.key === "ArrowUp") {
      event.preventDefault();
      setFocusedIndex((prev) => (prev - 1 + items.length) % items.length);
    }

    if (event.key === "Enter") {
      event.preventDefault();

      if (items[focusedIndex]) {
        onSelect(items[focusedIndex]);
      }
    }
  };

  const resetFocus = () => setFocusedIndex(0);

  return {
    focusedIndex,
    handleKeyDown,
    listRef,
    resetFocus
  };
};

export default useKeyboardNavigation;
