import { datadogRum } from "@datadog/browser-rum";
import { useAuth, useLoginWithRedirect } from "@frontegg/react";
import axios from "axios";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useCurrentAccountId, useEffectAsync } from "./hooks";
import MainLayout from "./layout/MainLayout";
import { api as apiAxiosInstance } from "./services/api";
import { setAxiosTokenInterceptor } from "./services/common";
import useAppStore from "./stores/appStore";
import {
  selectedAccountIdSelector,
  setupPermissionsSelector
} from "./stores/selectors/appSelectors";
import { HyroLoader } from "src/components/hyro-components";

const InitializePermissions = () => {
  const accountId = useAppStore(selectedAccountIdSelector);
  const httpReady = useAppStore(state => state.httpReady);
  const setHttpReady = useAppStore(state => state.setHttpReady);
  const setupPermissions = useAppStore(setupPermissionsSelector);
  const { user, isAuthenticated } = useAuth();
  const loginWithRedirect = useLoginWithRedirect();
  const navigate = useNavigate();
  const location = useLocation();

  // eslint-disable-next-line complexity
  useEffectAsync(() => {
    if (!isAuthenticated) {
      localStorage.setItem(
        "REDIRECT_AFTER_LOGIN",
        `${location.pathname}${location.search}${location.hash}`
      );
      loginWithRedirect();
      return;
    }

    const redirectPath = localStorage.getItem("REDIRECT_AFTER_LOGIN");

    if (redirectPath) {
      localStorage.removeItem("REDIRECT_AFTER_LOGIN");
      navigate(redirectPath);
    }

    setAxiosTokenInterceptor([apiAxiosInstance, axios], user.accessToken);
    setHttpReady(true);

    setupPermissions(user);

    datadogRum.setUser({
      id: user.sub,
      name: user.name,
      email: user.email
    });
  }, [
    isAuthenticated,
    loginWithRedirect,
    setupPermissions,
    accountId,
    user
  ]);

  return httpReady ? <MainLayout /> : <HyroLoader height="100vh" />;
};

export default InitializePermissions;
