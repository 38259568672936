import React from "react";

const CheckStatusIcon = () => (<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7802_10114)">
      <circle cx="24" cy="24" r="23.5" fill="#F3F3FF" stroke="#DEDFFF"/>
      <path d="M20 17.2C20 16.0799 20 15.5198 20.218 15.092C20.4097 14.7157 20.7157 14.4097 21.092 14.218C21.5198 14 22.0799 14 23.2 14H30.8C31.9201 14 32.4802 14 32.908 14.218C33.2843 14.4097 33.5903 14.7157 33.782 15.092C34 15.5198 34 16.0799 34 17.2V24.8C34 25.9201 34 26.4802 33.782 26.908C33.5903 27.2843 33.2843 27.5903 32.908 27.782C32.4802 28 31.9201 28 30.8 28H23.2C22.0799 28 21.5198 28 21.092 27.782C20.7157 27.5903 20.4097 27.2843 20.218 26.908C20 26.4802 20 25.9201 20 24.8V17.2Z" fill="#B3B7FF"/>
      <path d="M20 20V17.2C20 16.0799 20 15.5198 20.218 15.092C20.4097 14.7157 20.7157 14.4097 21.092 14.218C21.5198 14 22.0799 14 23.2 14H30.8C31.9201 14 32.4802 14 32.908 14.218C33.2843 14.4097 33.5903 14.7157 33.782 15.092C34 15.5198 34 16.0799 34 17.2V24.8C34 25.9201 34 26.4802 33.782 26.908C33.5903 27.2843 33.2843 27.5903 32.908 27.782C32.4802 28 31.9201 28 30.8 28H28" stroke="#4548C4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14 23.2C14 22.0799 14 21.5198 14.218 21.092C14.4097 20.7157 14.7157 20.4097 15.092 20.218C15.5198 20 16.0799 20 17.2 20H24.8C25.9201 20 26.4802 20 26.908 20.218C27.2843 20.4097 27.5903 20.7157 27.782 21.092C28 21.5198 28 22.0799 28 23.2V30.8C28 31.9201 28 32.4802 27.782 32.908C27.5903 33.2843 27.2843 33.5903 26.908 33.782C26.4802 34 25.9201 34 24.8 34H17.2C16.0799 34 15.5198 34 15.092 33.782C14.7157 33.5903 14.4097 33.2843 14.218 32.908C14 32.4802 14 31.9201 14 30.8V23.2Z" fill="#F3F3FF" stroke="#4548C4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18 26.8999L20 28.8999L24.5 24.3999" stroke="#EF4068" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_7802_10114">
        <rect width="48" height="48" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default CheckStatusIcon;
