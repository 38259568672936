import React from "react";

const SpotIcon = () => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7780_33899)">
      <circle cx="24" cy="24" r="23.5" fill="#F3F3FF" stroke="#DEDFFF"/>
      <path d="M22.5494 30.95C27.1886 30.95 30.9494 27.1892 30.9494 22.55C30.9494 17.9108 27.1886 14.15 22.5494 14.15C17.9102 14.15 14.1494 17.9108 14.1494 22.55C14.1494 27.1892 17.9102 30.95 22.5494 30.95Z" fill="#B3B7FF"/>
      <path d="M33.0494 33.05L28.4819 28.4825M30.9494 22.55C30.9494 27.1892 27.1886 30.95 22.5494 30.95C17.9102 30.95 14.1494 27.1892 14.1494 22.55C14.1494 17.9108 17.9102 14.15 22.5494 14.15C27.1886 14.15 30.9494 17.9108 30.9494 22.55Z" stroke="#4548C4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22.8218 28.6C19.3854 28.6 16.5996 25.8142 16.5996 22.3778" stroke="#F3F3FF" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M28.3246 12.6L29.1483 14.2473C29.4271 14.8049 29.5664 15.0836 29.7526 15.3252C29.9179 15.5395 30.11 15.7317 30.3244 15.8969C30.566 16.0831 30.8447 16.2225 31.4022 16.5013L33.0496 17.325L31.4022 18.1487C30.8447 18.4274 30.566 18.5668 30.3244 18.753C30.11 18.9182 29.9179 19.1104 29.7526 19.3248C29.5664 19.5663 29.4271 19.8451 29.1483 20.4026L28.3246 22.05L27.5009 20.4026C27.2222 19.8451 27.0828 19.5663 26.8966 19.3248C26.7313 19.1104 26.5392 18.9182 26.3248 18.753C26.0833 18.5668 25.8045 18.4274 25.247 18.1487L23.5996 17.325L25.247 16.5013C25.8045 16.2225 26.0833 16.0831 26.3248 15.8969C26.5392 15.7317 26.7313 15.5395 26.8966 15.3252C27.0828 15.0836 27.2222 14.8049 27.5009 14.2473L28.3246 12.6Z" fill="#FFCFDA" stroke="#EF4068" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_7780_33899">
        <rect width="48" height="48" fill="white"/>
      </clipPath>
    </defs>
  </svg>
;

export default SpotIcon;
