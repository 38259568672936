import { Typography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import EmptySearch from "src/static/images/empty_search.svg";

const StyledEmptyState = styled(Stack)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "calc(100vh - 200px)"
}));

const EmptyState = () => (
    <StyledEmptyState>
        <img src={EmptySearch} alt="" />
        <Typography variant="subtitle1" color="grey.700" sx={{ mt: 2 }}>No results found.</Typography>
        <Typography variant="body2" color="grey.500" sx={{ mt: .5 }}>Check your search term and try again.</Typography>
    </StyledEmptyState>
  );

export default EmptyState;
