import React from "react";
import { useLocation } from "react-router-dom";

import AccountAssistants from "../../pages/AccountAssistants";
import ErrorBoundary from "../ErrorBoundries";
import { AppWrapper, ContentWrapper } from "../LayoutWrappers";
import { useTheme } from "@mui/material";

const AccountLayout = () => {
  const location = useLocation();
  const theme = useTheme();

  return (
    <AppWrapper className="app" sx={{ backgroundColor: "#FAFAFA" }}>
      <ContentWrapper component="main" overrideLayout={{
        maxWidth: 1640,
        margin: "0 auto",
        [`@media (max-width: ${theme.breakpoints.desktop - 1}px)`]: {
          maxWidth: 1200,
          margin: "0 auto"
        }
      }}>
        <ErrorBoundary key={location.pathname}>
          <AccountAssistants />
        </ErrorBoundary>
      </ContentWrapper>
    </AppWrapper>
  );
};

export default AccountLayout;
