import React from "react";

const LinkToChatIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7807_9761)">
      <circle cx="24" cy="24" r="23.5" fill="#F3F3FF" stroke="#DEDFFF"/>
      <path d="M33 23.52C33 25.1329 33 28.0514 32.673 28.6675C32.3854 29.2094 31.9265 29.65 31.362 29.9261C30.7202 30.24 29.8802 30.24 28.2 30.24H25.6837C25.0597 30.24 24.7477 30.24 24.4492 30.2988C24.1844 30.3509 23.9282 30.4372 23.6875 30.5553C23.4162 30.6883 23.1725 30.8755 22.6852 31.2497L20.2998 33.0818C19.8837 33.4013 19.6756 33.5611 19.5005 33.5613C19.3483 33.5614 19.2042 33.495 19.1092 33.3807C19 33.2494 19 32.9936 19 32.482V30.24C18.07 30.24 17.605 30.24 17.2235 30.1418C16.1883 29.8755 15.3796 29.0992 15.1022 28.1054C15 27.7391 15 27.2927 15 26.4V20.448C15 18.835 15 18.0285 15.327 17.4125C15.6146 16.8706 16.0735 16.43 16.638 16.1539C17.2798 15.84 20.3198 15.84 22 15.84" stroke="#4548C4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24.6794 18.7799L23.8544 19.5719C22.7154 20.6654 22.7154 22.4382 23.8544 23.5317C24.9934 24.6252 26.8402 24.6252 27.9792 23.5317L28.8041 22.7397M27.9792 15.6121L28.8041 14.8201C29.9432 13.7267 31.7899 13.7267 32.9289 14.8201C34.068 15.9136 34.068 17.6865 32.9289 18.7799L32.104 19.5719M26.35 21.1359L30.4333 17.2159" stroke="#EF4068" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_7807_9761">
        <rect width="48" height="48" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default LinkToChatIcon;
