/* eslint-disable no-mixed-operators */
import { get as apiGet } from "src/services/api";
import { SYSTEM_MODES } from "src/stores/slices/createAppSlice";

import { previewService } from "../../services";
import { BUILD_STATUSES, SEVERITY_VARIANTS } from "../../utils";

const SUCCESS_ALERT = {
  message: "Call has been triggered successfully",
  severity: SEVERITY_VARIANTS.SUCCESS
};

const ERROR_ALERT = {
  message: "Something went wrong please try again",
  severity: SEVERITY_VARIANTS.ERROR
};

class BranchDeployerError extends Error {
  constructor(message) {
    super(message);
    this.name = "Branch deployer build failed";
  }
}

const createPreviewSlice = (set, get) => ({
  shouldRecreatePreview: false,
  previewAssistantId: null,
  forceDisableBuild: true,
  buildStatusPollingInProgress: false,
  branchDeployerBuildFailed: false,
  disablePublish: false,
  buildDraftResponse: { status: "", url: "" },
  setBuildDraftResponse: data => set({ buildDraftResponse: data }),
  setShouldRecreatePreview: bool => set({ shouldRecreatePreview: bool }),
  updateDisablePublish: bool => set({ disablePublish: bool }),
  pollBuildStatus: async () => {
    const { lastBuildAbort, selectedAssistant, draft } = get();
    set({ buildStatusPollingInProgress: true, branchDeployerBuildFailed: false });

    try {
      if (lastBuildAbort) {
        lastBuildAbort.abort();
      }
      const abort = new AbortController();
      set({ lastBuildAbort: abort });

      // eslint-disable-next-line no-constant-condition
      const startPolling = async () => {
        const branchDeployerResponse = await apiGet(`/assistants/${selectedAssistant.assistantId}/build/status?branchName=${draft?.branchName}`, null, abort.signal);
        set({ buildDraftResponse: branchDeployerResponse });

        if (branchDeployerResponse?.status === BUILD_STATUSES.LIVE || branchDeployerResponse?.status === "" || branchDeployerResponse?.status === BUILD_STATUSES.CHANGED) {
          const notifications = await apiGet(`/assistants/${selectedAssistant.assistantId}/notifications`, null, abort.signal);

          set({
            forceDisableBuild: false,
            lastBuildAbort: null,
            shouldRecreatePreview: false,
            buildStatusPollingInProgress: false,
            branchDeployerBuildFailed: false,
            buildDraftResponse: branchDeployerResponse,
            notifications
          });
          return; // Stop polling as the desired status is reached
        }

 if (branchDeployerResponse?.status === BUILD_STATUSES.FAILED) {
          const notifications = await apiGet(`/assistants/${selectedAssistant.assistantId}/notifications`, null, abort.signal);
          set({ notifications });
          throw new BranchDeployerError();
        }

        // Wait for 60 seconds before making the next request
        await new Promise(resolve => setTimeout(resolve, 60000));

        if (selectedAssistant.assistantId !== get().selectedAssistant.assistantId) return;

        await startPolling(); // Recursively call the function again for the next poll}
      };

      await startPolling();
    } catch (error) {
      set({
        shouldRecreatePreview: false,
        buildStatusPollingInProgress: false,
        branchDeployerBuildFailed: true,
        forceDisableBuild: true
      });

      if (error?.name !== "CanceledError") {
        if(!(error instanceof BranchDeployerError)) {
          console.error(error);
        }
        set({ buildDraftResponse: { status: "", url: "" } });
      }
    }
  },

  setIsAssistantPreviewable: () => {
    const {
      currentCollectionAssistantConfig,
      isSavingChanges,
      isDialogLoading,
      hasValidationErrors,
      systemMode
    } = get();
    const isAssistantPreviewable =
      (systemMode === SYSTEM_MODES.READ_ONLY || systemMode === SYSTEM_MODES.EDIT && Object.values(currentCollectionAssistantConfig).length > 0
      && !isSavingChanges
      && !isDialogLoading
      && !hasValidationErrors);
    set({ isAssistantPreviewable });
    return isAssistantPreviewable;
  },

  initiateCallToAssistant: async (phoneNumber) => {
    const { previewAssistantId } = get();

    try {
      await previewService.initiateTestCall({
        botId: previewAssistantId,
        phoneNumber
      });
      return SUCCESS_ALERT;
    } catch (error) {
      return ERROR_ALERT;
    }
  }
});

export default createPreviewSlice;
