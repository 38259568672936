import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Portal } from "@mui/material";
import Field from "src/components/common/Field";
import { FIELD_TYPES } from "src/components/common/fields/utils";
import { isEmail, SEVERITY_VARIANTS } from "src/utils";
import useSnackbar from "src/components/common/hooks/useSnackbar";
import HyroFeedbackMessage from "src/components/hyro-components/HyroFeedbackMessage";
import { useMutateSharePreview, useExternalPreviewLink } from "src/queries/hooks/preview";
import { selectedAccountIdSelector, assistantIdSelector, userDataSelector, systemModeSelector, hasPermissionSelector } from "src/stores/selectors/appSelectors";
import useAppStore from "src/stores/appStore";
import { LoadingButton } from "@mui/lab";
import { SNIPPET_Z_INDEX } from "src/components/widgets/overview/Snippet";
import CopyLinkIcon from "src/icons/CopyLinkIcon";
import CopyToClipboard from "react-copy-to-clipboard";
import { draftSelector } from "src/stores/selectors/tripletsSelectors";
import { SYSTEM_MODES } from "src/stores/slices/createAppSlice";
import { datadogRum } from "@datadog/browser-rum";
import { CUSTOM_ACTIONS } from "src/analytics/notifyCustomActions";
import { USER_PERMISSIONS } from "@hyro/dashboard-commons";

const SharePreviewDialog = ({ isSharePreviewDialogOpen, setIsSharePreviewDialogOpen }) => {
    const [emails, setEmails] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState(SEVERITY_VARIANTS.SUCCESS);
    const accountId = useAppStore(selectedAccountIdSelector);
    const assistantId = useAppStore(assistantIdSelector);
    const systemMode = useAppStore(systemModeSelector);
    const userData = useAppStore(userDataSelector);
    const draft = useAppStore(draftSelector);
    const hasPermission = useAppStore(hasPermissionSelector);
    const isSharePreviewAllowed = hasPermission(USER_PERMISSIONS.EXTERNAL_PREVIEW.READ);
    const sharePreviewMutation = useMutateSharePreview();
    const branchName = systemMode === SYSTEM_MODES.EDIT ? draft?.branchName : undefined;
    const { data: previewLink } = useExternalPreviewLink(isSharePreviewAllowed ? { accountId, assistantId, branchName } : {});
    const [
        isSnackbarOpen,
        snackbarMessage,
        addSnackbarMessage,
        exitSnackbar,
        closeSnackbar
      ] = useSnackbar();

    useEffect(() => {
        if (emails.length === 0) {
            setError(null);
            return;
        }

        const invalidEmails = emails.filter(email => !isEmail(email));

        if (invalidEmails.length > 0) {
            setError(`Invalid email address${invalidEmails.length > 1 ? "es" : ""}: ${invalidEmails.join(", ")}`);
        } else {
            setError(null);
        }
    }, [emails]);

    useEffect(() => {
        if (!isSharePreviewDialogOpen) {
            setEmails([]);
        }
    }, [isSharePreviewDialogOpen]);

    const handleShare = async () => {
        if (error || emails?.length === 0) {
            return;
        }

        datadogRum.addAction(CUSTOM_ACTIONS.PREVIEW_SHARED, { assistantId, accountId, numOfRecipients: emails.length });

        setLoading(true);

        const params = { accountId, assistantId };
        const payload = { emails, userName: userData?.name, branchName };

        try {
            await sharePreviewMutation.mutateAsync({ params, payload });

            addSnackbarMessage("Assistant preview shared successfully");
            setSnackbarSeverity(SEVERITY_VARIANTS.SUCCESS);
        } catch (error) {
            addSnackbarMessage("Action failed. Please contact support.");
            setSnackbarSeverity(SEVERITY_VARIANTS.ERROR);
            return;
        } finally {
            setIsSharePreviewDialogOpen(false);
            setLoading(false);
        }
    };

    const handleCopyEnd = (_, result) => {
        if (result) {
            addSnackbarMessage("Share link successfully copied");
            setSnackbarSeverity(SEVERITY_VARIANTS.SUCCESS);
        } else {
            addSnackbarMessage("Action failed. Please contact support.");
            setSnackbarSeverity(SEVERITY_VARIANTS.ERROR);
        }
    };

    return <>
        <Dialog
            sx={{ zIndex: isSharePreviewDialogOpen ? SNIPPET_Z_INDEX + 1 : 0 }}
            open={isSharePreviewDialogOpen}
            onClose={() => setIsSharePreviewDialogOpen(false)}>
            <DialogTitle data-testid="share-preview-dialog-title">Share Assistant Preview</DialogTitle>

            <DialogContent>
                <Typography variant="body1" sx={{ marginBlock: "8px 10px" }}>
                    Share this assistant preview with others by entering their email below.
                </Typography>

                <Field sx={{ zIndex: SNIPPET_Z_INDEX + 1 }} label="Recipient's email address" fieldType={FIELD_TYPES.LIST} value={emails} error={error} showAllChips showTagsInPopper={false} testId="share-preview-email-field" helperText="To add multiple emails, type an email and hit Enter." onChange={setEmails} />
            </DialogContent>

            <DialogActions sx={{ padding: "24px" }}>
                {!!previewLink && <CopyToClipboard text={previewLink} onCopy={handleCopyEnd}>
                    <Button sx={{ marginRight: "auto" }} color="primary" data-testid="share-preview-copy-link" startIcon={<CopyLinkIcon />}>Copy link</Button>
                </CopyToClipboard>}
                <Button sx={{ color: "#00000099" }} onClick={() => setIsSharePreviewDialogOpen(false)}>Cancel</Button>
                <LoadingButton variant="contained" color="primary" loading={loading} disabled={!!error} data-testid="share-preview-share-button" onClick={handleShare}>Share</LoadingButton>
            </DialogActions>
        </Dialog>

        <Portal>
            <HyroFeedbackMessage
                sx={{ zIndex: SNIPPET_Z_INDEX + 1 }}
                message={snackbarMessage}
                severity={snackbarSeverity}
                showFeedback={isSnackbarOpen}
                onExited={exitSnackbar}
                closeFeedback={closeSnackbar}
                />
        </Portal>
    </>;
};

export default SharePreviewDialog;
