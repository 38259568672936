import { COLLECTION_PAGES, PAGE_PERMISSIONS, pathMatches, PORTAL_PAGES } from "./utils";
import { useCallback, useEffect, useState, useRef } from "react";
import { useLocation, useMatch } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import useAppStore from "./stores/appStore";
import {
  hasPageErrorTypeSelector,
  hasPermissionSelector,
  systemModeSelector, userPermissionsSelector
} from "./stores/selectors/appSelectors";
import { SYSTEM_MODES } from "./stores/slices/createAppSlice";
import { draftSelector } from "./stores/selectors/tripletsSelectors";
import { useAuth } from "@frontegg/react";
import { getErrorPageFromStatus } from "src/layout/ErrorPages/ErrorPage";
import { useFetchDashboardSettings } from "src/queries/hooks/settings";
import { COLORS } from "src/components/admin/Settings/consts";

const useEffectAsync = (f, deps) => {
  useEffect(() => {
    f();
    // eslint-disable-next-line
  }, deps);
};

const useIsAdminPanel = () => {
  const { pathname } = useLocation();
  const [isAdminPanel, setIsAdminPanel] = useState(pathname.includes("/admin"));

  useEffect(() => {
    setIsAdminPanel(pathname.includes("/admin"));
  }, [pathname]);

  return isAdminPanel;
};

const useIsCollection = () => {
  const [isCollectionPage, setIsCollectionPage] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setIsCollectionPage(pathMatches(COLLECTION_PAGES)(pathname));
  }, [pathname]);

  return isCollectionPage;
};

const useIsPortalPage = () => {
  const [isPortalPage, setIsPortalPage] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    setIsPortalPage(pathMatches(PORTAL_PAGES)(pathname));
  }, [pathname]);
  return isPortalPage;
};

const useIsCurrentPageReadonly = () => {
  const hasPermission = useAppStore(hasPermissionSelector);
  const userPermissions = useAppStore(userPermissionsSelector);

  const [isPageReadonly, setIsPageReadonly] = useState(false);
  const { pathname } = useLocation();
  const page = useCurrentAssistantPage();

  useEffect(() => {
    PAGE_PERMISSIONS[page]?.WRITE &&
    !hasPermission(PAGE_PERMISSIONS[page]?.WRITE) ?
      setIsPageReadonly(true) :
      setIsPageReadonly(false);
  }, [hasPermission, userPermissions, page, pathname]);
  return isPageReadonly;
};

export const useDisplayDashboardSettingsBanner = () => {
  const dashboardSettings = useFetchDashboardSettings()?.data;
  const systemMode = useAppStore(systemModeSelector);
  const isBannerVisible = dashboardSettings?.banner?.show;
  const isMaintenanceBanner = dashboardSettings?.banner?.color === COLORS.MAINTENANCE && systemMode === SYSTEM_MODES.READ_ONLY;
  const isIncidentBanner = dashboardSettings?.banner?.color === COLORS.INCIDENT;
  return (isBannerVisible && (isMaintenanceBanner || isIncidentBanner));
};

export const useStatusBarDisplayed = () => {
  const isPortalPage = useIsPortalPage();
  const isPageReadonly = useIsCurrentPageReadonly();
  const draft = useAppStore(draftSelector);
  const systemMode = useAppStore(systemModeSelector);
  const displaySettingsBanner = useDisplayDashboardSettingsBanner();

  return displaySettingsBanner || (systemMode === SYSTEM_MODES.EDIT && ((isPortalPage && draft) || isPageReadonly));
};

const useCurrentAssistantPage = () => {
  const matchPage = useMatch("/:accountId/:assistantId/:page/*");
  return matchPage?.params?.page ?? "";
};

// only use for non admin pages
const useCurrentAccountId = () => {
  const matchPage = useMatch("/:accountId/:assistantId/:page/*");
  return matchPage?.params?.accountId ?? "";
};

const useCurrentAdminPage = () => {
  const matchPage = useMatch("/admin/*");
  return matchPage ? matchPage.params["*"] : null;
};

const useCurrentFullAssistantPage = () => {
  const matchPage = useMatch("/:accountId/:assistantId/*");
  return matchPage ? matchPage.params["*"] : null;
};

const useIsMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("sm"));
};

const useAccessToken = () => {
  const { user } = useAuth();
  return user?.accessToken;
};

const useAsyncError = () => {
  // eslint-disable-next-line
  const [_, setError] = useState();
  return useCallback(
    (e) => {
      setError(() => {
        throw e;
      });
    },
    [setError]
  );
};

const useErrorPage = () => {
  const pageErrorType = useAppStore(hasPageErrorTypeSelector);

  if (pageErrorType === null) {
    return null;
  }
  return getErrorPageFromStatus(pageErrorType);
};

const useHasSystemModeChanged = ({ from = [], to = [] }) => {
  const isFirstRender = useRef(true);
  const systemMode = useAppStore(systemModeSelector);
  const previousSystemMode = useRef(null);
  const [hasChanged, setHasChanged] = useState({ value: false });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const isValidFrom = from.length === 0 || from.includes(previousSystemMode.current);
    const isValidTo = to.length === 0 || to.includes(systemMode);

    if (isValidFrom && isValidTo && previousSystemMode.current !== systemMode) {
      setHasChanged({ value: true });
    } else {
      setHasChanged({ value: false });
    }

    previousSystemMode.current = systemMode;
  }, [systemMode]);

  return hasChanged;
};

export {
  useIsCollection,
  useAccessToken,
  useCurrentAdminPage,
  useCurrentAssistantPage,
  useCurrentFullAssistantPage,
  useCurrentAccountId,
  useEffectAsync,
  useIsAdminPanel,
  useIsPortalPage,
  useIsCurrentPageReadonly,
  useIsMobile,
  useAsyncError,
  useErrorPage,
  useHasSystemModeChanged
};
