import React, { useEffect, useState } from "react";
import HyroFeedbackMessage from "src/components/hyro-components/HyroFeedbackMessage";
import {
  isDialogLoadingSelector,
  isPageLoadingSelector,
  isSavingChangesSelector
} from "src/stores/selectors/collectionsSelectors";
import { deleteWidgetCookie, WIDGET_TOKEN } from "src/utils";

import EditModeHeader from "./header-types/EditModeHeader";
import PreviewModeHeader from "./header-types/PreviewModeHeader";
import ReadOnlyModeHeader from "./header-types/ReadOnlyModeHeader";
import { Header } from "./HeaderStyles";
import Snippet from "../../components/widgets/overview/Snippet";
import { useIsAdminPanel } from "../../hooks";
import useAppStore from "../../stores/appStore";
import {
  isWebSnippetOpenSelector,
  previousSystemModeSelector,
  selectedAccountIdSelector,
  selectedAssistantSelector,
  setIsAssistantPreviewableSelector,
  showHeaderSkeletonSelector,
  systemModeSelector
} from "../../stores/selectors/appSelectors";
import { draftSelector } from "../../stores/selectors/tripletsSelectors";
import { SYSTEM_MODES } from "../../stores/slices/createAppSlice";
import { COLORS } from "../consts";

const HeaderComponent = () => {
  const showHeaderSkeleton = useAppStore(showHeaderSkeletonSelector);
  const isWebSnippetOpen = useAppStore(isWebSnippetOpenSelector);
  const systemMode = useAppStore(systemModeSelector);
  const previousSystemMode = useAppStore(previousSystemModeSelector);
  const selectedAssistant = useAppStore(selectedAssistantSelector);
  const buildDraftResponse = useAppStore(state => state.buildDraftResponse);
  const setIsAssistantPreviewable = useAppStore(setIsAssistantPreviewableSelector);
  const accountId = useAppStore(selectedAccountIdSelector);
  const isSavingChanges = useAppStore(isSavingChangesSelector);
  const isDialogLoading = useAppStore(isDialogLoadingSelector);
  const isPageLoading = useAppStore(isPageLoadingSelector);
  const isCollectionUpdating = isSavingChanges || isDialogLoading;
  const isAdminPanel = useIsAdminPanel();
  const draft = useAppStore(draftSelector);
  const [feedbackConfig, setFeedbackConfig] = useState({
    open: false,
    message: "",
    severity: ""
  });

  useEffect(() => {
    if ((systemMode === SYSTEM_MODES.EDIT && previousSystemMode === SYSTEM_MODES.READ_ONLY)
      || (systemMode === SYSTEM_MODES.READ_ONLY && previousSystemMode === SYSTEM_MODES.EDIT)) {
      deleteWidgetCookie(WIDGET_TOKEN);
    }
  }, [systemMode, previousSystemMode]);

  useEffect(() => {
    setIsAssistantPreviewable();
  }, [
    accountId,
    isPageLoading,
    isCollectionUpdating,
    setIsAssistantPreviewable,
    systemMode
  ]);

  const getHeaderType = () => {
    if (systemMode === SYSTEM_MODES.READ_ONLY || isAdminPanel) {
      return <ReadOnlyModeHeader selectedAssistant={selectedAssistant} setFeedbackConfig={setFeedbackConfig}/>;
    }

    if (systemMode === SYSTEM_MODES.EDIT) {
      return <EditModeHeader selectedAssistant={selectedAssistant} setFeedbackConfig={setFeedbackConfig}/>;
    }

    if (systemMode === SYSTEM_MODES.PREVIEW && selectedAssistant) {
      return <PreviewModeHeader />;
    }
    return <></>;
  };

  const getHeaderColor = () => {
    if (systemMode === SYSTEM_MODES.READ_ONLY || isAdminPanel) {
      return COLORS.HEADER_READ_ONLY_BG;
    }

    if (systemMode === SYSTEM_MODES.EDIT) {
      return COLORS.HEADER_EDIT_BG;
    }

    if (systemMode === SYSTEM_MODES.PREVIEW) {
      return previousSystemMode === SYSTEM_MODES.EDIT ? COLORS.HEADER_EDIT_BG : COLORS.HEADER_READ_ONLY_BG;
    }
    return COLORS.HEADER_READ_ONLY_BG;
  };

  return (
    <Header
      showHeaderSkeleton={showHeaderSkeleton}
      editBackground={getHeaderColor()}>
      {getHeaderType()}
      <Snippet
        open={isWebSnippetOpen}
        usePreviewUrl={previousSystemMode === SYSTEM_MODES.EDIT && draft}
        bdWidgetServerUrl={buildDraftResponse?.url}
        assistantId={selectedAssistant?.assistantId} />
      <HyroFeedbackMessage
        showFeedback={feedbackConfig.open}
        closeFeedback={() => setFeedbackConfig({ ...feedbackConfig, open: false })}
        message={feedbackConfig.message}
        severity={feedbackConfig.severity}
      />
    </Header>
  );
};

export default HeaderComponent;
