import { get } from "src/services/api";

export const fetchAssistant = async (params) => {
  const url = `/accounts/${params.accountId}/assistants/${params.assistantId}`;
  return await get(url);
};

export default {
  fetchAssistant
};
