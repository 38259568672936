import React from "react";

const CallToTextIcon = () => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7802_10118)">
      <circle cx="24" cy="24" r="23.5" fill="#F3F3FF" stroke="#DEDFFF"/>
      <path d="M15 19.8C15 18.1198 15 17.2798 15.327 16.638C15.6146 16.0735 16.0735 15.6146 16.638 15.327C17.2798 15 18.1198 15 19.8 15H28.2C29.8802 15 30.7202 15 31.362 15.327C31.9265 15.6146 32.3854 16.0735 32.673 16.638C33 17.2798 33 18.1198 33 19.8V25.2C33 26.8802 33 27.7202 32.673 28.362C32.3854 28.9265 31.9265 29.3854 31.362 29.673C30.7202 30 29.8802 30 28.2 30H25.6837C25.0597 30 24.7477 30 24.4492 30.0613C24.1844 30.1156 23.9282 30.2055 23.6875 30.3285C23.4162 30.4671 23.1725 30.662 22.6852 31.0518L20.2998 32.9602C19.8837 33.2931 19.6756 33.4595 19.5005 33.4597C19.3483 33.4599 19.2042 33.3906 19.1092 33.2716C19 33.1348 19 32.8684 19 32.3355V30C18.07 30 17.605 30 17.2235 29.8978C16.1883 29.6204 15.3796 28.8117 15.1022 27.7765C15 27.395 15 26.93 15 26V19.8Z" stroke="#4548C4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <circle cx="20" cy="22" r="1" fill="#FFCFDA" stroke="#EF4068"/>
      <circle cx="24" cy="22" r="1" fill="#FFCFDA" stroke="#EF4068"/>
      <circle cx="28" cy="22" r="1" fill="#FFCFDA" stroke="#EF4068"/>
    </g>
    <defs>
      <clipPath id="clip0_7802_10118">
        <rect width="48" height="48" fill="white"/>
      </clipPath>
    </defs>
  </svg>
;

export default CallToTextIcon;

