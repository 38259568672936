import React from "react";

const SmartTransferIcon = () => (<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7802_10119)">
      <circle cx="24" cy="24" r="23.5" fill="#F3F3FF" stroke="#DEDFFF"/>
      <path d="M30 26C31.1046 26 32 25.1046 32 24C32 22.8954 31.1046 22 30 22C28.8954 22 28 22.8954 28 24C28 25.1046 28.8954 26 30 26Z" fill="#B3B7FF"/>
      <path d="M16 26C17.1046 26 18 25.1046 18 24C18 22.8954 17.1046 22 16 22C14.8954 22 14 22.8954 14 24C14 25.1046 14.8954 26 16 26Z" fill="#B3B7FF"/>
      <path d="M30 18C31.1046 18 32 17.1046 32 16C32 14.8954 31.1046 14 30 14C28.8954 14 28 14.8954 28 16C28 17.1046 28.8954 18 30 18Z" fill="#B3B7FF"/>
      <path d="M30 34C31.1046 34 32 33.1046 32 32C32 30.8954 31.1046 30 30 30C28.8954 30 28 30.8954 28 32C28 33.1046 28.8954 34 30 34Z" fill="#B3B7FF"/>
      <path d="M28 32H27.8C26.1198 32 25.2798 32 24.638 31.673C24.0735 31.3854 23.6146 30.9265 23.327 30.362C23 29.7202 23 28.8802 23 27.2V20.8C23 19.1198 23 18.2798 23.327 17.638C23.6146 17.0735 24.0735 16.6146 24.638 16.327C25.2798 16 26.1198 16 27.8 16H28M28 32C28 33.1046 28.8954 34 30 34C31.1046 34 32 33.1046 32 32C32 30.8954 31.1046 30 30 30C28.8954 30 28 30.8954 28 32ZM28 16C28 17.1046 28.8954 18 30 18C31.1046 18 32 17.1046 32 16C32 14.8954 31.1046 14 30 14C28.8954 14 28 14.8954 28 16ZM18 24L28 24M18 24C18 25.1046 17.1046 26 16 26C14.8954 26 14 25.1046 14 24C14 22.8954 14.8954 22 16 22C17.1046 22 18 22.8954 18 24ZM28 24C28 25.1046 28.8954 26 30 26C31.1046 26 32 25.1046 32 24C32 22.8954 31.1046 22 30 22C28.8954 22 28 22.8954 28 24Z" stroke="#4548C4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 26C17.1046 26 18 25.1046 18 24C18 22.8954 17.1046 22 16 22C14.8954 22 14 22.8954 14 24C14 25.1046 14.8954 26 16 26Z" fill="#FFCFDA"/>
      <path d="M16 26C17.1046 26 18 25.1046 18 24C18 22.8954 17.1046 22 16 22C14.8954 22 14 22.8954 14 24C14 25.1046 14.8954 26 16 26Z" stroke="#EF4068" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_7802_10119">
        <rect width="48" height="48" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default SmartTransferIcon;
