import React from "react";

const ControlledResponseIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7802_10115)">
      <circle cx="24" cy="24" r="23.5" fill="#F3F3FF" stroke="#DEDFFF"/>
      <path d="M32 22V18.8C32 17.1198 32 16.2798 31.673 15.638C31.3854 15.0735 30.9265 14.6146 30.362 14.327C29.7202 14 28.8802 14 27.2 14H20.8C19.1198 14 18.2798 14 17.638 14.327C17.0735 14.6146 16.6146 15.0735 16.327 15.638C16 16.2798 16 17.1198 16 18.8V29.2C16 30.8802 16 31.7202 16.327 32.362C16.6146 32.9265 17.0735 33.3854 17.638 33.673C18.2798 34 19.1198 34 20.8 34H22.5M25 23H20M23 27H20M28 19H20" stroke="#4548C4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M26 30.1C26 29.5399 26 29.2599 26.109 29.046C26.2049 28.8578 26.3578 28.7049 26.546 28.609C26.7599 28.5 27.0399 28.5 27.6 28.5H31.4C31.9601 28.5 32.2401 28.5 32.454 28.609C32.6422 28.7049 32.7951 28.8578 32.891 29.046C33 29.2599 33 29.5399 33 30.1V30.9C33 31.4601 33 31.7401 32.891 31.954C32.7951 32.1422 32.6422 32.2951 32.454 32.391C32.2401 32.5 31.9601 32.5 31.4 32.5H27.6C27.0399 32.5 26.7599 32.5 26.546 32.391C26.3578 32.2951 26.2049 32.1422 26.109 31.954C26 31.7401 26 31.4601 26 30.9V30.1Z" fill="#FFCFDA"/>
      <path d="M31.25 29V27.25C31.25 26.2835 30.4665 25.5 29.5 25.5C28.5335 25.5 27.75 26.2835 27.75 27.25V29M27.6 33H31.4C31.9601 33 32.2401 33 32.454 32.891C32.6422 32.7951 32.7951 32.6422 32.891 32.454C33 32.2401 33 31.9601 33 31.4V30.6C33 30.0399 33 29.7599 32.891 29.546C32.7951 29.3578 32.6422 29.2049 32.454 29.109C32.2401 29 31.9601 29 31.4 29H27.6C27.0399 29 26.7599 29 26.546 29.109C26.3578 29.2049 26.2049 29.3578 26.109 29.546C26 29.7599 26 30.0399 26 30.6V31.4C26 31.9601 26 32.2401 26.109 32.454C26.2049 32.6422 26.3578 32.7951 26.546 32.891C26.7599 33 27.0399 33 27.6 33Z" stroke="#EF4068" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_7802_10115">
        <rect width="48" height="48" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);

export default ControlledResponseIcon;
