import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import React from "react";

import { CONFIRMATION_DIALOG } from "./AccountEditorConsts";

const ConfirmationDialog = ({
  open,
  isLoading,
  onDiscard,
  onClose,
  onSubmit,
  isSaveDisabled,
  accountName
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogContent>
      <Typography variant="body1" color="text.primary">
        {CONFIRMATION_DIALOG.title.replace("$accountName", accountName)}
      </Typography>
    </DialogContent>
    <DialogActions sx={{ px: 3, pb: 3 }}>
      <Button onClick={onDiscard}>Discard</Button>
      <LoadingButton
        loading={isLoading}
        disabled={isSaveDisabled}
        variant="contained"
        onClick={onSubmit}
        data-testid='save-changes'
      >
        {CONFIRMATION_DIALOG.buttonText}
      </LoadingButton>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
