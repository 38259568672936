import { styled } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import AssistantCard from "./AssistantCard";
import { ASSISTANT_TYPES, CHANNEL_TO_DISPLAY_NAME } from "src/utils";

const AssistantsContainer = styled("div")(({ theme }) => ({
    display: "grid",
    gap: theme.spacing(3),
    marginBlock: theme.spacing(2, 3),
    [theme.breakpoints.up("xs")]: {
      gridTemplateColumns: "minmax(0, 1fr)"
    },
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "repeat(3, minmax(0, 392px))"
    },
    [`@media(min-width: ${theme.breakpoints.desktop}px)`]: {
      gridTemplateColumns: "repeat(4, minmax(0, 392px))"
    },
    justifyContent:"center"
  }));

const AssistantsList = ({ assistants, showSkeleton = false, testId }) => {
    const navigate = useNavigate();

    return <AssistantsContainer data-testid={testId}>
        {assistants.map((assistant) => {
        const {
            id: assistantId,
            assistantName,
            type,
            assistantChannel,
            liveAt: wentLiveDate,
            createdAt: createdDate
        } = assistant;
        return (
            <AssistantCard
            key={assistantId}
            assistantId={assistantId}
            assistantName={assistantName}
            date={type === ASSISTANT_TYPES.LIVE ? wentLiveDate : createdDate}
            dateType={type === ASSISTANT_TYPES.LIVE ? "Live since" : "Created on"}
            channel={ CHANNEL_TO_DISPLAY_NAME[assistantChannel] || "Unknown Channel"}
            assistantType={type || "Unknown Type"}
            showSkeleton={showSkeleton}
            onClick={() => {
              if (assistantId && !showSkeleton) {
              navigate(`${assistantId}`);
              }
            }}
            />
        );
        })}
    </AssistantsContainer>;
};

export default AssistantsList;
