import { Button, Chip, Grid, Stack, Tooltip, tooltipClasses, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import React from "react";

import { FAILED_REQUEST_TEXT, NOTIFICATIONS_STATE_TEXTS } from "./consts";
import { notifyNotificationsMenu } from "../../../analytics/notifyCustomActions";
import useAppStore from "../../../stores/appStore";
import { isFirstDraftSelector } from "../../../stores/selectors/tripletsSelectors";
import { NOTIFICATION_TYPES, NOTIFICATIONS_STATES } from "../../../utils";
import dayjs from "dayjs";
import { isDraftOwnerSelector } from "src/stores/selectors/appSelectors";

const StyledButton = styled(Button)(() => ({
  "&:focus": {
    outline: 0
  },
  "color": "rgba(0, 0, 0, 0.87)",
  "textTransform": "none",
  "fontWeight": 500,
  "opacity": 0.6,
  "fontSize": "12px"
}));

const StyledBox = styled(Box, {
  shouldForwardProp: prop => prop !== "backgroundColor"
})(({ backgroundColor }) => ({
  margin: "8px",
  overflow: "hidden",
  maxWidth: "290px",
  padding: "8px",
  borderRadius: "4px",
  backgroundColor
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#323232"
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#323232",
    maxWidth: 160
  }
}));

const StyledChip = styled(Chip, {
  shouldForwardProp: prop => prop !== "backgroundColor"
})(({ backgroundColor }) => ({
  backgroundColor,
  border: "none",
  color: "white",
  fontSize: "12px",
  fontWeight: 500,
  padding: "4px"
}));

// eslint-disable-next-line max-lines-per-function,complexity
const NotificationItem = (
  {
    notification: {
      description,
      lastUpdate,
      createdBy,
      status,
      draft,
      type = "Deployment"
    },
    setItemToDelete
  }
) => {
  const isFirstDraft = useAppStore(isFirstDraftSelector);
  const isDraftOwner = useAppStore(isDraftOwnerSelector);

  const {
    palette: { primary, info, success, error }
  } = useTheme();

  const getChipContent = (status) => {
    switch (status) {
    case NOTIFICATIONS_STATES.IN_PROGRESS:
      return {
        chipColor: primary.main,
        label: status,
        tooltipTitle: NOTIFICATIONS_STATE_TEXTS[type][NOTIFICATIONS_STATES.IN_PROGRESS]
      };
    case NOTIFICATIONS_STATES.PENDING_DEPLOYMENT:
      return {
        chipColor: info.main,
        label: status,
        tooltipTitle: NOTIFICATIONS_STATE_TEXTS[type][NOTIFICATIONS_STATES.PENDING_DEPLOYMENT]
      };
    case NOTIFICATIONS_STATES.LIVE:
      return {
        chipColor: success.main,
        label: status,
        tooltipTitle: NOTIFICATIONS_STATE_TEXTS[type][NOTIFICATIONS_STATES.LIVE]
      };
    case NOTIFICATIONS_STATES.COMPLETED:
      return {
        chipColor: success.main,
        label: status,
        tooltipTitle: NOTIFICATIONS_STATE_TEXTS[type][NOTIFICATIONS_STATES.COMPLETED]
      };
    case NOTIFICATIONS_STATES.IN_PROGRESS_FAILED:
      return {
        chipColor: error.main,
        label: FAILED_REQUEST_TEXT,
        tooltipTitle: NOTIFICATIONS_STATE_TEXTS[type][NOTIFICATIONS_STATES.IN_PROGRESS_FAILED]
      };
    case NOTIFICATIONS_STATES.PENDING_DEPLOYMENT_FAILED:
      return {
        chipColor: error.main,
        label: FAILED_REQUEST_TEXT,
        tooltipTitle: NOTIFICATIONS_STATE_TEXTS[type][NOTIFICATIONS_STATES.PENDING_DEPLOYMENT_FAILED]
      };
    default:
      return {};
    }
  };

  const getChip = () => {
    const { chipColor, tooltipTitle, label } = getChipContent(status);
    return <StyledTooltip title={<Typography variant="tooltip">{tooltipTitle}</Typography>} placement="right-end" arrow>
      <StyledChip
        label={label}
        backgroundColor={chipColor}
        variant="outlined"
        size="small"
      />
    </StyledTooltip>;
  };

  const getBackgroundColor = (status) => {
    switch (status) {
    case NOTIFICATIONS_STATES.LIVE:
    case NOTIFICATIONS_STATES.COMPLETED:
      return "#E8FAF4";
    case NOTIFICATIONS_STATES.IN_PROGRESS_FAILED:
      return "#F0C5CF4D";
    case NOTIFICATIONS_STATES.PENDING_DEPLOYMENT_FAILED:
      return "#F0C5CF4D";
    default:
      return "";
    }
  };

  const canDiscardChange = isDraftOwner && (status === NOTIFICATIONS_STATES.IN_PROGRESS &&
    (
      (type === NOTIFICATION_TYPES.DEPLOYMENT && !isFirstDraft) ||
      (type === NOTIFICATION_TYPES.BUILD)
    ));

  return Object.values(NOTIFICATIONS_STATES).includes(status)
    ? (
      <StyledBox backgroundColor={getBackgroundColor(status)}>
        <Stack>
          <Typography variant="subtitle2" noWrap>
            {description}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {dayjs(lastUpdate || "").fromNow() + " by " + createdBy}
          </Typography>
          <Stack mt={1} direction="row" alignItems="center" justifyContent="space-between">
            {getChip()}
            {canDiscardChange && (
              <Grid item>
                <StyledButton
                  onClick={() => {
                    if (type === NOTIFICATION_TYPES.DEPLOYMENT) {
                      setItemToDelete(draft, "draft");
                      notifyNotificationsMenu("deleteDraft");
                    } else {
                      setItemToDelete({ type });
                      notifyNotificationsMenu("discardBuild");
                    }
                  }}
                >
                  Cancel
                </StyledButton>
              </Grid>
            )}
          </Stack>
        </Stack>
      </StyledBox>
    )
    : (
      <></>
    );
};

export default NotificationItem;
