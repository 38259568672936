import React from "react";

const RxIcon = () => (<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7802_10120)">
      <circle cx="24" cy="24" r="23.5" fill="#F3F3FF" stroke="#DEDFFF"/>
      <path d="M20 15.6C20 15.0399 20 14.7599 20.109 14.546C20.2049 14.3578 20.3578 14.2049 20.546 14.109C20.7599 14 21.0399 14 21.6 14H26.4C26.9601 14 27.2401 14 27.454 14.109C27.6422 14.2049 27.7951 14.3578 27.891 14.546C28 14.7599 28 15.0399 28 15.6V16.4C28 16.9601 28 17.2401 27.891 17.454C27.7951 17.6422 27.6422 17.7951 27.454 17.891C27.2401 18 26.9601 18 26.4 18H21.6C21.0399 18 20.7599 18 20.546 17.891C20.3578 17.7951 20.2049 17.6422 20.109 17.454C20 17.2401 20 16.9601 20 16.4V15.6Z" fill="#B3B7FF"/>
      <path d="M28 16C28.93 16 29.395 16 29.7765 16.1022C30.8117 16.3796 31.6204 17.1883 31.8978 18.2235C32 18.605 32 19.07 32 20V29.2C32 30.8802 32 31.7202 31.673 32.362C31.3854 32.9265 30.9265 33.3854 30.362 33.673C29.7202 34 28.8802 34 27.2 34H20.8C19.1198 34 18.2798 34 17.638 33.673C17.0735 33.3854 16.6146 32.9265 16.327 32.362C16 31.7202 16 30.8802 16 29.2V20C16 19.07 16 18.605 16.1022 18.2235C16.3796 17.1883 17.1883 16.3796 18.2235 16.1022C18.605 16 19.07 16 20 16M21.6 18H26.4C26.9601 18 27.2401 18 27.454 17.891C27.6422 17.7951 27.7951 17.6422 27.891 17.454C28 17.2401 28 16.9601 28 16.4V15.6C28 15.0399 28 14.7599 27.891 14.546C27.7951 14.3578 27.6422 14.2049 27.454 14.109C27.2401 14 26.9601 14 26.4 14H21.6C21.0399 14 20.7599 14 20.546 14.109C20.3578 14.2049 20.2049 14.3578 20.109 14.546C20 14.7599 20 15.0399 20 15.6V16.4C20 16.9601 20 17.2401 20.109 17.454C20.2049 17.6422 20.3578 17.7951 20.546 17.891C20.7599 18 21.0399 18 21.6 18Z" stroke="#4548C4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24 29V23M21 26H27" stroke="#EF4068" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_7802_10120">
        <rect width="48" height="48" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default RxIcon;
