export const FETCH_QUERY_KEYS = {
  DASHBOARD_SETTINGS: "dashboardSettings",
  COLLECTIONS: "collections",
  COLLECTION: "collection",
  COLLECTION_REFS: "collectionRefs",
  ASSISTANT: "assistant",
  ACCOUNTS: "accounts",
  EXTERNAL_PREVIEW: "externalPreview",
  EXTERNAL_PREVIEW_LINK: "externalPreviewLink"
};
