import { Tooltip } from "@mui/material";
import React from "react";
import { isUpdatingCollectionSelector } from "src/stores/selectors/collectionsSelectors";
import { BUILD_STATUSES } from "src/utils";

import { StyledButton, StyledIconButton } from "./HeaderStyles";
import PreviewIcon from "../../icons/PreviewIcon";
import useAppStore from "../../stores/appStore";
import {
  assistantIdSelector,
  isAssistantPreviewableSelector,
  systemModeSelector
} from "../../stores/selectors/appSelectors";
import {
  branchDeployerBuildFailedSelector,
  buildStatusPollingInProgressSelector
} from "../../stores/selectors/previewSelectors";
import { draftSelector } from "../../stores/selectors/tripletsSelectors";
import { SYSTEM_MODES } from "../../stores/slices/createAppSlice";

// eslint-disable-next-line complexity
const WebSnippet = ({ isButton, isActive, onClick }) => {
  const selectedAssistantId = useAppStore(assistantIdSelector);
  const assistantsToDisablePreview
    = import.meta.env.VITE_ASSISTANTS_TO_DISABLE_CHAT_PREVIEW?.split(",");
  const buildDraftResponse = useAppStore(state => state.buildDraftResponse);
  const branchDeployerBuildFailed = useAppStore(branchDeployerBuildFailedSelector);
  const buildStatusPollingInProgress = useAppStore(buildStatusPollingInProgressSelector);
  const isAssistantPreviewable = useAppStore(isAssistantPreviewableSelector);
  const systemMode = useAppStore(systemModeSelector);
  const draft = useAppStore(draftSelector);
  const isUpdatingCollection = useAppStore(isUpdatingCollectionSelector);

  const isDisabled = (draft && systemMode === SYSTEM_MODES.EDIT && buildDraftResponse?.status !== BUILD_STATUSES.LIVE)
    || (!draft && systemMode === SYSTEM_MODES.EDIT)
    || buildStatusPollingInProgress
    || branchDeployerBuildFailed
    || !isAssistantPreviewable
    || (assistantsToDisablePreview && assistantsToDisablePreview.includes(selectedAssistantId))
    || isUpdatingCollection;

  return isButton ? <StyledButton
    disabled={isDisabled}
    onClick={onClick}
    variant="outlined"
    startIcon={<PreviewIcon disabled={isDisabled} size={20} />}>
    Preview
  </StyledButton> : <Tooltip title={"Chat preview"}>
    <span>
      <StyledIconButton
        variant="text"
        disabled={isDisabled}
        isActive={isActive}
        onClick={onClick}
      >
        <PreviewIcon disabled={isDisabled} />
      </StyledIconButton>
    </span>
  </Tooltip>;
};

export default WebSnippet;
