import "./styles/App.css";
import "./fonts/fonts.css";
import { FronteggProvider } from "@frontegg/react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { useLocation } from "react-router-dom";

import { ClientSocketProvider } from "./contexts/ClientSocketContext";
import { DateTimeProvider } from "./contexts/DateTimeContext";
import initDatadog from "./datadog";
import InitializePermissions from "./InitializePermissions";
import ErrorBoundary from "./layout/ErrorBoundries";
import HeaderSkeleton from "./layout/Header/HeaderSkeleton";
import useAppStore from "./stores/appStore";
import { showHeaderSkeletonSelector } from "./stores/selectors/appSelectors";
import { ENVIRONMENTS, FRONTEGG_DASHBOARD_BASE_URL, FRONTEGG_DASHBOARD_CLIENT_ID, EXTERNAL_PREVIEW } from "./utils";
import theme from "./theme";

if (import.meta.env.VITE_ENV === ENVIRONMENTS.PRODUCTION) {
  initDatadog();
}

const queryClient = new QueryClient({});

const App = () => {
  const location = useLocation();
  const showHeaderSkeleton = useAppStore(showHeaderSkeletonSelector);
  const isHeaderSkeletonVisible = showHeaderSkeleton && !location.pathname.includes(EXTERNAL_PREVIEW);
  const contextOptions = {
    baseUrl: FRONTEGG_DASHBOARD_BASE_URL,
    clientId: FRONTEGG_DASHBOARD_CLIENT_ID
  };

  const authOptions = {
    keepSessionAlive: true
  };

  return (
    <FronteggProvider
      contextOptions={contextOptions}
      hostedLoginBox
      authOptions={authOptions}
    >
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            {isHeaderSkeletonVisible ? <HeaderSkeleton /> : null}
            <ClientSocketProvider>
              <DateTimeProvider>
                <ErrorBoundary key={location.pathname}>
                  <InitializePermissions />
                </ErrorBoundary>
              </DateTimeProvider>
            </ClientSocketProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </FronteggProvider>
  );
};

export default App;
