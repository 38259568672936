import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { USER_PERMISSIONS } from "@hyro/dashboard-commons";
import { notifyToggleWidget } from "../../../analytics/notifyCustomActions";
import useAppStore from "../../../stores/appStore";
import {
  openWidgetInitiallySelector,
  previousSystemModeSelector, setHeaderSkeletonSelector,
  setIsWebSnippetOpenSelector,
  setSystemModeSelector,
  hasPermissionSelector
} from "../../../stores/selectors/appSelectors";
import { StyledButton } from "../HeaderStyles";
import ShareIcon from "src/icons/ShareIcon";
import SharePreviewDialog from "../SharePreviewDialog";

const PreviewModeHeader = () => {
  const setIsWebSnippetOpen = useAppStore(setIsWebSnippetOpenSelector);
  const setSystemMode = useAppStore(setSystemModeSelector);
  const previousSystemMode = useAppStore(previousSystemModeSelector);
  const setShowHeaderSkeleton = useAppStore(setHeaderSkeletonSelector);
  const openWidgetInitially = useAppStore(openWidgetInitiallySelector);
  const hasPermission = useAppStore(hasPermissionSelector);
  const [isSharePreviewDialogOpen, setIsSharePreviewDialogOpen] = useState(false);

  useEffect(() => {
    setShowHeaderSkeleton(false);

    if (openWidgetInitially) {
      notifyToggleWidget("Opened");
      setIsWebSnippetOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExitPreviewMode = () => {
    notifyToggleWidget("Closed");
    setSystemMode(previousSystemMode);
    setIsWebSnippetOpen(false);
  };

  return <Stack direction='row'>
    <Stack direction='row' sx={{ ml: "auto" }} alignItems={"center"} justifyContent='center' gap={1}>
      {hasPermission(USER_PERMISSIONS.EXTERNAL_PREVIEW.READ) && <StyledButton
        onClick={() => setIsSharePreviewDialogOpen(true)}
        sx={{ gap: "8px" }}
        variant="outlined">
        <ShareIcon /> Share
      </StyledButton>}

      <StyledButton
        onClick={handleExitPreviewMode}
        variant="outlined">
          Exit preview
      </StyledButton>
    </Stack>

    <SharePreviewDialog isSharePreviewDialogOpen={isSharePreviewDialogOpen} setIsSharePreviewDialogOpen={setIsSharePreviewDialogOpen} />
  </Stack>;
};

export default PreviewModeHeader;
