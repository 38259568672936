import { conversationService } from "../../services";
import { get as apiGet } from "../../services/api";

const createConversationsSlice = (set, get) => ({
  conversationsList: [],
  conversationPage: 0,
  selectedConversation: null,
  isLoadingConversation: false,
  hasMoreConversations: true,
  totalConversations: 0,
  filterParameters: {},
  filterOptions: null,

  getSingleConversation: async (conversationId) => {
    set({ selectedConversation: null, isLoadingConversation: true });

    const { selectedAssistant } = get();
    if (!selectedAssistant) return;

    try {
      const conversation = await conversationService.get({
        roomId: `${selectedAssistant.assistantId}`,
        conversationId
      });
      set({ selectedConversation: conversation, isLoadingConversation: false });
    } catch (error) {
      console.error(error);
      set({ isLoadingConversation: false });
    }
  },

  resetSelectedConversation: () => {
    set({ selectedConversation: null });
  },

  getSelectOptions: async (dates) => {
    try {
      set({ filterOptions: null });
      const { selectedAssistant } = get();
      const skillsOptionsPromise = apiGet(
        `conversations/assistants/${selectedAssistant.assistantId}/skills`,
        { from: dates[0].format(), to: dates[1].format() }
      );
      const sitesOptionsPromise = apiGet(
        `conversations/assistants/${selectedAssistant.assistantId}/sites`,
        { from: dates[0].format(), to: dates[1].format() }
      );
      const eventOptionsPromise = apiGet(
        `/conversations/assistants/${selectedAssistant.assistantId}/event-types`,
        { from: dates[0].format(), to: dates[1].format() }
      );

      const [skillsOptions, sitesOptions, eventOptions] =
        await Promise.allSettled([skillsOptionsPromise, sitesOptionsPromise, eventOptionsPromise]);
      set({
        filterOptions: {
          skillsOptions: skillsOptions?.value,
          sitesOptions: sitesOptions?.value,
          eventOptions: eventOptions?.value?.filter((event) => event !== "USER_UTTERANCE" && event !== "BOT_UTTERANCE")
        }
      });
    } catch (e) {
      console.error(e);
      set({
        filterOptions: {
          skillsOptions: [],
          sitesOptions: [],
          eventOptions: []
        }
      });
    }
  },

  resetConversationsState: () => {
    set({
      conversationsList: [],
      conversationPage: 0,
      selectedConversation: null,
      hasMoreConversations: true
    });
  }
});

export default createConversationsSlice;
