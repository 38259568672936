import React from "react";

const PatientRegistrationIcon = () => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7807_9760)">
      <circle cx="24" cy="24" r="23.5" fill="#F3F3FF" stroke="#DEDFFF"/>
      <path d="M22 24C24.4853 24 26.5 21.9853 26.5 19.5C26.5 17.0147 24.4853 15 22 15C19.5147 15 17.5 17.0147 17.5 19.5C17.5 21.9853 19.5147 24 22 24Z" fill="#B3B7FF"/>
      <path d="M21 27.5H19.5C18.1044 27.5 17.4067 27.5 16.8389 27.6722C15.5605 28.06 14.56 29.0605 14.1722 30.3389C14 30.9067 14 31.6044 14 33M26.5 19.5C26.5 21.9853 24.4853 24 22 24C19.5147 24 17.5 21.9853 17.5 19.5C17.5 17.0147 19.5147 15 22 15C24.4853 15 26.5 17.0147 26.5 19.5Z" stroke="#4548C4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23.8861 29.8666C23.9285 29.7181 23.9497 29.6439 23.9816 29.5746C24.0098 29.5131 24.0442 29.4546 24.0842 29.4001C24.1293 29.3386 24.1839 29.284 24.2932 29.1748L30.75 22.718C31.4404 22.0276 32.5597 22.0276 33.25 22.7179C33.9404 23.4083 33.9404 24.5276 33.25 25.218L26.7932 31.6748C26.6839 31.784 26.6293 31.8386 26.5679 31.8837C26.5134 31.9238 26.4549 31.9582 26.3934 31.9864C26.3241 32.0182 26.2499 32.0394 26.1014 32.0819L23 32.968L23.8861 29.8666Z" fill="#FFCFDA" stroke="#EF4068" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_7807_9760">
        <rect width="48" height="48" fill="white"/>
      </clipPath>
    </defs>
  </svg>
;

export default PatientRegistrationIcon;
