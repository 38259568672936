import { Typography, Stack } from "@mui/material";
import React from "react";

const HyroTableTitle = ({ children, title, rowsLength, testId, titleSize = "h6" }) => (
  <Stack sx={{ justifyContent: "flex-start", alignItems: "center", flexDirection: "row" }} data-testid={testId}>
    {title ? <Typography variant={titleSize}>{title}</Typography> : null}
    <Typography variant="body2" sx={{ ml: 0.5, translate: "0 1px" }} data-testid={`${testId}-rows-length`}
    >{`(${rowsLength || 0})`}</Typography>

    {children}
  </Stack>
);

export default HyroTableTitle;
