import { datadogRum } from "@datadog/browser-rum";
import { pipe, prop } from "ramda";

import useAppStore from "../stores/appStore";
import { objectByAssistantId } from "../utils";

const HOME_INSIGHTS_ACTIONS = {
  NAVIGATION: "homeInsights:paginationNavigation",
  VIEW_CONVERSATIONS: "homeInsights:viewConversations",
  VIEW_INSIGHTS: "homeInsights:viewInsights",
  SEND_EMAIL: "homeInsights:sendEmail"
};
const TEST_CONNECTION = {
  CLICKED: "testConnection:clicked",
  SUCCEEDED: "testConnection:succeeded",
  FAILED: "testConnection:failed"
};

export const CUSTOM_ACTIONS = {
  CREATE_ASSISTANT: "createAssistant",
  PREVIEW_USAGE: "previewUsage",
  PUBLISH_CHANGES: "publishChanges",
  NOTIFICATION_MENU: "notificationMenu",
  KNOWLEDGE_EXPLORER_USAGE: "knowledgeExplorerUsage",
  ADD_RESPONSE: "addResponse",
  SAVE_ADD_RESPONSE: "saveAddResponse",
  PAGE_NAV: "pageNav",
  TOGGLE_WIDGET: "ToggleWidget",
  OPEN_MENU: "openMenu",
  ROW_SUBMITTED: "rowSubmitted",
  PREVIEW_SHARED: "previewShared",
  CALL_ME_CLICKED: "callMeClicked",
  TEST_CONNECTION,
  ...HOME_INSIGHTS_ACTIONS
};

const sendNotification = (action, context) =>
  datadogRum.addAction(action, context);

const getSelectedAssistantType = pipe(objectByAssistantId, prop("type"));

const notifyRowSubmitted = (key, displayKey) => {
  const {
    selectedAssistant,
    userPermissions: { userId }
  } = useAppStore.getState();
  sendNotification(CUSTOM_ACTIONS.ROW_SUBMITTED, {
    assistantId: selectedAssistant?.assistantId,
    userId,
    key,
    displayKey
  });
};

const notifyCreateAssistant = (newAssistantId, assistantType) => {
  const {
    userPermissions: { userId }
  } = useAppStore.getState();
  sendNotification(CUSTOM_ACTIONS.CREATE_ASSISTANT, {
    assistantType,
    isNewAssistant: true,
    assistantId: newAssistantId,
    userId
  });
};

const notifyPreviewUsage = (previewUsageType) => {
  const {
    selectedAssistant,
    userPermissions: { userId },
    originalTriplets,
    assistants
  } = useAppStore.getState();
  sendNotification(CUSTOM_ACTIONS.PREVIEW_USAGE, {
    previewUsageType,
    assistantType: getSelectedAssistantType(assistants, selectedAssistant?.assistantId),
    isNewAssistant: originalTriplets.length === 0,
    assistantId: selectedAssistant?.assistantId,
    userId
  });
};

const notifyKnowledgeExplorerUsage = (
  searchValues,
  useExactMatch,
  maxDistanceFromRoot,
  maxNodes
) => {
  const {
    selectedAssistant,
    userPermissions: { userId },
    selectedAccountId
  } = useAppStore.getState();
  sendNotification(CUSTOM_ACTIONS.KNOWLEDGE_EXPLORER_USAGE, {
    searchValues,
    useExactMatch,
    maxDistanceFromRoot,
    maxNodes,
    assistantId: selectedAssistant?.assistantId,
    userId,
    accountId: selectedAccountId
  });
};

const notifyPageNav = (destination, trigger) => {
  const {
    selectedAssistant,
    userPermissions: { userId },
    selectedAccountId
  } = useAppStore.getState();
  sendNotification(CUSTOM_ACTIONS.PAGE_NAV, {
    destination,
    trigger,
    assistantId: selectedAssistant?.assistantId,
    userId,
    accountId: selectedAccountId
  });
};

const openMenu = (destination) => {
  const {
    selectedAssistant,
    userPermissions: { userId },
    selectedAccountId
  } = useAppStore.getState();
  sendNotification(CUSTOM_ACTIONS.OPEN_MENU, {
    destination,
    assistantId: selectedAssistant?.assistantId,
    userId,
    accountId: selectedAccountId
  });
};

const notifyNotificationsMenu = (action) => {
  const {
    selectedAssistant,
    userPermissions: { userId },
    selectedAccountId
  } = useAppStore.getState();
  sendNotification(CUSTOM_ACTIONS.NOTIFICATION_MENU, {
    action,
    assistantId: selectedAssistant?.assistantId,
    userId,
    accountId: selectedAccountId
  });
};

const notifyToggleWidget = (toggleState) => {
  const {
    selectedAssistant,
    userPermissions: { userId },
    selectedAccountId
  } = useAppStore.getState();
  sendNotification(CUSTOM_ACTIONS.TOGGLE_WIDGET, {
    toggleState,
    assistantId: selectedAssistant?.assistantId,
    userId,
    accountId: selectedAccountId
  });
};

const notifyPublishChanges = (changeType) => {
  const {
    selectedAssistant,
    userPermissions: { userId },
    originalTriplets,
    assistants,
    selectedAccountId
  } = useAppStore.getState();
  sendNotification(CUSTOM_ACTIONS.PUBLISH_CHANGES, {
    assistantType: getSelectedAssistantType(assistants, selectedAssistant?.assistantId),
    isNewAssistant: originalTriplets.length === 0,
    assistantId: selectedAssistant?.assistantId,
    userId,
    accountId: selectedAccountId,
    changeType
  });
};

export {
  notifyCreateAssistant,
  notifyPreviewUsage,
  notifyPublishChanges,
  notifyKnowledgeExplorerUsage,
  notifyPageNav,
  notifyToggleWidget,
  notifyNotificationsMenu,
  notifyRowSubmitted,
  openMenu
};
