/* eslint-disable consistent-return */
import { concat, evolve, filter, isEmpty, keys, mergeRight, not, pipe, prop, propEq, reject, sortBy } from "ramda";
import { get as apiGet } from "src/services/api";

import { SYSTEM_MODES } from "./createAppSlice";
import {
  filterByRelation,
  getMainKgHashFromVersionFile,
  makeGraphTriplets
} from "../../pages/KnowledgeExplorer/knowledgeUtils";
import { botPortalService, kgviewerService, storageService } from "../../services";
import * as api from "../../services/api";
import { DRAFT_STATUS, objectToTriplets, PROVIDER, relationIn, Relations } from "../../triplets";
import { CONFIGURATIONS_DIRECTORY_NAME, SYNONYM } from "../../utils";

// eslint-disable-next-line max-lines-per-function
const createTripletsSlice = (set, get) => ({
  // original triplets = most up to date master triplets: used for comparison while you are editing a draft
  originalTriplets: [],

  currentTriplets: [],
  displayedTriplets: [],
  knowledgeGraphAsTriplets: [],
  tripletsStatus: DRAFT_STATUS.IDLE,
  failedToCreateDraft: false,
  failedToPublishDraft: false,
  failedToUpdateDraft: false,
  infoPopovers: {
    draftExpiration: false,
    buildDraft: false,
    setLive: false
  },
  couldNotLoadTriplets: false,

  clearTriplets: () => {
    set({
      originalTriplets: [],
      currentTriplets: [],
      displayedTriplets: [],
      draft: null,
      tripletsStatus: DRAFT_STATUS.LOADING
    });
  },

  initTriplets: async () => {
    const { selectedAccountId, selectedAssistant } = get();
    set({ tripletsStatus: DRAFT_STATUS.LOADING });

    try {
      const originalTriplets = await botPortalService.originalTriplets();
      set({ originalTriplets });
    } catch (err) {
      set({ couldNotLoadTriplets: true });
    }

    const draft = await api.get(
      `/botPortal/account/${selectedAccountId}/assistant/${selectedAssistant.assistantId}/draft`
    );
    const { originalTriplets, systemMode, getValidationResults } = get();
    let draftTriplets = [];

    if (draft) {
      set({ draft });

      if (systemMode === SYSTEM_MODES.EDIT) {
        getValidationResults();
      }
      draftTriplets = (await botPortalService.tripletsFromDraft({ draft })) || [];
    }
    const currentTriplets = draftTriplets;

    set({
      currentTriplets,
      displayedTriplets: systemMode === SYSTEM_MODES.EDIT && draft ? draftTriplets : originalTriplets,
      draft,
      tripletsStatus: DRAFT_STATUS.IDLE
    });
  },

  getKnowledgeGraph: async () => {
    const { draft } = get();
    if (draft?.isFirstDraft) return;
    const versionsFileObj = await kgviewerService.getVersionsFile();
    // non deployed assistants do not have official versions yet
    if (Object.keys(versionsFileObj).length === 0) return;

    const graphHash = getMainKgHashFromVersionFile(versionsFileObj);
    const mainKnowledgeGraph = await kgviewerService.getGraph({
      graphHash
    });
    const knowledgeGraphAsTriplets = makeGraphTriplets(mainKnowledgeGraph);
    set({ knowledgeGraphAsTriplets });
  },

  updateCurrentTriplets: async (updateFn, type) => {
    const {
      userData,
      draft,
      displayedTriplets,
      discardDraftIfNoChanges,
      pageName,
      infoPopovers,
      selectedAssistant
    } = get();
    // we need to sort current triplets so we can check differentiation between current and original triplets
    const updatedTriplets = sortBy(prop(0))(updateFn(displayedTriplets));
    set({ displayedTriplets: updatedTriplets, currentTriplets: updatedTriplets });

    const tripletsDir = `${CONFIGURATIONS_DIRECTORY_NAME}/${selectedAssistant?.assistantFolder}`;
    const commitMessage = `Edit ${tripletsDir}/triplets.csv`;

    set({ tripletsStatus: DRAFT_STATUS.SAVING });
    const actionName = type ? `${type} triplets` : "";
    const noChanges = await discardDraftIfNoChanges();

    if (!noChanges) {
      if (!draft) {
        try {
          const newDraft = await botPortalService.createDraftAndUpdateTriplets({
            fileContent: updatedTriplets,
            commitMessage,
            userData
          }, { pageName, actionName });
          set({ infoPopovers: { ...infoPopovers, draftExpiration: true }, draft: newDraft });
        } catch (error) {
          console.error("Error creating a new draft: ", error);

          if (error.response.status === 409) {
            set({ failedToCreateDraft: true, draft: null });
          }
        }
      } else {
        try {
          await botPortalService.editTriplets({
            draft,
            fileContent: updatedTriplets,
            commitMessage,
            userData
          }, { pageName, actionName });
          const [branchDeployerResponse, notifications] = await Promise.all([
            apiGet(`/assistants/${selectedAssistant.assistantId}/build/status?branchName=${draft?.branchName}`),
            apiGet(`/assistants/${selectedAssistant.assistantId}/notifications`)
          ]);
          set({ buildDraftResponse: branchDeployerResponse, notifications });
        } catch (error) {
          console.error("Error editing an existing draft: ", error);
          set({ failedToUpdateDraft: true });
        }
      }
    }
    set({
      tripletsStatus: DRAFT_STATUS.IDLE
    });
  },

  publishCurrentTripletsToDraft: async (changeDescription) => {
    const { draft, selectedAssistant, pageName } = get();
    set({ tripletsStatus: DRAFT_STATUS.PUBLISHING });

    try {
      const updatedDraft = await botPortalService.createMergeRequest({
        description: changeDescription,
        draft
      }, { pageName });

      const notifications = await api.get(`/assistants/${selectedAssistant.assistantId}/notifications`);

      set({
        notifications,
        draft: updatedDraft
      });
    } catch (error) {
      set({ failedToPublishDraft: true });
    }

    set({
      tripletsStatus: DRAFT_STATUS.IDLE
    });
  },

  deletePublishedDraft: async (draftToDelete) => {
    const {
      notifications,
      userData,
      originalTriplets,
      collectionOriginalAssistantConfig,
      collections,
      originalCollectionConfig,
      collectionSpec,
      pageName
    } = get();
    const updatedNotifications = reject(propEq("draft", draftToDelete))(
      notifications
    );

    try {
      await botPortalService.deleteDraft({
        draft: draftToDelete,
        userData
      }, { actionName: "Delete published draft", pageName });
    } catch (error) {
      console.error("Error deleting draft: ", error);
      return { error: true };
    }

    set({
      draft: null,
      notifications: updatedNotifications,
      currentTriplets: [],
      displayedTriplets: originalTriplets,
      collectionAssistantConfig: collectionOriginalAssistantConfig,
      currentCollectionAssistantConfig: collectionOriginalAssistantConfig,
      selectedCollection: { ...originalCollectionConfig, spec: collectionSpec },
      collections: { ...collections, configRef: collections?.originalConfig, currentConfig: collections?.originalConfig },
      validationResults: { live: {}, draft: {}, current: {} },
      shouldRecreatePreview: false
    });
  },

  deleteCurrentDraft: async () => {
    const {
      draft,
      originalTriplets,
      userData,
      originalAssistantConfig,
      collectionOriginalAssistantConfig,
      collections,
      originalCollectionConfig,
      collectionSpec,
      pageName,
      selectedAssistant
    } = get();

    if (!draft?.isFirstDraft) {
      set({ isDiscardingChanges: true });

      try {
        await botPortalService.deleteDraft({
          draft,
          userData
        }, { actionName: "Delete current draft", pageName });
      } catch (error) {
        console.error("Error deleting draft: ", error);
        set({ isDiscardingChanges: false });
        return { error: true };
      }

      const notifications = await apiGet(`/assistants/${selectedAssistant.assistantId}/notifications`);

      set({
        notifications,
        selectedCollection: { ...originalCollectionConfig, spec: collectionSpec },
        currentTriplets: [],
        displayedTriplets: originalTriplets,
        assistantConfig: originalAssistantConfig,
        collectionAssistantConfig: collectionOriginalAssistantConfig,
        currentCollectionAssistantConfig: collectionOriginalAssistantConfig,
        collections: { ...collections, configRef: collections?.originalConfig, currentConfig: collections?.originalConfig },
        draft: null,
        validationResults: { live: {}, draft: {}, current: {} },
        isDiscardingChanges: false,
        shouldRecreatePreview: false,
        buildDraftResponse: { status: "", url: "" }
      });
    }
  },

  updateResultCardConfig: async (modifiedConfig) => {
    const result_card_triplet_format_fns = {
      [Relations.RENDER_TITLE]: subtitle =>
        subtitle ? `name,${subtitle}` : "name"
    };
    const relationsToReject = keys(modifiedConfig);
    const tripletsToConcat = pipe(
      evolve(result_card_triplet_format_fns),
      filter(pipe(isEmpty, not)),
      mergeRight({ id: PROVIDER }),
      objectToTriplets
    )(modifiedConfig);

    const { updateCurrentTriplets } = get();
    const tripletsUpdateFn = pipe(
      reject(relationIn(relationsToReject)),
      concat(tripletsToConcat)
    );
    await updateCurrentTriplets(tripletsUpdateFn);
  },

  updateSynonyms: async (synonyms, type) => {
    const { updateCurrentTriplets } = get();
    const synonymTriplets = synonyms.reduce(
      (acc, synonym) => [...acc, [synonym.trigger, SYNONYM, synonym.synonym]],
      []
    );

    const tripletsUpdateFn = pipe(
      reject(relationIn(SYNONYM)),
      concat(synonymTriplets)
    );

    await updateCurrentTriplets(tripletsUpdateFn, type);
  },

  isTriggerInGraph: (node) => {
    const { knowledgeGraphAsTriplets, originalTriplets } = get();
    const isInGraph = filterByRelation(Relations.CONCEPT_TRIGGER)(
      knowledgeGraphAsTriplets
    ).some(trigger => trigger[2] === node);
    const isInTripletsTriggers = filterByRelation(Relations.CONCEPT_TRIGGER)(
      originalTriplets
    ).some(trigger => trigger[2] === node);

    return isInGraph && !isInTripletsTriggers
      ? ["This trigger already exists in the knowledge graph"]
      : [];
  },

  discardDraftIfNoChanges: async () => {
    const {
      originalTriplets,
      displayedTriplets,
      deleteCurrentDraft,
      collectionOriginalAssistantConfig,
      currentCollectionAssistantConfig,
      draft
    } = get();

    if (
      JSON.stringify(displayedTriplets) === JSON.stringify(originalTriplets)
      && JSON.stringify(currentCollectionAssistantConfig) === JSON.stringify(collectionOriginalAssistantConfig)
    ) {
      if (draft) {
        const { error } = await deleteCurrentDraft();
        if (error) return false;
      }
      return true;
    }
    return false;
  },

  createNewDataSource: async (fileForm) => {
    const { pageName, infoPopovers } = get();
    const res = await storageService.createDataSource(fileForm, { pageName });

    set({
      draft: res.draft,
      currentTriplets: res.updatedTriplets,
      displayedTriplets: res.updatedTriplets,
      tripletsStatus: DRAFT_STATUS.IDLE,
      infoPopovers: { ...infoPopovers, draftExpiration: true }
    });
  },

  updateExistingDataSource: async (fileForm) => {
    const { pageName } = get();
    const res = await storageService.updateDataSource(fileForm, { pageName });

    set({
      draft: res.draft,
      currentTriplets: res.updatedTriplets,
      displayedTriplets: res.updatedTriplets,
      tripletsStatus: DRAFT_STATUS.IDLE
    });
  },

  deleteDataSourceFile: async (data) => {
    const { discardDraftIfNoChanges, pageName } = get();
    const res = await storageService.deleteDataSource(data, { pageName });
    set({
      currentTriplets: res.updatedTriplets,
      displayedTriplets: res.updatedTriplets
    });
    const noChanges = await discardDraftIfNoChanges();

    if (!noChanges) {
      set({ draft: res.draft });
    }

    set({
      tripletsStatus: DRAFT_STATUS.IDLE
    });
  },

  setFailedToCreateDraftSelector: (failedToCreateDraft) => {
    set({ failedToCreateDraft });
  },

  setFailedToPublishDraftSelector: (failedToPublishDraft) => {
    set({ failedToPublishDraft });
  },

  setInfoPopovers: (type, data) => {
    const { infoPopovers } = get();
    set({ infoPopovers: { ...infoPopovers, [type]: data } });
  },

  setCouldNotLoadTriplets: (couldNotLoadTriplets) => {
    set({ couldNotLoadTriplets });
  }
});

export default createTripletsSlice;
