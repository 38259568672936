import React from "react";

const SiteInformationIcon = () => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7802_10116)">
      <circle cx="24" cy="24" r="23.5" fill="#F3F3FF" stroke="#DEDFFF"/>
      <path d="M17 26.2864C15.1486 27.1031 14 28.2412 14 29.5C14 31.9853 18.4772 34 24 34C29.5228 34 34 31.9853 34 29.5C34 28.2412 32.8514 27.1031 31 26.2864M30 20C30 24.0637 25.5 26 24 29C22.5 26 18 24.0637 18 20C18 16.6863 20.6863 14 24 14C27.3137 14 30 16.6863 30 20Z" stroke="#4548C4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <circle cx="24" cy="20" r="1" fill="#FFCFDA" stroke="#EF4068"/>
    </g>
    <defs>
      <clipPath id="clip0_7802_10116">
        <rect width="48" height="48" fill="white"/>
      </clipPath>
    </defs>
  </svg>
;

export default SiteInformationIcon;
