import React from "react";
import useAppStore from "src/stores/appStore";
import { selectedAssistantSelector } from "src/stores/selectors/appSelectors";
import { ASSISTANT_CHANNELS } from "src/utils";
import { List, ListItem, ListItemButton, ListItemText, Popover, styled } from "@mui/material";

const PreviewDropdown = ({ align = "left", anchorEl, setAnchorEl, setPhoneDialogOpen, setIsSharePreviewDialogOpen, handleOpenWidget }) => {
  const selectedAssistant = useAppStore(selectedAssistantSelector);
  const isPhone = selectedAssistant?.channel === ASSISTANT_CHANNELS.PHONE;

  const handlePreviewClick = () => {
    setAnchorEl(null);

    if (isPhone) setPhoneDialogOpen(true);
    else handleOpenWidget();
  };

  const handleSharePreviewClick = () => {
    setAnchorEl(null);
    setIsSharePreviewDialogOpen(true);
  };

  return (<>
    <Popover
      open={!!anchorEl}
      sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
      anchorEl={anchorEl}
      placement="bottom-end"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: align
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: align
      }}
      PaperProps={{
        sx: {
          marginTop: "8px",
          boxShadow: "0px 4px 8px 0px #0000001F, 0px 2px 2px 2px #00000003"
        }
      }}
      onClose={() => setAnchorEl(null)}>
        <StyledList>
            <StyledListItem>
                <StyledListItemButton onClick={handlePreviewClick}>
                  {isPhone ? "Preview Call" : "Preview Chat"}
                </StyledListItemButton>
            </StyledListItem>
            <StyledListItem>
                <StyledListItemButton data-testid="share-preview-button" onClick={handleSharePreviewClick}>
                  Share
                </StyledListItemButton>
            </StyledListItem>
            <StyledListItem>
                <StyledListItemText
                primary="Preview conversations are marked as tests, without affecting live data."
                primaryTypographyProps={{ fontSize: "11px", lineHeight: "15px" }} />
            </StyledListItem>
        </StyledList>
    </Popover>
  </>);
};

const StyledList = styled(List)({
  width: "220px",
  padding: "8px",
  borderRadius: "8px",
  backgroundColor: "white",
  boxShadow: "0px 4px 8px 0px #0000001F, 0px 2px 2px 2px #00000003"
});

const StyledListItem = styled(ListItem)({
  padding: "0px",
  "&:nth-of-type(2)": {
    marginBottom: "4px"
  }
});

const StyledListItemButton = styled(ListItemButton)({
  padding: "8px 10px",
  color: "black",
  borderRadius: "8px",
  fontSize: "14px"
});

const StyledListItemText = styled(ListItemText)({
  position: "relative",
  padding: "12px 8px 0",
  color: "#2D2C44B2",
  "&::before": {
    content: "''",
    position: "absolute",
    left: "-8px",
    right: "-8px",
    top: "0px",
    height: "1px",
    width: "calc(100% + 16px)",
    backgroundColor: "#2D2C441F"
  }
});

export default PreviewDropdown;
