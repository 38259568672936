import React from "react";

const ApptCancellationIcon = () => (<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7780_33900)">
      <circle cx="24" cy="24" r="23.5" fill="#F3F3FF" stroke="#DEDFFF"/>
      <path d="M15 20.8C15 19.1198 15 18.2798 15.327 17.638C15.6146 17.0735 16.0735 16.6146 16.638 16.327C17.2798 16 18.1198 16 19.8 16H28.2C29.8802 16 30.7202 16 31.362 16.327C31.9265 16.6146 32.3854 17.0735 32.673 17.638C33 18.2798 33 19.1198 33 20.8V22H15V20.8Z" fill="#B3B7FF"/>
      <path d="M33 23.5V20.8C33 19.1198 33 18.2798 32.673 17.638C32.3854 17.0735 31.9265 16.6146 31.362 16.327C30.7202 16 29.8802 16 28.2 16H19.8C18.1198 16 17.2798 16 16.638 16.327C16.0735 16.6146 15.6146 17.0735 15.327 17.638C15 18.2798 15 19.1198 15 20.8V29.2C15 30.8802 15 31.7202 15.327 32.362C15.6146 32.9265 16.0735 33.3854 16.638 33.673C17.2798 34 18.1198 34 19.8 34H24.5M33 22H15M28 14V18M20 14V18" stroke="#4548C4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M27 30H33" stroke="#EF4068" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_7780_33900">
        <rect width="48" height="48" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default ApptCancellationIcon;
