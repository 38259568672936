import styled from "@emotion/styled";
import { Badge, IconButton, Stack, Tooltip, tooltipClasses, Typography, Popper, useTheme } from "@mui/material";
import React, { useRef, useEffect } from "react";
import MultiLanguageDrawer from "src/components/common/fields/MultiLanguageDrawer";
import { FIELD_TYPES } from "src/components/common/fields/utils";
import MultipleOptions from "./fields/MultipleOptions";
import RadioButtonsGroup from "./fields/RadioButtonsGroup";
import Secret from "./fields/Secret";
import SingleOption from "./fields/SingleOption";
import { StyledTextField } from "./styles";
import AddSuggestionIcon from "../../icons/AddSuggestionIcon";
import MultipleValues from "../../pages/KnowledgeEditor/ModifyEntity/MultipleValues";
import { getLanguageIcon } from "../collections/consts";
import StatusChip from "src/components/collections/StatusChip";

// eslint-disable-next-line max-lines-per-function, complexity
const Field = props => {
  const {
    fieldType,
    label,
    field,
    value,
    required,
    onChange,
    onKeyDown,
    onBlur,
    error,
    isReadOnly,
    status,
    showLabel = false,
    onAddButtonClick,
    shouldShowAddButton = false,
    isMultiLine = false,
    maxRows = undefined,
    index = 0,
    options,
    isFieldReadOnlyFromSpec,
    currentSelectedLanguage,
    collectionLanguages,
    shouldShowLanguageFlag = false,
    languageFlagPosition = "end",
    showErrorInPopper = false,
    popperErrorPlacement = "bottom-start",
    isDrawerView = false,
    testId,
    fetchingRefs,
    autoFocus = false,
    InputProps,
    helperText
  } = props;
  const theme = useTheme();
  const LanguageIcon = getLanguageIcon(currentSelectedLanguage);
  const [inputAnchorEl, setInputAnchorEl] = React.useState(null);

  const getHelperText = () => {
    const getOffset = () => {
      if (fieldType === FIELD_TYPES.MULTI_LANGUAGE_TEXT) {
        return [shouldShowLanguageFlag ? -40 : 0, 12];
      }

      if (fieldType === FIELD_TYPES.CHOOSE_ONE) {
        return [0, 24];
      }
      return [0, 12];
    };

    if (error && typeof error !== "object") {
      if (showErrorInPopper) {
        const offset = getOffset();

        return (
          <StyledHelperPopper
            data-testid={`${testId ? `${testId}-` : ""}${fieldType}-${index}-helper`}
            open={!!error && !!inputAnchorEl}
            component={Typography}
            anchorEl={inputAnchorEl}
            variant="caption"
            sx={{ width: inputAnchorEl?.clientWidth }}
            placement={popperErrorPlacement}
            modifiers={[{ name: "offset", options: { offset } }]}>
            {error}
          </StyledHelperPopper>
        );
      }

      return (
        <Typography sx={{ whiteSpace: "pre-line" }} variant="caption">
          {error}
        </Typography>
      );
    }

    if (status) {
      return <StatusChip status={status} />;
    }

    if (helperText) {
      return <Typography variant="caption" color="#757575">{helperText}</Typography>;
    }

    return null;
  };

  const getTextFieldProps = () => ({
    fullWidth: true,
    maxRows,
    multiline: isMultiLine,
    variant: "outlined",
    label: showLabel ? label : "",
    required,
    error: !!error,
    helperText: getHelperText(),
    readOnly: isReadOnly,
    isFieldReadOnlyFromSpec,
    placeholder: label,
    onFocus: (e) => {
      if (showErrorInPopper) {
        setInputAnchorEl(e.currentTarget);
      }
    },
    onBlur: () => {
      onBlur && onBlur();

      if (showErrorInPopper) {
        setInputAnchorEl(null);
      }
    }
  });
  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      const input = inputRef.current;
      input?.focus();
      input?.setSelectionRange(input.value.length, input.value.length); // Move cursor to the end
    }
  }, [autoFocus]);

  const getTestIdObject = () => ({
    FormHelperTextProps:{
      "data-testid": `${testId ? `${testId}-` : ""}${fieldType}-${index}-helper`,
      sx: showErrorInPopper ? { display: "none" } : {}
    },
    inputProps:{ autoComplete: "off", "data-testid": `${testId ? `${testId}-` : ""}${fieldType}-${index}` }
  });

  switch (fieldType) {
  case FIELD_TYPES.BOOL:
    return (
      <RadioButtonsGroup {...props} isBoolean />
    );
  case FIELD_TYPES.CHOOSE_AT_LEAST_ONE:
    return <MultipleOptions
      {...props}
      helperText={getHelperText()}
      testId={`${testId ? `${testId}-` : ""}${fieldType}-${index}`}
      onFocus={(target) => {
        if (showErrorInPopper) {
          setInputAnchorEl(target);
        }
      }}
      onBlur={() => {
        if (showErrorInPopper) {
          setInputAnchorEl(null);
        }
      }}
    />;
  case FIELD_TYPES.CHOOSE_ONE:
    return <SingleOption {...props}
      inputRef={inputRef}
      helperText={getHelperText()}
      testId={`${testId ? `${testId}-` : ""}${fieldType}-${index}`}
      options={options?.length ? options : []}
      onAddNewCollection={null}
      onFocus={(target) => {
        if (showErrorInPopper) {
          setInputAnchorEl(target);
        }
      }}
      onBlur={() => {
        if (showErrorInPopper) {
          setInputAnchorEl(null);
        }
      }}
    />;
  case FIELD_TYPES.COLLECTION_REF:
    return <SingleOption {...props}
      helperText={getHelperText()}
      loading={fetchingRefs}
      testId={`${testId ? `${testId}-` : ""}${fieldType}-${index}`}
      options={options?.length ? options : []}
    />;
  case FIELD_TYPES.SECRET:
    return <Secret {...props} />;
  case FIELD_TYPES.LIST:
    return (
      <MultipleValues
        {...props}
        index={index}
        inputRef={inputRef}
        helperText={getHelperText()}
        values={value || []}
        onChange={(e, value) => onChange(value, e)}
        onDelete={valueToDelete => () => {
          const newValue = value.filter(
            value => value !== valueToDelete
          );
          onChange(newValue);
        }}
        onFocus={(e) => {
          if (showErrorInPopper) {
            setInputAnchorEl(e.target);
          }
        }}
        onBlur={() => {
          if (showErrorInPopper) {
            setInputAnchorEl(null);
          }
        }}
      />
    );
  case FIELD_TYPES.ACTION:
    return (
      <Stack flexDirection={"row"} gap={"16px"}>
        <StyledTextField
          {...getTextFieldProps()}
          {...getTestIdObject()}
          InputProps={{
            sx: { borderRadius: "4px" },
            readOnly: isReadOnly,
            endAdornment: (shouldShowLanguageFlag &&
                <LanguageIcon style={{ marginLeft: "12px" }} />)
          }}
          value={value?.text ?? ""}
          onChange={({ target }) => !isReadOnly && onChange({ ...value, text: target.value })}
          onBlur={e => {
            const trimmedValue = e.target.value.trim();
            !isReadOnly && onChange({ ...value, text: trimmedValue });
          }}
        />
        {
          shouldShowAddButton &&
            <Tooltip
              title="Add a button"
              placement={"top"}
              componentsProps={{
                popper: {
                  sx: {
                    [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
                      marginBottom: "0px",
                      backgroundColor: theme.palette.primary.dark,
                      borderRadius: "4px"
                    }
                  }
                }
              }}
            >
              <AddSuggestionBadgeContainer
                data-testid={`add-suggestion-badge-${index}`}
                badgeContent={(value?.suggestions ?? []).length}
                color="secondary">
                <AddSuggestionButton data-testid={`add-suggestion-button-${index}`} onClick={onAddButtonClick}>
                  <AddSuggestionIcon color={theme.palette.secondary.main} />
                </AddSuggestionButton>
              </AddSuggestionBadgeContainer>
            </Tooltip>
        }
      </Stack>);
  case FIELD_TYPES.MULTI_LANGUAGE_TEXT:
    if (isDrawerView) {
      return <MultiLanguageDrawer
        collectionLanguages={collectionLanguages}
        currentSelectedLanguage={currentSelectedLanguage}
        title={field?.spec?.display_key}
        isMandatory={field?.spec?.is_mandatory}
        value={field?.configuration}
        textFieldProps={getTextFieldProps()}
        errorText={error}
        isReadOnly={isReadOnly}
        onChange={onChange}
        fieldType={fieldType}
        index={index}
        testId={testId}
      />;
    }

    return (
      <StyledTextField
        {...getTextFieldProps()}
        {...getTestIdObject()}
        inputRef={inputRef}
        InputProps={{
          sx: { borderRadius: "4px" },
          readOnly: isReadOnly,
          startAdornment: (shouldShowLanguageFlag && languageFlagPosition === "start" &&
            <LanguageIcon style={{ marginRight: "8px" }} />),
          endAdornment: (shouldShowLanguageFlag && languageFlagPosition === "end" &&
              <LanguageIcon style={{ marginLeft: "12px" }} />)
        }}
        value={value?.[currentSelectedLanguage] ?? ""}
        onChange={({ target }) => !isReadOnly && onChange({ ...value, [currentSelectedLanguage]: target.value })}
        onBlur={e => !isReadOnly && onChange({ ...value, [currentSelectedLanguage]: e.target.value.trim() })}
        onKeyDown={onKeyDown}
      />
    );
  default:
    return <StyledTextField
      {...getTextFieldProps()}
      {...getTestIdObject()}
      inputRef={inputRef}
      InputProps={{ sx: { borderRadius: "4px", ...InputProps?.sx }, disabled: isReadOnly }}
      value={value ?? ""}
      onChange={({ target: { value } }) => !isReadOnly && onChange(value)}
      onBlur={e => {
        !isReadOnly && onChange(e.target.value.trim());
      }}
      onKeyDown={onKeyDown}
    />;
  }
};

export default Field;

const StyledHelperPopper = styled(Popper)`
  background-color: #fff;
  box-shadow: 0px 4px 8px 0px #0000001F, 0px 2px 2px 2px #00000003;
  padding: 12px 16px;
  border-radius: 8px;
  color: #EF404B;
  white-space: pre-line;
  margin-top: 8px;
`;

const AddSuggestionBadgeContainer = styled(Badge)`
  top: 12px;
  height: fit-content;
`;

const AddSuggestionButton = styled(IconButton)`
  border: 1px solid #BCC2FB;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background-color: white;
  cursor: pointer;

  &:hover {
    border: 1px solid #BCC2FB;
    background-color: ${({ theme }) => theme.palette.secondary.light};
  }

  &:active {
    border: 1px solid #BCC2FB;
    background-color: ${({ theme }) => theme.palette.secondary.lightHover};
  }

  & .MuiTouchRipple-root {
    display: none;
  }
`;
