import { Box, Chip, Stack, chipClasses } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import CloseIcon from "src/icons/CloseIcon";

const CHIP_LIMIT = 1;

const StyledChipDeleteIcon = styled(Stack)(() => ({
  backgroundColor: "#2D2C443D",
  borderRadius: "50%",
  width: "18px",
  height: "18px",
  alignItems: "center",
  justifyContent: "center",
  padding: "6px",
  "& svg": { width: "14px" },
  "& svg path": { strokeWidth: "3px" }
}));

const StyledChip = styled(Chip, {
})(() => ({
  border: "1px solid rgba(45, 44, 68, 0.24)",
  fontSize: "12px",
  fontWeight: 500,
  [`& .${chipClasses.label}`]: {
    userSelect: "text"
  }
}));

const StyledChipContainer = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "8px"
}));

const ChipContainer = ({ values, showAllChips, testId, isReadOnly, onDelete }) => (<>
  {showAllChips ?
    <StyledChipContainer>
      {values.map((option, index) => (
        <StyledChip
          data-testid={`${testId ? `${testId}-` : ""}chip-${index}`}
          variant="outlined"
          label={option}
          key={`${option}-${index}`}
          deleteIcon={isReadOnly ? <></> : <StyledChipDeleteIcon
            data-testid={`${testId ? `${testId}-` : ""}chip-${index}-delete-icon`}><CloseIcon
              color="#fff"/></StyledChipDeleteIcon>}
          onDelete={onDelete?.(option)}
        />
      ))}</StyledChipContainer>
    :
    <Box display="flex" justifyContent="flex-start">
      {values?.length > 0 &&
        <StyledChip
          label={values[0]}
          variant="outlined"
          data-testid={`${testId ? `${testId}-` : ""}chip-0`} />}

      {values?.length > CHIP_LIMIT &&
        <StyledChip
          style={{ marginLeft: "8px", backgroundColor: "transparent" }}
          label={`+${values.length - CHIP_LIMIT}`}
          data-testid={`${testId ? `${testId}-` : ""}chip-count-box`}/>}
    </Box>}
</>
);

export default ChipContainer;
