import React from "react";

const TransferToLiveAgentIcon = () => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7807_9753)">
      <circle cx="24" cy="24" r="23.5" fill="#F3F3FF" stroke="#DEDFFF"/>
      <path opacity="0.12" d="M19.3808 21.8531C20.0768 23.3027 21.0256 24.6614 22.2271 25.8629C23.4287 27.0645 24.7873 28.0133 26.237 28.7093C26.3617 28.7692 26.424 28.7991 26.5029 28.8221C26.7832 28.9038 27.1275 28.8451 27.3649 28.6751C27.4317 28.6273 27.4889 28.5701 27.6032 28.4558C27.9528 28.1062 28.1276 27.9314 28.3034 27.8171C28.9663 27.3861 29.8209 27.3861 30.4838 27.8171C30.6595 27.9314 30.8343 28.1062 31.184 28.4558L31.3788 28.6507C31.9103 29.1821 32.176 29.4478 32.3203 29.7332C32.6074 30.3008 32.6074 30.9711 32.3203 31.5386C32.176 31.824 31.9103 32.0897 31.3788 32.6212L31.2212 32.7788C30.6916 33.3084 30.4268 33.5732 30.0667 33.7755C29.6672 33.9999 29.0467 34.1613 28.5885 34.1599C28.1756 34.1587 27.8933 34.0786 27.3289 33.9184C24.2955 33.0574 21.4331 31.4329 19.0451 29.0449C16.6572 26.6569 15.0327 23.7946 14.1717 20.7612C14.0115 20.1967 13.9314 19.9145 13.9302 19.5016C13.9288 19.0434 14.0902 18.4229 14.3146 18.0234C14.5169 17.6633 14.7817 17.3985 15.3113 16.8689L15.4689 16.7113C16.0004 16.1798 16.2661 15.9141 16.5515 15.7697C17.119 15.4827 17.7893 15.4827 18.3569 15.7697C18.6422 15.9141 18.908 16.1798 19.4394 16.7113L19.6343 16.9061C19.9839 17.2557 20.1587 17.4305 20.273 17.6063C20.704 18.2692 20.704 19.1238 20.273 19.7867C20.1587 19.9625 19.9839 20.1373 19.6343 20.4869C19.52 20.6012 19.4628 20.6583 19.415 20.7251C19.2449 20.9626 19.1862 21.3068 19.268 21.5872C19.291 21.6661 19.3209 21.7284 19.3808 21.8531Z" fill="#4548C4"/>
      <path d="M19.3808 21.8534C20.0768 23.303 21.0256 24.6616 22.2271 25.8632C23.4287 27.0648 24.7873 28.0136 26.237 28.7096C26.3617 28.7694 26.424 28.7994 26.5029 28.8224C26.7832 28.9041 27.1275 28.8454 27.3649 28.6754C27.4317 28.6275 27.4889 28.5704 27.6032 28.4561C27.9528 28.1064 28.1276 27.9316 28.3034 27.8174C28.9663 27.3864 29.8209 27.3864 30.4838 27.8174C30.6595 27.9316 30.8343 28.1064 31.184 28.4561L31.3788 28.6509C31.9103 29.1824 32.176 29.4481 32.3203 29.7335C32.6074 30.301 32.6074 30.9713 32.3203 31.5389C32.176 31.8242 31.9103 32.09 31.3788 32.6214L31.2212 32.779C30.6916 33.3087 30.4268 33.5735 30.0667 33.7757C29.6672 34.0001 29.0467 34.1615 28.5885 34.1601C28.1756 34.1589 27.8933 34.0788 27.3289 33.9186C24.2955 33.0576 21.4331 31.4332 19.0451 29.0452C16.6572 26.6572 15.0327 23.7948 14.1717 20.7614C14.0115 20.197 13.9314 19.9148 13.9302 19.5018C13.9288 19.0436 14.0902 18.4231 14.3146 18.0236C14.5169 17.6636 14.7817 17.3988 15.3113 16.8691L15.4689 16.7115C16.0004 16.1801 16.2661 15.9143 16.5515 15.77C17.119 15.4829 17.7893 15.4829 18.3569 15.77C18.6422 15.9143 18.908 16.1801 19.4394 16.7115L19.6343 16.9064C19.9839 17.256 20.1587 17.4308 20.273 17.6066C20.704 18.2694 20.704 19.124 20.273 19.7869C20.1587 19.9627 19.9839 20.1375 19.6343 20.4871C19.52 20.6014 19.4628 20.6586 19.415 20.7254C19.2449 20.9628 19.1862 21.3071 19.268 21.5874C19.291 21.6663 19.3209 21.7287 19.3808 21.8534Z" fill="#B3B7FF"/>
      <path d="M19.3808 21.8532C20.0768 23.3028 21.0256 24.6615 22.2271 25.8631C23.4287 27.0646 24.7873 28.0134 26.237 28.7094C26.3617 28.7693 26.424 28.7992 26.5029 28.8222C26.7832 28.904 27.1275 28.8453 27.3649 28.6752C27.4317 28.6274 27.4889 28.5702 27.6032 28.4559C27.9528 28.1063 28.1276 27.9315 28.3034 27.8172C28.9663 27.3862 29.8209 27.3862 30.4838 27.8172C30.6595 27.9315 30.8343 28.1063 31.184 28.4559L31.3788 28.6508C31.9103 29.1822 32.176 29.448 32.3203 29.7333C32.6074 30.3009 32.6074 30.9712 32.3203 31.5387C32.176 31.8241 31.9103 32.0898 31.3788 32.6213L31.2212 32.7789C30.6916 33.3085 30.4268 33.5733 30.0667 33.7756C29.6672 34 29.0467 34.1614 28.5885 34.16C28.1756 34.1588 27.8933 34.0787 27.3289 33.9185C24.2955 33.0575 21.4331 31.433 19.0451 29.045C16.6572 26.6571 15.0327 23.7947 14.1717 20.7613C14.0115 20.1969 13.9314 19.9146 13.9302 19.5017C13.9288 19.0435 14.0902 18.423 14.3146 18.0235C14.5169 17.6634 14.7817 17.3986 15.3113 16.869L15.4689 16.7114C16.0004 16.1799 16.2661 15.9142 16.5515 15.7699C17.119 15.4828 17.7893 15.4828 18.3569 15.7699C18.6422 15.9142 18.908 16.1799 19.4394 16.7114L19.6343 16.9062C19.9839 17.2559 20.1587 17.4307 20.273 17.6064C20.704 18.2693 20.704 19.1239 20.273 19.7868C20.1587 19.9626 19.9839 20.1374 19.6343 20.487C19.52 20.6013 19.4628 20.6584 19.415 20.7253C19.2449 20.9627 19.1862 21.307 19.268 21.5873C19.291 21.6662 19.3209 21.7285 19.3808 21.8532Z" stroke="#4548C4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M29.5698 15L33.0698 18.5M33.0698 18.5L29.5698 22M33.0698 18.5H25.0698" stroke="#EF4068" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_7807_9753">
        <rect width="48" height="48" fill="white"/>
      </clipPath>
    </defs>
  </svg>
;

export default TransferToLiveAgentIcon;
