import { get } from "src/services/api";
import { FETCH_QUERY_KEYS } from "src/queries/consts";
import { useQuery, useMutation } from "@tanstack/react-query";
import { retryIfServerError } from "../utils";
import { sharePreview, getPreviewLink } from "src/queries/services/preview";

export const useExternalPreview = (q) => useQuery({
  queryKey: [FETCH_QUERY_KEYS.EXTERNAL_PREVIEW],
  queryFn: async () => await get(`/preview?q=${q}`),
  staleTime: Infinity,
  enabled: !!q
});

export const useExternalPreviewLink = ({ accountId, assistantId, branchName }) => useQuery({
  queryKey: [FETCH_QUERY_KEYS.EXTERNAL_PREVIEW_LINK, { accountId, assistantId, branchName }],
  queryFn: async () => await getPreviewLink({ accountId, assistantId, branchName }),
  enabled: !!accountId && !!assistantId,
  staleTime: Infinity
});

export const useMutateSharePreview = () => useMutation({
  mutationFn: sharePreview,
  retry: retryIfServerError
});
