import React from "react";

const IndeterminateIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="filled/toggle/indeterminate-check-box">
      <path id="Vector"
            d="M15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5ZM14.1667 10.8333H5.83333V9.16667H14.1667V10.8333Z"
            fill="#4548C4"/>
    </g>
  </svg>
);

export default IndeterminateIcon;
