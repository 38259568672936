import { Box } from "@mui/material";
import React from "react";
import Loader from "src/icons/Loader";

const HyroLoader = ({ text = "Just a moment...", height, background }) => (<Box
  display="flex"
  flexDirection="column"
  height={height || "calc(100vh - 124px)"}
  alignItems="center"
  justifyContent="center"
  bgcolor={background || "#FFFFFF"}
>
  <Box mb="16px">
    <Loader />
  </Box>
  <Box fontWeight={400} fontSize="14px" color="#00000099">
    {text}
  </Box>
</Box>);

export default HyroLoader;
