import CheckIcon from "@mui/icons-material/Check";
import { List, Popover, Typography } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { darken, styled } from "@mui/material/styles";
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "src/components/common/Dialogs/HyroUiDialog";

import Avatar from "../../components/common/Avatar";
import { DIALOG_VARIANTS, EXIT_ASSISTANT_DIALOG } from "../../components/common/Dialogs/DialogConsts";
import { useIsAdminPanel, useIsMobile } from "../../hooks";
import ArrowDownIcon from "../../icons/ArrowDownIcon";
import useAppStore from "../../stores/appStore";
import {
  selectedAccountIdSelector,
  setSelectedAssistantByIdSelector,
  setHeaderSkeletonSelector,
  showHeaderSkeletonSelector,
  systemModeSelector, clearAccountAndAssistantSelector
} from "../../stores/selectors/appSelectors";
import { collectionConfigChangedSelector } from "../../stores/selectors/collectionsSelectors";
import { handleDraftTripletsSelector, isFirstDraftSelector } from "../../stores/selectors/tripletsSelectors";
import { SYSTEM_MODES } from "../../stores/slices/createAppSlice";
import { APP_HEADER_HEIGHT, APP_MOBILE_HEADER_HEIGHT } from "../consts";
import { useAccounts } from "src/queries/hooks/accounts";
import SearchBar from "src/components/common/SearchBar";
import { noOptionList } from "src/components/collections/helpers";
import useKeyboardNavigation from "src/components/common/hooks/useKeyboardNavigation";

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  justifyContent: "end",
  svg: {
    verticalAlign: "top"
  }
}));

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== "selected" && prop !== "focused"
})(({ theme, selected, focused }) => ({
  ...(selected && {
    color: theme.palette.primary.main
  }),
  ...(focused && {
    backgroundColor: darken(theme.palette.background.default, 0.04)
  })
}));

const StyledSearchListItem = styled(ListItem)(() => ({
  padding: "0 16px 8px 16px"
}));

const AccountName = styled("div")(() => ({
  marginLeft: "12px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"
}));

// eslint-disable-next-line max-lines-per-function, complexity
const AccountsMenu = () => {
  const isMobile = useIsMobile();
  const isAdminPanel = useIsAdminPanel();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const collectionConfigChanged = useAppStore(collectionConfigChangedSelector);
  const showHeaderSkeleton = useAppStore(showHeaderSkeletonSelector);
  const setShowHeaderSkeleton = useAppStore(setHeaderSkeletonSelector);
  const setSelectedAssistant = useAppStore(setSelectedAssistantByIdSelector);
  const clearAccountAndAssistant = useAppStore(clearAccountAndAssistantSelector);
  const selectedAccountId = useAppStore(selectedAccountIdSelector);
  const accounts = useAccounts()?.data;
  const selectedAccountName = accounts?.find(({ accountId }) => accountId === selectedAccountId)?.name;
  const open = Boolean(anchorEl);
  const [, deleteCurrentDraft] = useAppStore(handleDraftTripletsSelector);
  const [isExitDialogOpen, setIsExitDialogOpen] = useState(false);
  const [upcomingAccountId, setUpcomingAccountId] = useState(null);
  const systemMode = useAppStore(systemModeSelector);
  const isFirstDraft = useAppStore(isFirstDraftSelector);
  const [searchText, setSearchText] = useState("");

  const filteredAccounts = useMemo(() => accounts?.filter(account => account.name.toLowerCase().includes(searchText.toLowerCase()) ||(account.id === searchText)),
    [accounts, searchText]);
  let accountsToMap = searchText === "" ? accounts : filteredAccounts;
  accountsToMap = accountsToMap?.length === 0 ? noOptionList() : accountsToMap;

  const onItemSelectedFromKeyboard = (selectedItem) => {
    handleSelectedAccountChange(selectedItem.accountId);
  };

  const {
    focusedIndex,
    handleKeyDown,
    listRef,
    resetFocus
  } = useKeyboardNavigation(filteredAccounts, open, onItemSelectedFromKeyboard);

  const showSearch = accounts?.length > 5;

  useEffect(() => {
    if ((isAdminPanel && accounts?.length > 0) || (!isAdminPanel && selectedAccountName)) {
      setShowHeaderSkeleton(false);
    }
  }, [selectedAccountName, isAdminPanel, accounts, setShowHeaderSkeleton]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setSearchText("");
    setAnchorEl(null);
    resetFocus();
  };

  const navigateToAccount = accountId =>
    navigate(`/${accountId}`);

  const handleSelectedAccountChange = (accountId) => {
    if (collectionConfigChanged && !isFirstDraft) {
      setUpcomingAccountId(accountId);
      setIsExitDialogOpen(true);
    } else {
      clearAccountAndAssistant();
      navigateToAccount(accountId);
    }
    handleMenuClose();
  };

  if (showHeaderSkeleton) return null;

  return (
    <>
      {accounts?.length > 0
        ? (
          <div style={{
            color: "#363554",
            pointerEvents: systemMode === SYSTEM_MODES.EDIT && !isAdminPanel ? "none" : "auto"
          }}>
            <ListItemButton
              data-testid="accounts-menu-button"
              disableRipple
              sx={{
                height: isMobile ? 24 : 36,
                "&:hover": { bgcolor: "transparent" },
                cursor: accounts?.length > 1 ? "pointer" : "default"
              }}
              onClick={handleClick}>
              <Avatar
                variant="rounded"
                sx={{ borderRadius: "4px" }}
                fontSize={13.5} size={24}
                name={selectedAccountId} />
              {!isMobile && (
                <AccountName>{selectedAccountName || "Choose an account"}</AccountName>
              )}
              {(systemMode !== SYSTEM_MODES.EDIT && accounts?.length > 1) || isAdminPanel ? <ArrowDownIcon
                color={"#2D2C44B2"}
                fontSize="18px"
                sx={{ marginTop: "2px", marginLeft: "4px" }}
              /> : null}
            </ListItemButton>
            {accounts?.length > 1 ?
              <Popover
              id="account-menu"
              anchorEl={anchorEl}
              open={open}
              onKeyDown={handleKeyDown}
              onClose={handleMenuClose}
              elevation={3}
              PaperProps={{
                style: {
                  maxHeight: showSearch && 440,
                  maxWidth: 400
                }
              }}
              sx={{ top: (isMobile ? APP_MOBILE_HEADER_HEIGHT : APP_HEADER_HEIGHT) - 16 }}
            >
              <List
                style={{ outline: "none", maxHeight: 440 }}
                sx={{ width: "260px" }}
                tabIndex={-1}
                ref={listRef}>
                <ListItem>
                  <ListItemText
                    primary="Switch Account"
                    primaryTypographyProps={{ variant: "caption" }}
                  />
                </ListItem>

                {showSearch && (
                  <StyledSearchListItem>
                    <SearchBar stayOpen hideTooltip shouldAutoFocus onChange={setSearchText} customWidth="100%" handleKeyDown={handleKeyDown}/>
                  </StyledSearchListItem>)
                }

                {accountsToMap
                  ?.sort((a1, a2) => a1.name.localeCompare(a2.name))
                  .map((account, index) => (
                    <StyledListItemButton
                      disabled={account.disabled}
                      data-testid={`account-${account.name}`}
                      key={index}
                      onClick={() => handleSelectedAccountChange(account.accountId)}
                      selected={account.accountId === selectedAccountId}
                      focused={focusedIndex === index}
                    >
                      {account.key !== "no_options" && <ListItemIcon sx={{ minWidth: "40px" }}>
                        <Avatar
                          variant="rounded"
                          sx={{ borderRadius: "4px" }}
                          fontSize={13.5}
                          size={24}
                          name={account.accountId}/>
                      </ListItemIcon>}
                      <ListItemText>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            lineHeight: 1.2
                          }}
                        >
                          {account.name || account.display_key}
                        </Typography>
                      </ListItemText>
                      {account.accountId === selectedAccountId && (
                        <StyledListItemIcon>
                          <CheckIcon />
                        </StyledListItemIcon>
                      )}
                    </StyledListItemButton>
                  ))}
              </List>
            </Popover> : null}
          </div>
        )
        : null}
      <Dialog
        open={isExitDialogOpen}
        title={EXIT_ASSISTANT_DIALOG.title}
        submitButtonText={EXIT_ASSISTANT_DIALOG.discardButton}
        cancelButtonText={EXIT_ASSISTANT_DIALOG.keepEditingButton}
        variant={DIALOG_VARIANTS.transactional}
        handleSubmit={() => {
          deleteCurrentDraft();
          setSelectedAssistant(null);
          navigateToAccount(upcomingAccountId);
          handleMenuClose();
          setIsExitDialogOpen(false);
        }}
        handleCancel={() => setIsExitDialogOpen(false)}
      >
        {EXIT_ASSISTANT_DIALOG.content}
      </Dialog>
    </>
  );
};

export default AccountsMenu;
