import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import React from "react";

import usePublishDialog from "./usePublishDialog";

const PublishDialog = ({ open, onClose, handleSubmit }) => {
  const { dialogContent } = usePublishDialog({
    onClose,
    handleSubmit
  });
  const testId = "publish-dialog";
  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <DialogTitle>{dialogContent.title}</DialogTitle>
      <DialogContent>
        <Typography
          style={{ whiteSpace: "pre-line" }}
          variant="body1"
          color="text.primary"
        >
          {dialogContent.subtitle}
        </Typography>
        {dialogContent.step === 1
          ? (
            <TextField
              fullWidth
              sx={{ mt: 2 }}
              label={dialogContent.label}
              value={dialogContent.changeDescription}
              onChange={({ target: { value } }) =>
                dialogContent.setChangeDescription(value)}
            >
            </TextField>
          )
          : null}
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button data-testid={`${testId}-close-button`} onClick={dialogContent.handleCancel}>Back to editing</Button>
        <Button
          data-testid={`${testId}-publish-button`}
          variant="contained"
          onClick={dialogContent.handlePublish}
          disabled={dialogContent.disablePublish}
        >
          {dialogContent.actionButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PublishDialog;
