import React, { useState } from "react";
import useAppStore from "../../stores/appStore";
import {
  selectedAssistantSelector,
  systemModeSelector,
  setIsWebSnippetOpenSelector,
  setSystemModeSelector,
  hasPermissionSelector
} from "../../stores/selectors/appSelectors";
import { ASSISTANT_CHANNELS, BUILD_STATUSES } from "../../utils";
import Tooltip from "src/components/common/Tooltip";
import { PREVIEW_TOOLTIP } from "src/layout/Header/header-types/status-header/DraftPublishing/DraftHeaderConsts";
import { SYSTEM_MODES } from "src/stores/slices/createAppSlice";
import { draftSelector } from "src/stores/selectors/tripletsSelectors";
import { isUpdatingCollectionSelector } from "src/stores/selectors/collectionsSelectors";
import PreviewButtonVoice from "./PreviewButtonVoice";
import PreviewButtonWeb from "./PreviewButtonWeb";
import PreviewDropdown from "./PreviewDropdown";
import CallMeManager from "./CallMe/CallMeManager";
import SharePreviewDialog from "./SharePreviewDialog";
import { notifyToggleWidget } from "../../analytics/notifyCustomActions";
import { USER_PERMISSIONS } from "@hyro/dashboard-commons";

const PreviewButton = ({ isButton }) => {
  const buildDraftResponse = useAppStore(state => state.buildDraftResponse);
  const systemMode = useAppStore(systemModeSelector);
  const setSystemMode = useAppStore(setSystemModeSelector);
  const setIsWebSnippetOpen = useAppStore(setIsWebSnippetOpenSelector);
  const draft = useAppStore(draftSelector);
  const selectedAssistant = useAppStore(selectedAssistantSelector);
  const isUpdatingCollection = useAppStore(isUpdatingCollectionSelector);
  const [anchorEl, setAnchorEl] = useState(null);
  const [phoneDialogOpen, setPhoneDialogOpen] = useState(false);
  const [isSharePreviewDialogOpen, setIsSharePreviewDialogOpen] = useState(false);
  const isPhone = selectedAssistant?.channel === ASSISTANT_CHANNELS.PHONE;
  const branchName = systemMode === SYSTEM_MODES.EDIT ? draft?.branchName : undefined;
  const hasPermission = useAppStore(hasPermissionSelector);

  const getPreviewTooltip = () => {
    if (systemMode !== SYSTEM_MODES.EDIT) return "";

    if ((!draft || isUpdatingCollection)) {
      return PREVIEW_TOOLTIP.NO_CHANGES;
    }

    if (draft && buildDraftResponse?.status !== BUILD_STATUSES.LIVE) {
      return PREVIEW_TOOLTIP.BUILD_NEEDED;
    }

    return "";
  };

  const handleOpenDropdown = (event) => {
    if (hasPermission(USER_PERMISSIONS.EXTERNAL_PREVIEW.READ)) {
      setAnchorEl(event.currentTarget);
    } else {
      if (isPhone) setPhoneDialogOpen(true);
      else handleOpenWidget();
    }
  };

  const handleOpenWidget = () => {
    notifyToggleWidget("Opened");
    setSystemMode(SYSTEM_MODES.PREVIEW);
    setIsWebSnippetOpen(true);
  };

  return (<>
    <Tooltip title={getPreviewTooltip()} testId="preview-button-tooltip">
      {isPhone ?
        <PreviewButtonVoice isButton={isButton} isActive={!!anchorEl} onClick={handleOpenDropdown} />
        : <PreviewButtonWeb isButton={isButton} isActive={!!anchorEl} onClick={handleOpenDropdown} />}
    </Tooltip>

    <PreviewDropdown anchorEl={anchorEl} setAnchorEl={setAnchorEl} setPhoneDialogOpen={setPhoneDialogOpen} setIsSharePreviewDialogOpen={setIsSharePreviewDialogOpen} handleOpenWidget={handleOpenWidget} align={isButton ? "left" : "right"} />

    <SharePreviewDialog isSharePreviewDialogOpen={isSharePreviewDialogOpen} setIsSharePreviewDialogOpen={setIsSharePreviewDialogOpen} />

    <CallMeManager phoneDialogOpen={phoneDialogOpen} setPhoneDialogOpen={setPhoneDialogOpen} branchName={branchName} />
  </>);
};

export default PreviewButton;
