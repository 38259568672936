import CheckIcon from "@mui/icons-material/Check";
import { List, Popover, Tooltip, Stack } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "src/components/common/Dialogs/HyroUiDialog";

import { DIALOG_VARIANTS, EXIT_ASSISTANT_DIALOG } from "../components/common/Dialogs/DialogConsts";
import { useCurrentAssistantPage } from "../hooks";
import UnfoldIcon from "../icons/UnfoldIcon";
import useAppStore from "../stores/appStore";
import {
  assistantsSelector,
  selectedAccountIdSelector,
  selectedAssistantSelector, setSelectedAccountSelector,
  systemModeSelector
} from "../stores/selectors/appSelectors";
import { setSelectedCollectionSelector } from "../stores/selectors/collectionsSelectors";
import { isEditModeSelector, isFirstDraftSelector } from "../stores/selectors/tripletsSelectors";
import { SYSTEM_MODES } from "../stores/slices/createAppSlice";
import { BUILDING_BLOCKS, CHANNEL_TO_DISPLAY_NAME, SKILLS, ASSISTANT_CHANNELS } from "../utils";
import SearchBar from "src/components/common/SearchBar";
import { noOptionList } from "src/components/collections/helpers";
import useKeyboardNavigation from "src/components/common/hooks/useKeyboardNavigation";
import PhoneIcon from "src/static/images/phone_icon.svg";
import ChatIcon from "src/static/images/chat_icon.svg";

const ButtonContainer = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1.5)
}));

const StyledButton = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== "hover"
})(({ theme, hover }) => ({
  "maxWidth": "100%",
  "padding": theme.spacing(1, 1.5),
  "borderRadius": theme.shape.borderRadius,
  "display": "flex",
  "justifyContent": "center",
  "transition": "none",
  "&:hover": hover
    ? {
      backgroundColor: theme.palette.action.hover
    }
    : {
      backgroundColor: "transparent",
      cursor: "default"
    },
  "&.Mui-selected": {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light
  }
}));

const StyledButtonIcon = styled(ListItemIcon)(() => ({
  minWidth: 0,
  svg: {
    verticalAlign: "top"
  }
}));

const ChannelListItem = styled(ListItemText)(() => ({
  marginBlock: 0,
  display: "flex",
  alignItems: "center",
  marginTop: "2px"
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: "block",
  marginBlock: theme.spacing(1),
  padding: theme.spacing(0, 1)
}));

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== "selected"
})(({ selected, theme }) => ({
  "minHeight": 36,
  "justifyContent": open ? "initial" : "center",
  "gap": theme.spacing(0.5),
  "padding": theme.spacing(1),
  "borderRadius": theme.shape.borderRadius,
  "textOverflow": "ellipsis",
  "&:hover": {
    backgroundColor: theme.palette.action.hover
  },
  ...(selected && {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    }
  })
}));

const StyledListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: prop => prop !== "selected"
})(({ theme, selected }) => ({
  color: selected ? theme.palette.primary.main : theme.palette.text.secondary,
  ...(selected && { justifyContent: "end" }),
  minWidth: 0,
  marginLeft: theme.spacing(1),
  svg: {
    verticalAlign: "top"
  }
}));

const TextContainer = styled("div")(() => ({
  width: "100%",
  textOverflow: "ellipsis",
  overflow: "hidden"
}));

const ChannelText = styled("span")(({ theme, isList, selected }) => ({
  color: (isList && selected) ? theme.palette.primary.main : theme.palette.action.active
}));

const StyledIconContainer = styled(Stack)(() => ({
  justifyContent: "center",
  alignItems: "center",
  width: "40px",
  height: "40px",
  borderRadius: "10px",
  border: "1px solid #DEDFFF",
  backgroundColor: "#F3F3FF"
}));

// eslint-disable-next-line max-lines-per-function, complexity
const AssistantSelector = ({ isNavPanelOpen }) => {
  const isEditMode = useAppStore(isEditModeSelector);
  const isFirstDraft = useAppStore(isFirstDraftSelector);
  const setSelectedCollection = useAppStore(setSelectedCollectionSelector);
  const accountId = useAppStore(selectedAccountIdSelector);
  const systemMode = useAppStore(systemModeSelector);
  const assistants = useAppStore(assistantsSelector)?.sort();
  const [anchorEl, setAnchorEl] = useState(null);
  const setSelectedAccount = useAppStore(setSelectedAccountSelector);
  const selectedAssistant = useAppStore(selectedAssistantSelector);
  const navigate = useNavigate();
  const currentPage = useCurrentAssistantPage();
  const [isExitDialogOpen, setIsExitDialogOpen] = useState(false);
  const [upcomingAssistantId, setUpcomingAssistantId] = useState(null);
  const [popoverMinWidth, setPopoverMinWidth] = useState(0);
  const [searchText, setSearchText] = useState("");
  const paperRef = useRef(null);

  useEffect(() => {
    if (accountId && assistants.length === 0) { setSelectedAccount(accountId); }
  }, [accountId, assistants, setSelectedAccount]);

  const filteredAssistants = useMemo(() => assistants.filter(assistant => assistant.name.toLowerCase().includes(searchText.toLowerCase()) ||(assistant.id === searchText)),
    [assistants, searchText]);
  let assistantsToMap = searchText === "" ? assistants : filteredAssistants;
  assistantsToMap = assistantsToMap?.length === 0 ? noOptionList() : assistantsToMap;
  const open = Boolean(anchorEl);
  const showSearch = assistants.length > 5;
  const buttonIsClickable = assistants.length > 1 && systemMode !== SYSTEM_MODES.EDIT;

  const onItemSelectedFromKeyboard = (selectedItem) => {
    handleSelectedAssistantChange(selectedItem.id);
  };

  const {
    focusedIndex,
    handleKeyDown,
    listRef,
    resetFocus
  } = useKeyboardNavigation(filteredAssistants, open, onItemSelectedFromKeyboard);

  const handlePopoverEntered = () => {
    if (paperRef.current && popoverMinWidth === 0) {
      const popoverWidth = paperRef.current.offsetWidth;
      setPopoverMinWidth(popoverWidth);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    resetFocus();
  };

  const handleMenuClose = () => {
    setSearchText("");
    setAnchorEl(null);
    setPopoverMinWidth(0);
    resetFocus();
  };

  const navigateToNewAssistant = assistantId =>{
    let page;

    if (currentPage.includes(SKILLS)) {
      page = SKILLS;
    } else if (currentPage.includes(BUILDING_BLOCKS)) {
      page = BUILDING_BLOCKS;
    } else {
      page = currentPage;
    }
    navigate(`/${accountId}/${assistantId}/${page}`);
  };

  const handleSelectedAssistantChange = (assistantId) => {
    if (isEditMode && !isFirstDraft) {
      setUpcomingAssistantId(assistantId);
      navigateToNewAssistant(assistantId);
    }
    else {
      setSelectedCollection(null);
      navigateToNewAssistant(assistantId);
    }
    handleMenuClose();
  };

  const renderNameAndChannel = (assistant, isList, selected) => (<TextContainer>
    <ListItemText style={{ marginBlock: 0 }}>
      <Typography variant="body2" noWrap>
        {assistant.name}
      </Typography>
    </ListItemText>
    <ChannelListItem style={{ marginTop: isList ? "2px" : "4px" }}>
      <Typography variant="caption" noWrap>
        <ChannelText noWrap isList={isList} selected={selected}>
          {CHANNEL_TO_DISPLAY_NAME[assistant.channel] ? CHANNEL_TO_DISPLAY_NAME[assistant.channel] + " assistant" : "Unknown Channel"}
        </ChannelText>
      </Typography>
    </ChannelListItem>
  </TextContainer>);

  return <>
        <ButtonContainer>
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  maxWidth: "180px",
                  marginLeft: isNavPanelOpen ? "0px" : "8px"
                }
              }
            }}
            title={selectedAssistant.name} placement="bottom"
          >
            <StyledButton
              hover={buttonIsClickable}
              {...(buttonIsClickable && { onClick: handleMenuOpen })}
              disableRipple
            >
              {isNavPanelOpen ? renderNameAndChannel(selectedAssistant, false) : null}
              {buttonIsClickable && (
              <StyledButtonIcon sx={{ ml: isNavPanelOpen ? "8px": "0px" }}>
                <UnfoldIcon fontSize="small"/>
              </StyledButtonIcon>
              )}
            </StyledButton>
          </Tooltip>
        </ButtonContainer>

        <Popover
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          onKeyDown={handleKeyDown}
          elevation={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          PaperProps={{
            ref: paperRef,
            style: {
              maxHeight: showSearch ? 400 : 300,
              maxWidth: 400,
              minWidth: popoverMinWidth
            }
          }}
        >
          <List
            ref={listRef}
            onMouseOver={handlePopoverEntered}
            style={{ outline: "none" }}
            sx={{ overflow: "auto" }}>
            {showSearch && (
              <StyledListItem>
                <SearchBar hideTooltip stayOpen shouldAutoFocus onChange={setSearchText} customWidth="100%" handleKeyDown={handleKeyDown}/>
              </StyledListItem>)
            }

            {assistantsToMap.map((assistant, i) => {
              const selected = assistant.id === selectedAssistant.assistantId;
              const isWebAssistant = assistant.channel === ASSISTANT_CHANNELS.WEB;
              const isPhoneAssistant = assistant.channel === ASSISTANT_CHANNELS.PHONE;

              return <StyledListItem key={assistant.id + i}>
                {assistant.key === "no_options"
                  ?
                  <StyledListItemButton disabled>
                    <Typography variant="body2">
                      {assistant.display_key}
                    </Typography>
                  </StyledListItemButton>
                  :
                <StyledListItemButton
                  onClick={() => handleSelectedAssistantChange(assistant.id)}
                  selected={selected}
                  focused={focusedIndex === i}
                >
                  <ListItemIcon sx={{ minWidth: 0, marginRight: 1 }}>
                    <StyledIconContainer>
                      {(isWebAssistant || isPhoneAssistant) && <img src={isWebAssistant ? ChatIcon : PhoneIcon} alt="" width={22} height={22} />}
                    </StyledIconContainer>
                  </ListItemIcon>
                  {renderNameAndChannel(assistant, true, selected)}
                  {selected && (
                    <StyledListItemIcon {...{ selected: true }}>
                      <CheckIcon/>
                    </StyledListItemIcon>)}
                </StyledListItemButton>}
              </StyledListItem>;
            })}
          </List>
        </Popover>
        <Dialog
          open={isExitDialogOpen}
          title={EXIT_ASSISTANT_DIALOG.title}
          submitButtonText={EXIT_ASSISTANT_DIALOG.discardButton}
          cancelButtonText={EXIT_ASSISTANT_DIALOG.keepEditingButton}
          variant={DIALOG_VARIANTS.transactional}
          handleSubmit={() => {
            navigateToNewAssistant(upcomingAssistantId);
            handleMenuClose();
            setIsExitDialogOpen(false);
          }}
          handleCancel={() => setIsExitDialogOpen(false)}
        >
          {EXIT_ASSISTANT_DIALOG.content}
        </Dialog>
      </>;
};

export default AssistantSelector;
