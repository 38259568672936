import { Chip as MuiChip, styled } from "@mui/material";
import React from "react";
import { path } from "ramda";

const StyledChipPropsToForward = ["backgroundColorPath", "textColorPath"];

const StyledChip = styled(MuiChip, {
  shouldForwardProp: (prop) => !StyledChipPropsToForward.includes(prop)
})`
  background-color: ${({ theme, backgroundColorPath }) =>
  backgroundColorPath
    ? path(["palette", ...backgroundColorPath], theme)
    : "transparent"};
  color: ${({ theme, textColorPath }) =>
  textColorPath ? path(["palette", ...textColorPath], theme) : "black"};
`;

const Chip = ({
  backgroundColorPath,
  textColorPath,
  ...props
}) => (
  <StyledChip
    backgroundColorPath={backgroundColorPath}
    textColorPath={textColorPath}
    {...props}
  />
);

export default Chip;
