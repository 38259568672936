import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  radioClasses,
  RadioGroup,
  typographyClasses
} from "@mui/material";
import React from "react";
import { capitalizeAndCleanUnderscore } from "../../../utils";
import styled from "@emotion/styled";
import StatusChip from "../../collections/StatusChip";

const RadioButtonsGroup = ({
  value,
  options,
  isReadOnly,
  isBoolean,
  onChange,
  label,
  showLabel,
  status,
  index,
  fieldType
}) => {
  const opts = isBoolean
    ? [
      { key: true, label: "Yes" },
      { key: false, label: "No" }
    ]
    : options;
  const handleChange = ({ target }) => onChange(target.value === "true");

  return (
    <FormControl disabled={isReadOnly} data-testid={`${fieldType}-${index}-${value}`}>
      {showLabel && <FormLabel>{label}</FormLabel>}
      <RadioGroup row onChange={e => !isReadOnly && handleChange(e)}>
        {opts.map(option => (
          <StyledFormControlLabel
            value={option.key}
            checked={value === option.key}
            key={option.key}
            control={(
              <StyledRadio
                data-testid={`${fieldType}-${index}-option-${option.key}${value === option.key ? "-checked":""}`}
                readOnly={isReadOnly}
                disableRipple={isReadOnly}
                color="primary"
              />
            )}
            label={capitalizeAndCleanUnderscore(option.label)}
          />
        ))}
      </RadioGroup>
      <FormHelperText data-testid={`${fieldType}-${index}-helper`}><StatusChip status={status}/></FormHelperText>
    </FormControl>
  );
};

const StyledRadio = styled(Radio, {
  shouldForwardProp: prop => prop !== "readOnly"
})`
  &:hover {
    background-color: ${({ theme, readOnly }) =>
    readOnly ? "transparent" : theme.palette.action.hover};
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  & .${typographyClasses.root}.Mui-disabled {
    color: #363554;
  }

  & .${radioClasses.root}.Mui-disabled {
    color: #2D2C44B2;
  }
`;

export default RadioButtonsGroup;
