import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { always, cond, isEmpty, isNil, T } from "ramda";
import React from "react";
import dayjs from "dayjs";

const LastUpdateItem = ({ notification }) => (<Stack
  alignItems="center"
  justifyContent="center"
  sx={{ backgroundColor: theme => theme.palette.grey[100], minHeight: "58px" }}
>
  <Box>
    {cond([
      [isEmpty, always(<Typography variant="caption">Loading last assistant update...</Typography>)],
      [isNil, always(<Typography variant="caption">(No updates yet</Typography>)],
      [
        T,
        always(
          <Stack sx={{ p: "6px 16px", textAlign: "center" }} alignItems="center" justifyContent="center">
            <Typography variant="caption">
              Last assistant update:
              {` ${dayjs(notification.updated_at).format("D MMM, H:mm")}`}
            </Typography>
            <Typography variant="caption">{notification.description}</Typography>
          </Stack>
        )
      ]
    ])(notification)}
  </Box>
</Stack>);

export default LastUpdateItem;
