import { Stack, Tooltip, tooltipClasses } from "@mui/material";
import React from "react";

const CustomTooltip = ({ title, placement = "top", children, open, popperStyle = {}, testId }) => (
  <Tooltip
    title={title}
    placement={placement}
    open={open}
    componentsProps={{
      tooltip: {
        "data-testid": testId
      },
      popper: {
        sx: {
          [`&.${tooltipClasses.popper}[data-popper-placement*=${placement}] .${tooltipClasses.tooltip}`]: {
            marginBottom: "8px",
            ...popperStyle
          }
        }
      }
    }}>
    <Stack alignItems={"center"} direction={"row"}>
      {children}
    </Stack>
  </Tooltip>
);

export default CustomTooltip;
