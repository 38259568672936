import React from "react";

const ApptReschedulingIcon = () => (<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7780_33901)">
      <circle cx="24" cy="24" r="23.5" fill="#F3F3FF" stroke="#DEDFFF"/>
      <path d="M15 20.8C15 19.1198 15 18.2798 15.327 17.638C15.6146 17.0735 16.0735 16.6146 16.638 16.327C17.2798 16 18.1198 16 19.8 16H28.2C29.8802 16 30.7202 16 31.362 16.327C31.9265 16.6146 32.3854 17.0735 32.673 17.638C33 18.2798 33 19.1198 33 20.8V22H15V20.8Z" fill="#B3B7FF"/>
      <path d="M33 23.5V20.8C33 19.1198 33 18.2798 32.673 17.638C32.3854 17.0735 31.9265 16.6146 31.362 16.327C30.7202 16 29.8802 16 28.2 16H19.8C18.1198 16 17.2798 16 16.638 16.327C16.0735 16.6146 15.6146 17.0735 15.327 17.638C15 18.2798 15 19.1198 15 20.8V29.2C15 30.8802 15 31.7202 15.327 32.362C15.6146 32.9265 16.0735 33.3854 16.638 33.673C17.2798 34 18.1198 34 19.8 34H24.5M33 22H15M28 14V18M20 14V18" stroke="#4548C4" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <g clipPath="url(#clip1_7780_33901)">
        <path d="M30.5001 28.2913V29.5L31.467 29.9835M34.1261 29.5C34.1261 31.5026 32.5027 33.126 30.5001 33.126C28.4975 33.126 26.874 31.5026 26.874 29.5C26.874 27.4974 28.4975 25.874 30.5001 25.874C32.5027 25.874 34.1261 27.4974 34.1261 29.5Z" stroke="#EF4068" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_7780_33901">
        <rect width="48" height="48" fill="white"/>
      </clipPath>
      <clipPath id="clip1_7780_33901">
        <rect x="26" y="25" width="9" height="9" rx="4.5" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default ApptReschedulingIcon;
