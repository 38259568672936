/* eslint-disable complexity */
import React from "react";
import { DEFAULT_REF_FIELD, FIELD_TYPES } from "src/components/common/fields/utils";
import { formatPhoneNumber } from "src/utils";
import { v4 as uuidv4 } from "uuid";

import {
  getMandatoryMessageForType,
  NO_OPTIONS_ERROR_MESSAGE,
  NO_OPTIONS_MESSAGE,
  STATUS_TYPES,
  VAGUE_NO_OPTIONS_MESSAGE,
  PRIMITIVE_INNER_TYPE,
  STATUS
} from "./consts";

export const isBool = (value) => typeof value === "boolean";

export const isPrimitiveType = (type) => (type === FIELD_TYPES.TEXT
  || type === FIELD_TYPES.PHONE
  || type === FIELD_TYPES.URL
  || type === FIELD_TYPES.INT
  || type === FIELD_TYPES.BOOL
  || type === FIELD_TYPES.SECRET
);

export const transformFieldSpecToConfig = (fieldSpec) => {
  if (fieldSpec?.fields?.length) {
    return fieldSpec?.fields?.map(({ key, type, default: configuration }) => ({
      key,
      type,
      configuration: configuration || null
    }));
  }
  return {
    key: fieldSpec?.key || null,
    type: fieldSpec?.type || null,
    configuration: fieldSpec?.default || null
  };
};

export function getUpdatedFieldsAfterChange(fields, changedFieldIndex, value, currentSelectedLanguage) {
  return fields.map((field, i) => {
    if (i !== changedFieldIndex) {
      return field;
    }

    let modifiedValue = value;

    if (field?.type === FIELD_TYPES.LIST) {
      const isPhone = field?.spec?.element_spec?.type === FIELD_TYPES.PHONE;

      if (isPhone) {
        const formattedPhoneNumbers = value.map(val => formatPhoneNumber(val));
        const uniquePhoneNumbers = [...new Set(formattedPhoneNumbers)];

        modifiedValue = uniquePhoneNumbers;
      }

      modifiedValue = modifiedValue.map(val => ({
        type: field?.spec?.element_spec?.type,
        key: uuidv4(),
        configuration: {
          type: field?.spec?.element_spec.default?.type,
          value: val
        }
      }));
    } else if (field?.type === FIELD_TYPES.CHOOSE_ONE) {
      const option = field?.spec?.options?.find(({ key }) => key === value);
      return {
        ...field,
        configuration: transformFieldSpecToConfig(option)
      };
    } else if (field?.type === FIELD_TYPES.CHOOSE_AT_LEAST_ONE) {
      return {
        ...field,
        configuration: value.map(({ key, type }) => ({ key, type, configuration: [] })),
        options: field?.spec?.options
      };
    }
    else if (field?.type === FIELD_TYPES.COLLECTION_REF || field?.type === FIELD_TYPES.ACTION) {
      modifiedValue = value === DEFAULT_REF_FIELD.key ? "" : value;
    } else if (field?.type === FIELD_TYPES.INT) {
      modifiedValue = parseInt(value);

      if (isNaN(modifiedValue)) {
        modifiedValue = null;
      }
    } else if (field?.type === FIELD_TYPES.PHONE) {
      modifiedValue = formatPhoneNumber(value);
    }

    if (field?.type === FIELD_TYPES.LIST
      || field?.type === FIELD_TYPES.COLLECTION_REF
      || field?.type === FIELD_TYPES.MULTI_LANGUAGE_TEXT) {
      return {
        ...field,
        configuration: modifiedValue
      };
    }

    if (field?.type === FIELD_TYPES.ACTION) {
      return {
        ...field,
        configuration: {
          [currentSelectedLanguage]: modifiedValue
        }
      };
    }
    const isPrimitive = isPrimitiveType(field?.type);
    return {
      ...field,
      configuration: {
        ...field.configuration,
        ...(isPrimitive ? { type: PRIMITIVE_INNER_TYPE } : {}),
        value: modifiedValue
      }
    };
  });
}

export const determineStatusForTableRow = (liveValue, value, publishValue, status, incomplete, invalid, duplicate, unassigned) => {
  if (duplicate || status === STATUS_TYPES.DUPLICATE) {
    return STATUS_TYPES.DUPLICATE;
  }

  if (invalid || status === STATUS_TYPES.INVALID) {
    return STATUS_TYPES.INVALID;
  }

  if (incomplete || status === STATUS_TYPES.INCOMPLETE) {
    return STATUS_TYPES.INCOMPLETE;
  }

  if (unassigned || status === STATUS_TYPES.UNASSIGNED) {
    return STATUS_TYPES.UNASSIGNED;
  }

  if (status === STATUS_TYPES.DRAFT) {
    return STATUS_TYPES.DRAFT;
  }

  if (JSON.stringify(value) !== JSON.stringify(publishValue)) {
    return STATUS_TYPES.DRAFT;
  }

  if (status === STATUS_TYPES.PENDING) {
    return STATUS_TYPES.PENDING;
  }

  if (JSON.stringify(liveValue) !== JSON.stringify(publishValue)) {
    return STATUS_TYPES.PENDING;
  }
  return STATUS_TYPES.LIVE;
};

export const noOptionList = (label) => [
  {
    display_key: label ? NO_OPTIONS_MESSAGE.replace("$label", label) : VAGUE_NO_OPTIONS_MESSAGE,
    key: "no_options",
    disabled: true
  }
];

export const errorOptionList = (label) => [
  {
    display_key: NO_OPTIONS_ERROR_MESSAGE.replace("$label", label),
    key: "no_options_error",
    error: true
  }
];

export const extractKeysAndCollectionIdsIfRef = (obj) => {
  const result = [];

  const extract = (obj) => {
    if (obj?.options) {
      obj.options.forEach(field => {
        extract(field);
      });
    } else if (obj?.fields) {
      obj.fields.forEach(field => {
        extract(field);
      });
    } else if (obj?.type === FIELD_TYPES.COLLECTION_REF) {
      result.push({ key: obj.key, collection_id: obj.collection_id, query_params: obj.query_params });
    }
  };

  extract(obj);
  return result;
};

export const isItemMultiLanguageField = (field) => field?.type === FIELD_TYPES.MULTI_LANGUAGE_TEXT || field?.type === FIELD_TYPES.ACTION;

export const getRealFieldValue = (value, language) => (Object.prototype.hasOwnProperty.call(value || {}, "text") && value?.text !== "")
  || (Object.prototype.hasOwnProperty.call(value || {}, language) && value?.[language] !== "")
  || value?.length > 0
  || (typeof value !== "object" && value)
  || Number.isFinite(value);

// eslint-disable-next-line consistent-return
export const isMandatoryField = ({ type, is_mandatory, value, language }) => {
  if (type === FIELD_TYPES.MULTI_LANGUAGE_TEXT) {
    let mandatoryFields = {};

    if (value) {
      Object.keys(value)?.forEach((language) => {
        const val = getRealFieldValue(value[language], language);
        if (is_mandatory && !val) mandatoryFields = { ...mandatoryFields, ...getMandatoryMessageForType(type, language) };
      });
    }

    return Object.keys(mandatoryFields).length === 0 ? "" : mandatoryFields;
  }

  const val = getRealFieldValue(value, language);
  if (is_mandatory && !val && !isBool(value)) return getMandatoryMessageForType(type);
  if (!val && !isBool(value)) return "";
};

export const getMandatoryStar = (isMandatory) => isMandatory ? <span style={{ color: "#EF404B" }}> *</span> : null;

export const doesRowEqualSpec = (row, spec) => {
  const specConfiguration = spec.fields;

  return specConfiguration.every((config) => {
    const value = row[config.display_key];

    switch (config.type) {
    case FIELD_TYPES.MULTI_LANGUAGE_TEXT:
      return Object.keys(value).every((language) => value[language] === config.default[language]);

    case FIELD_TYPES.COLLECTION_REF:
    case FIELD_TYPES.LIST:
      return JSON.stringify(value) === JSON.stringify(config.default);

    case FIELD_TYPES.CHOOSE_ONE:
      return value === config.default.key || value === config.default.configuration.value;

    default:
      return JSON.stringify(value) === JSON.stringify(config.default.value);
    }
  });
};

export const isRowUnique = (row, rows) => {
// eslint-disable-next-line no-unused-vars
  const cleanRow = ({ index, id, [STATUS]: status, fields, spec, path, ...rest }) => rest;

  const cleanRows = rows.filter(({ id }) => id !== row?.id).map(cleanRow);
  const cleanRowToCheck = cleanRow(row);

  if (cleanRows.length === 0) return true;
  return cleanRows.every((collectionRow) => Object.keys(cleanRowToCheck).some((key) => JSON.stringify(cleanRowToCheck[key]) !== JSON.stringify(collectionRow[key])));
};

export const filterOutRowsToDelete = (collection, rowIdsToDelete) => {
  const filterConfiguration = (configuration) => configuration.map((config) => {
    if (config.type === FIELD_TYPES.LIST) {
      return {
        ...config,
        configuration: filterConfiguration(
          config.configuration.filter((innerConfig) => !rowIdsToDelete.includes(innerConfig.key))
        )
      };
    }
    return config;
  });

  return {
    ...collection,
    configuration: {
      ...collection.configuration,
      configuration: filterConfiguration(collection.configuration.configuration)
    }
  };
};

export const getValidationMessage = (validationResults = {}, configurableKey, severity) => {
  const validationKeys = Object.entries(validationResults)
    .filter(([key, results]) =>
      key.includes(configurableKey) && (!severity || results.some(({ error_severity_level }) => error_severity_level === severity))
    )
    .map(([key, results]) => ({ key, results }));

  return validationKeys.length ? validationKeys : null;
};

export const getValidationTypes = (validationResults, configurableKey) => Object.entries(validationResults || {}).reduce((result, [key, results]) => {
  if (key.includes(configurableKey)) {
    results.forEach(({ type, details }) => {
      result[type] = details;
    });
  }
  return result;
}, {});

export const getValidationsByType = (validationResults, type) => {
  if (!validationResults) return [];
  const validationsAsObject = Object.entries(validationResults).filter(([key]) => key.includes("root")).reduce((acc, [key, errors]) => {
    const [fieldType] = key.split("/");

    if (!acc[fieldType]) {
      acc[fieldType] = 0;
    }
    acc[fieldType] += errors.filter(error => error.type === type).length;
    return acc;
  }, {});
  return Object.entries(validationsAsObject).map(([fieldType, amount]) => ({ fieldType, amount }));
};

export const getLanguages = (config) => ({
  list: config?.languages?.map(lang => lang.language),
  default: config?.languages?.find(lang => lang.is_default)?.language
});
