import React from "react";

const FindAProviderIcon = () => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7780_33891)">
      <circle cx="24" cy="24" r="23.5" fill="#F3F3FF" stroke="#DEDFFF"/>
      <path d="M27.6001 28.5H19.6001C17.391 28.5 15.6001 30.2909 15.6001 32.5C15.6001 33.0523 16.0478 33.5 16.6001 33.5H30.6001C31.1524 33.5 31.6001 33.0523 31.6001 32.5C31.6001 30.2909 29.8092 28.5 27.6001 28.5Z" fill="#B3B7FF"/>
      <path d="M23.6001 26C25.8092 26 27.6001 24.2091 27.6001 22C27.6001 19.7909 25.8092 18 23.6001 18C21.391 18 19.6001 19.7909 19.6001 22C19.6001 24.2091 21.391 26 23.6001 26Z" fill="#FFCFDA"/>
      <path d="M15.6001 33.8174C16.2027 34 17.0166 34 18.4001 34H28.8001C30.1836 34 30.9975 34 31.6001 33.8174M15.6001 33.8174C15.4709 33.7783 15.3514 33.7308 15.2381 33.673C14.6736 33.3854 14.2147 32.9265 13.9271 32.362C13.6001 31.7202 13.6001 30.8802 13.6001 29.2V18.8C13.6001 17.1198 13.6001 16.2798 13.9271 15.638C14.2147 15.0735 14.6736 14.6146 15.2381 14.327C15.8799 14 16.7199 14 18.4001 14H28.8001C30.4803 14 31.3203 14 31.9621 14.327C32.5266 14.6146 32.9855 15.0735 33.2731 15.638C33.6001 16.2798 33.6001 17.1198 33.6001 18.8V29.2C33.6001 30.8802 33.6001 31.7202 33.2731 32.362C32.9855 32.9265 32.5266 33.3854 31.9621 33.673C31.8488 33.7308 31.7293 33.7783 31.6001 33.8174M15.6001 33.8174C15.6005 33.0081 15.6053 32.5799 15.677 32.2196C15.9926 30.6329 17.233 28.3925 18.8197 28.0769C19.2061 28 19.6708 28 20.6001 28H26.6001C27.5294 28 27.9941 28 28.3805 28.0769C29.9672 28.3925 31.2076 30.6329 31.5232 32.2196C31.5949 32.5799 31.5997 33.0081 31.6001 33.8174" stroke="#4548C4" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23.6001 25.5C25.8092 25.5 27.6001 23.7091 27.6001 21.5C27.6001 19.2909 25.8092 17.5 23.6001 17.5C21.391 17.5 19.6001 19.2909 19.6001 21.5C19.6001 23.7091 21.391 25.5 23.6001 25.5Z" stroke="#EF4068" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_7780_33891">
        <rect width="48" height="48" fill="white"/>
      </clipPath>
    </defs>
  </svg>
;

export default FindAProviderIcon;
