import { USER_PERMISSIONS } from "@hyro/dashboard-commons";
import {
  Balance as BalanceIcon,
  BuildOutlined as BuildOutlinedIcon,
  ExploreOutlined as ExploreOutlinedIcon,
  HomeOutlined as HomeOutlinedIcon,
  OfflineBoltOutlined as OfflineBoltOutlinedIcon,
  SettingsOutlined as SettingsOutlinedIcon
} from "@mui/icons-material";

import AssistantIcon from "../../icons/AssistantIcon";
import BranchDeployerIcon from "../../icons/BranchDeployerIcon";
import ClockIconMenu from "../../icons/ClockIconMenu";
import ConversationsIcon from "../../icons/ConversationsIcon";
import DashboardSettingsIcon from "../../icons/DashboardSettingsIcon";
import DataSourceIcon from "../../icons/DataSourcesIcon";
import EditorIcon from "../../icons/EditorIcon";
import findProviderIcon from "../../icons/findProviderIcon";
import FolderIcon from "../../icons/FolderIcon";
import GridIcon from "../../icons/GridIcon";
import InsightsIcon from "../../icons/InsightsIcon";
import IntegrationIcon from "../../icons/IntegrationIcon";
import ListIcon from "../../icons/ListIcon";
import {
  ACCOUNT_MANAGEMENT,
  ALL_ASSISTANTS,
  AUDIT_LOG, BACK_OFFICE,
  BRANCH_DEPLOYER,
  BUILDING_BLOCKS,
  CHANNEL_SETTINGS,
  CHAT_INSIGHTS,
  CONVERSATIONS,
  CORE_HYRO_KPIS,
  DATA_SOURCES,
  FIND_A_PROVIDER,
  GENERAL_SETTINGS,
  HOME,
  INSIGHTS,
  INTEGRATIONS,
  KNOWLEDGE_DEBUGGER,
  KNOWLEDGE_EDITOR,
  KNOWLEDGE_EXPLORER,
  PIPELINE_PERFORMANCE,
  PROMPT_PLAYGROUND,
  QUESTION_GENERATOR,
  RESPONSES,
  SETTINGS,
  SKILLS,
  SYNONYMS,
  VOICE_INSIGHTS
} from "../../utils";

const filterNavItemByPermission = hasPermission => (navItem) => {
  if (navItem.children) {
    navItem.children = navItem.children.filter(filterNavItemByPermission(hasPermission));
  }
  return navItem.requiredPermission
    ? hasPermission(navItem.requiredPermission)
    : true;
};

const getGeneralNavItems = (
  accountId,
  selectedAssistantId,
  selectedConversationId = null
) => [
  {
    key: HOME,
    text: "Home",
    icon: HomeOutlinedIcon,
    url: `/${accountId}/${selectedAssistantId}/${HOME}`,
    requiredPermission: USER_PERMISSIONS.HOME_PAGE.READ
  },
  {
    key: INSIGHTS,
    text: "Insights",
    description: "Loading data may take time, it refreshes every 5 minutes.",
    url: `/${accountId}/${selectedAssistantId}/${INSIGHTS}`,
    icon: InsightsIcon,
    requiredPermission: USER_PERMISSIONS.INSIGHTS_PAGE.READ
  },
  {
    key: CONVERSATIONS,
    text: `Conversation: ${selectedConversationId}`,
    icon: ConversationsIcon,
    url: `/${accountId}/${selectedAssistantId}/${CONVERSATIONS}/${selectedConversationId}`,
    requiredPermission: "HACK_TO_NOT_SHOW_IN_NAV_DONT_REMOVE_ME"
  },
  {
    key: RESPONSES,
    text: "Responses",
    icon: ListIcon,
    url: `/${accountId}/${selectedAssistantId}/${RESPONSES}`,
    requiredPermission: USER_PERMISSIONS.RESPONSES_PAGE.READ
  },
  {
    key: CONVERSATIONS,
    text: "Conversations",
    description: "The data is refreshed every 5 minutes.",
    icon: ConversationsIcon,
    url: `/${accountId}/${selectedAssistantId}/${CONVERSATIONS}`,
    requiredPermission: USER_PERMISSIONS.CONVERSATIONS_PAGE.READ
  },
  {
    key: KNOWLEDGE_EDITOR,
    text: "Knowledge Editor",
    icon: EditorIcon,
    url: `/${accountId}/${selectedAssistantId}/${KNOWLEDGE_EDITOR}`,
    requiredPermission: USER_PERMISSIONS.KNOWLEDGE_EDITOR_PAGE.READ
  },
  {
    key: SKILLS,
    text: "Skills",
    icon: OfflineBoltOutlinedIcon,
    url: `/${accountId}/${selectedAssistantId}/${SKILLS}`,
    requiredPermission: USER_PERMISSIONS.SKILLS_PAGE.READ
  },
  {
    key: SETTINGS,
    text: "Settings",
    icon: SettingsOutlinedIcon,
    children: [
      {
        key: `${SETTINGS}/${GENERAL_SETTINGS}`,
        text: "General",
        url: `/${accountId}/${selectedAssistantId}/settings/${GENERAL_SETTINGS}`,
        requiredPermission: USER_PERMISSIONS.SETTINGS_PAGE.READ
      },
      {
        key: `${SETTINGS}/${CHANNEL_SETTINGS}`,
        text: "Channel",
        url: `/${accountId}/${selectedAssistantId}/settings/${CHANNEL_SETTINGS}`,
        requiredPermission: USER_PERMISSIONS.SETTINGS_PAGE.READ
      }
    ],
    requiredPermission: USER_PERMISSIONS.SETTINGS_PAGE.READ
  }
];

const getAdminNavItems = () => [
  {
    key: ALL_ASSISTANTS,
    text: "All Assistants",
    icon: AssistantIcon,
    url: `/admin/${ALL_ASSISTANTS}`
  },
  {
    key: ACCOUNT_MANAGEMENT,
    text: "Account Management",
    icon: FolderIcon,
    url: `/admin/${ACCOUNT_MANAGEMENT}`
  },
  {
    key: BRANCH_DEPLOYER,
    text: "Branch Deployer",
    icon: BranchDeployerIcon,
    url: `/admin/${BRANCH_DEPLOYER}`
  },
  {
    key: INSIGHTS,
    text: "Insights",
    icon: InsightsIcon,
    children: [
      {
        key: `${INSIGHTS}/${VOICE_INSIGHTS}`,
        text: "Voice",
        url: `/admin/insights/${VOICE_INSIGHTS}`
      },
      {
        key: `${INSIGHTS}/${CHAT_INSIGHTS}`,
        text: "Chat",
        url: `/admin/insights/${CHAT_INSIGHTS}`
      },
      {
        key: `${INSIGHTS}/${PIPELINE_PERFORMANCE}`,
        text: "Pipeline Performance",
        url: `/admin/insights/${PIPELINE_PERFORMANCE}`
      },
      {
        key: `${INSIGHTS}/${CORE_HYRO_KPIS}`,
        text: "Core Hyro KPIs",
        url: `/admin/insights/${CORE_HYRO_KPIS}`
      }
    ]
  },
  {
    key: BACK_OFFICE,
    text: "Back Office",
    icon: BuildOutlinedIcon,
    children: [
      {
        key: `${BACK_OFFICE}/${KNOWLEDGE_DEBUGGER}`,
        text: "Debugger",
        url: `/admin/back-office/${KNOWLEDGE_DEBUGGER}`
      },
      {
        key: `${BACK_OFFICE}/${KNOWLEDGE_EXPLORER}`,
        text: "Explorer",
        url: `/admin/back-office/${KNOWLEDGE_EXPLORER}`
      },
      {
        key: `${BACK_OFFICE}/${PROMPT_PLAYGROUND}`,
        text: "Prompt playground",
        url: `/admin/back-office/${PROMPT_PLAYGROUND}`
      },
      {
        key: `${BACK_OFFICE}/${QUESTION_GENERATOR}`,
        text: "Question generator",
        url: `/admin/back-office/${QUESTION_GENERATOR}`
      }
    ]
  },
  {
    key: AUDIT_LOG,
    text: "Audit Log",
    icon: ClockIconMenu,
    url: `/admin/${AUDIT_LOG}`,
    requiredPermission: USER_PERMISSIONS.AUDIT_LOG.READ
  },
  {
    key: SETTINGS,
    text: "Settings",
    icon: DashboardSettingsIcon,
    url: `/admin/${SETTINGS}`
  }
];
const getHyroNavItems = (accountId, selectedAssistantId) => [
  {
    key: BUILDING_BLOCKS,
    text: "Building Blocks",
    icon: GridIcon,
    url: `/${accountId}/${selectedAssistantId}/${BUILDING_BLOCKS}`,
    requiredPermission: USER_PERMISSIONS.BUILDING_BLOCKS_PAGE.READ
  },
  {
    key: INTEGRATIONS,
    text: "Integrations",
    icon: IntegrationIcon,
    url: `/${accountId}/${selectedAssistantId}/${INTEGRATIONS}`,
    requiredPermission: USER_PERMISSIONS.INTEGRATIONS_PAGE.READ
  },
  {
    key: DATA_SOURCES,
    text: "Data Sources",
    icon: DataSourceIcon,
    url: `/${accountId}/${selectedAssistantId}/${DATA_SOURCES}`,
    requiredPermission: USER_PERMISSIONS.DATA_SOURCES_PAGE.READ
  },
  {
    key: FIND_A_PROVIDER,
    text: "Find a Provider",
    icon: findProviderIcon,
    url: `/${accountId}/${selectedAssistantId}/${FIND_A_PROVIDER}`,
    requiredPermission: USER_PERMISSIONS.FIND_A_PROVIDER_PAGE.READ
  },
  {
    key: KNOWLEDGE_EXPLORER,
    text: "Knowledge Explorer",
    icon: ExploreOutlinedIcon,
    url: `/${accountId}/${selectedAssistantId}/${KNOWLEDGE_EXPLORER}`,
    requiredPermission: USER_PERMISSIONS.KNOWLEDGE_EXPLORER_PAGE.READ
  },
  {
    key: SYNONYMS,
    text: "Synonyms",
    icon: BalanceIcon,
    url: `/${accountId}/${selectedAssistantId}/${SYNONYMS}`,
    requiredPermission: USER_PERMISSIONS.SYNONYMS_PAGE.READ
  },
  {
    key: AUDIT_LOG,
    text: "Audit Log",
    icon: ClockIconMenu,
    url: `/${accountId}/${selectedAssistantId}/${AUDIT_LOG}`,
    requiredPermission: USER_PERMISSIONS.AUDIT_LOG.READ
  }
];
export {
  filterNavItemByPermission,
  getGeneralNavItems,
  getHyroNavItems,
  getAdminNavItems
};
