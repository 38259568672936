import { get } from "src/services/api";
import { ADMIN_DASHBOARD_SETTINGS_ENDPOINT, DASHBOARD_SETTINGS_ENDPOINT } from "src/components/admin/Settings/useSettings";
import useAppStore from "src/stores/appStore";

export const fetchDashboardSettings = async (isAdminPanel) => {
  const url = isAdminPanel ? ADMIN_DASHBOARD_SETTINGS_ENDPOINT : DASHBOARD_SETTINGS_ENDPOINT;
  const dashboardSettings = await get(url);
  useAppStore.getState().setDashboardSettings(dashboardSettings);
  return dashboardSettings;
};
