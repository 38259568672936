import { Stack, Popper, styled, autocompleteClasses } from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledAutocomplete, StyledTextField } from "src/components/common/styles";
import CloseIcon from "src/icons/CloseIcon";
import ChipContainer from "src/pages/KnowledgeEditor/Table/ChipContainer";

const StyledChipContainer = styled(Stack,
  { shouldForwardProp: (prop) => prop !== "showAllChips" })(({ showAllChips }) => ({
  overflow: "scroll",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "8px",
  maxHeight: "120px",
  paddingBlock: showAllChips ? "3px" : "0"
}));

const PopperContainer = styled(Stack)(() => ({
  maxHeight: "135px",
  overflow: "scroll",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "8px",
  padding: "16px 12px",
  borderRadius: "8px",
  backgroundColor: "#fff",
  boxShadow: "0px 4px 8px 0px #0000001F, 0px 2px 2px 2px #00000003"
}));

// eslint-disable-next-line max-lines-per-function
const MultipleValues = (
  {
    id,
    values,
    onChange,
    onDelete,
    onFocus,
    label,
    required,
    options,
    helperText,
    error,
    disabled,
    index,
    isReadOnly,
    showLabel,
    isFieldReadOnlyFromSpec,
    fieldType,
    testId,
    open = false,
    maxPopperWidth,
    isFromTable,
    showAllChips,
    showTagsInPopper = true,
    sx
  }) => {
  const [value, setValue] = useState("");
  const [isFocused, setIsFocused] = useState(open);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (values.length === 0) {
      setValue("");
    }
  }, [values]);

  const handlePaste = (event) => {
    event.preventDefault();

    const pastedText = event.clipboardData.getData("text");
    const pastedValues = pastedText.split("|").map(value => value.trim()).filter(Boolean);
    const uniqueValues = [...new Set([...values, ...pastedValues])];

    if (uniqueValues.length !== values.length) {
      onChange(event, uniqueValues);
    }
  };

  const handleFocus = (event) => {
    onFocus?.(event);
    setAnchorEl(event.currentTarget);
    setIsFocused(true);
  };

  const handleAutoCompleteChange = (event, newValue) => {
    const isClearIconClicked = Array.isArray(newValue) && newValue.length === 0;

    if (isClearIconClicked) {
      setValue("");
    } else {
      const newVal = [...new Set(newValue.map(value => value.trim()))];
      onChange(event, newVal);
      setValue("");
    }
  };

  return (
    <StyledAutocomplete
      sx={{ ...sx, [`& .${autocompleteClasses.endAdornment}'`]: { top: "50%", transform: "translateY(-50%)" } }}
      id={id}
      options={options || []}
      value={values}
      multiple
      freeSolo
      clearOnEscape
      clearOnBlur
      clearIcon={isFocused && value.length ? <CloseIcon color="#2D2C44B2" fontSize="small" data-testid={`${testId ? `${testId}-` : ""}${fieldType}-${index}-clear-icon`} /> : null}
      onFocus={handleFocus}
      onBlur={(e) => {
        setIsFocused(false);
        setAnchorEl(null);

        const trimmedValue = value.trim();

        if (trimmedValue && !values.includes(trimmedValue)) {
          onChange(e, [...values, trimmedValue]);
          setValue("");
        }
      }}
      disabled={disabled || isReadOnly}
      onInputChange={(event, newInputValue) => {
        setValue(newInputValue);
      }}
      onChange={handleAutoCompleteChange}
      componentsProps={{
        paper: { elevation: 3 },
        popper: { sx: { paddingBottom: "12px" } },
        endAdornment: { sx: { display: "none" } }
      }}
      // eslint-disable-next-line complexity
      renderTags={() =>
        <>
          {(showTagsInPopper && isFocused && !isReadOnly) ?
            <Popper
              open={isFocused && Boolean(anchorEl) && values.length > 0}
              anchorEl={anchorEl}
              placement="bottom-start"
              modifiers={[{ name: "offset", options: { offset: (helperText && !isFromTable) ? [0, -14] : [0, 4] } }]}
              sx={{ minWidth: anchorEl?.clientWidth, maxWidth: maxPopperWidth || anchorEl?.clientWidth, zIndex: (theme) => sx?.zIndex || theme.zIndex.modal + 1 }}
            >
              <PopperContainer>
                <ChipContainer
                  values={values}
                  showAllChips
                  testId={testId ? `${testId}-${fieldType}` : `${fieldType}`}
                  onDelete={onDelete}
                  isReadOnly={isReadOnly}/>
              </PopperContainer>
            </Popper>
            :
            <StyledChipContainer showAllChips={showAllChips}>
              <ChipContainer
                values={values}
                showAllChips={showAllChips}
                testId={testId ? `${testId}-${fieldType}` : `${fieldType}`}
                onDelete={onDelete}
                isReadOnly={isReadOnly}/>
            </StyledChipContainer>}
        </>
      }
      renderInput={params => (
        <StyledTextField
          {...params}
          autoFocus={open}
          isFieldReadOnlyFromSpec={isFieldReadOnlyFromSpec}
          readOnly={isReadOnly}
          value={value}
          variant="outlined"
          label={showLabel ? label : ""}
          placeholder={values.length ? "" : label}
          FormHelperTextProps={{ "data-testid": `${testId ? `${testId}-` : ""}${fieldType}-${index}-helper` }}
          helperText={helperText}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off",
            "data-testid": `${testId ? `${testId}-` : ""}${fieldType}-${index}`,
            sx: showAllChips && values?.length > 0 && showTagsInPopper ? { display: "none" } : {}
          }}
          required={required}
          error={!!error}
          onPaste={handlePaste}
        />
      )}
    />
  );
};

export default MultipleValues;
