import React, { useEffect, useRef, useState } from "react";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import { StyledButton, StyledDivider } from "../HeaderStyles";
import AccountsMenu from "../AccountsMenu";
import { BUILD_STATUSES, capitalize, SEVERITY_VARIANTS } from "../../../utils";
import UserMenu from "../UserMenu";
import NotificationsPopover from "src/layout/Header/notifications-menu/NotificationsPopover";
import StarIcon from "../../../icons/StarIcon";
import useAppStore from "../../../stores/appStore";
import {
  draftSelector,
  handleDraftTripletsSelector,
  infoPopoversSelector,
  isPublishingTripletsSelector,
  isSavingTripletsSelector,
  setInfoPopoversSelector
} from "../../../stores/selectors/tripletsSelectors";
import {
  collectionConfigChangedSelector,
  hasValidationErrors,
  isSavingChangesSelector,
  isUpdatingCollectionSelector,
  selectedCollectionSelector,
  setCollectionConfigChangedSelector,
  setDialogErrorSelector,
  updateCollectionSelector,
  validationResultsSelector
} from "../../../stores/selectors/collectionsSelectors";
import {
  buildStatusPollingInProgressSelector,
  setShouldRecreatePreviewSelector
} from "../../../stores/selectors/previewSelectors";
import { notifyPublishChanges } from "../../../analytics/notifyCustomActions";
import PublishDialog from "./status-header/DraftPublishing/PublishDialog";
import ArrowLeftIcon from "../../../icons/ArrowLeftIcon";
import PreviewButton from "../PreviewButton";
import {
  assistantIdSelector,
  isReadOnlyModeSelector,
  pageNameSelector,
  setIsAssistantPreviewableSelector,
  setSystemModeSelector, userDataSelector
} from "../../../stores/selectors/appSelectors";
import { SYSTEM_MODES } from "../../../stores/slices/createAppSlice";
import useDraftPublishHeader from "./status-header/DraftPublishing/useDraftPublishHeader";
import Dialog from "src/components/common/Dialogs/HyroUiDialog";
import {
  BUILD_TOOLTIP,
  DONT_SHOW_AGAIN_STORAGE,
  POPOVERS,
  REFRESH_PAGE, SET_LIVE_TOOLTIP
} from "./status-header/DraftPublishing/DraftHeaderConsts";
import { DIALOG_VARIANTS } from "../../../components/common/Dialogs/DialogConsts";
import InfoPopover from "./status-header/DraftPublishing/InfoPopover";
import { EXIT_EDIT_MODE_DIALOG } from "../../consts";
import { get, post } from "src/services/api";
import CubeIcon from "src/icons/CubeIcon";
import { getValidationsByType } from "src/components/collections/helpers";
import { VALIDATION_TYPES } from "src/components/collections/consts";
import { useFetchDashboardSettings } from "src/queries/hooks/settings";
import { useMutateCollection } from "src/queries/hooks/collections";
import Tooltip from "src/components/common/Tooltip";

// eslint-disable-next-line max-lines-per-function, complexity
const EditModeHeader = ({ selectedAssistant, setFeedbackConfig }) => {
  const theme = useTheme();
  const setIsAssistantPreviewable = useAppStore(setIsAssistantPreviewableSelector);
  const forceDisableBuild = useAppStore(state => state.forceDisableBuild);
  const [fetchingStatus, setFetchingStatus] = useState(false);
  const buildDraftResponse = useAppStore(state => state.buildDraftResponse);
  const setBuildDraftResponse = useAppStore(state => state.setBuildDraftResponse);
  const [publishConfirmationOpen, setPublishConfirmationOpen] = useState(false);
  const actionButtonRef = useRef();
  const notificationButtonRef = useRef();
  const setNotifications = useAppStore(state => state.setNotifications);
  const setSystemMode = useAppStore(setSystemModeSelector);
  const selectedCollection = useAppStore(selectedCollectionSelector);
  const updateCollection = useAppStore(updateCollectionSelector);
  const setCollectionConfigChanged = useAppStore(setCollectionConfigChangedSelector);
  const isSavingChanges = useAppStore(isSavingChangesSelector);
  const isPublishingTriplets = useAppStore(isPublishingTripletsSelector);
  const isUpdatingCollection = useAppStore(isUpdatingCollectionSelector);
  const isSavingTriplets = useAppStore(isSavingTripletsSelector);
  const buildStatusPollingInProgress = useAppStore(buildStatusPollingInProgressSelector);
  const collectionConfigChanged = useAppStore(collectionConfigChangedSelector);
  const setShouldRecreatePreview = useAppStore(setShouldRecreatePreviewSelector);
  const hasAssistantValidationErrors = useAppStore(hasValidationErrors);
  const draft = useAppStore(draftSelector);
  const [publishCurrentTripletsToDraft] = useAppStore(handleDraftTripletsSelector);
  const [isExitDialogOpen, setIsExitDialogOpen] = useState(false);
  const isReadOnly = useAppStore(isReadOnlyModeSelector);
  const userData = useAppStore(userDataSelector);
  const dashboardSettings = useFetchDashboardSettings({ enabled: !!userData?.accessToken })?.data;
  const pageName = useAppStore(pageNameSelector);
  const [snackbarAlert, setSnackbarAlert] = useState({
    open: false,
    message: "",
    severity: ""
  });
  const selectedAssistantId = useAppStore(assistantIdSelector);
  const dontShowPopovers = {
    draftExpiration: localStorage.getItem(DONT_SHOW_AGAIN_STORAGE.DRAFT_EXPIRATION),
    buildDraft: localStorage.getItem(DONT_SHOW_AGAIN_STORAGE.BUILD_DRAFT),
    setLive: localStorage.getItem(DONT_SHOW_AGAIN_STORAGE.SET_LIVE)
  };
  const infoPopovers = useAppStore(infoPopoversSelector);
  const setInfoPopovers = useAppStore(setInfoPopoversSelector);
  const pollBuildStatus = useAppStore(state => state.pollBuildStatus);
  const setDialogError = useAppStore(setDialogErrorSelector);
  const validationResults = useAppStore(validationResultsSelector);
  const unassigned = getValidationsByType(validationResults, VALIDATION_TYPES.UNASSIGNED);
  const updateCollectionMutation = useMutateCollection();

  useEffect(() => {
    setIsAssistantPreviewable();

    if (selectedAssistantId && !fetchingStatus && draft) {
      try {
        pollBuildStatus();
      } catch (err) {
        console.error(err);
        setDialogError(true);
        setFetchingStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssistantId, draft, setBuildDraftResponse, pollBuildStatus, fetchingStatus, setIsAssistantPreviewable]);

  const inProgressOrTestIsRunning
    = isSavingTriplets
    || isPublishingTriplets
    || buildStatusPollingInProgress
    || isUpdatingCollection;

  const isPublishDisabled = dashboardSettings?.disablePublish
    || !draft
    || inProgressOrTestIsRunning
    || hasAssistantValidationErrors
    || isReadOnly
    || buildDraftResponse.status !== BUILD_STATUSES.LIVE;

  const isBuildDisabled = !draft
    || hasAssistantValidationErrors
    || isUpdatingCollection
    || isReadOnly
    || buildDraftResponse.status === BUILD_STATUSES.LIVE
    || buildDraftResponse.status === BUILD_STATUSES.PENDING
    || buildDraftResponse.status === BUILD_STATUSES.KG_UPDATE;

  const isBuildDisplayed = buildDraftResponse.status === ""
    || buildDraftResponse.status === BUILD_STATUSES.PENDING
    || buildDraftResponse.status === BUILD_STATUSES.KG_UPDATE
    || buildDraftResponse.status === BUILD_STATUSES.CHANGED
    || forceDisableBuild;

  const isBuilding = buildDraftResponse.status === BUILD_STATUSES.PENDING
    || buildDraftResponse.status === BUILD_STATUSES.KG_UPDATE;

  const getBuildTooltip = () => {
    if (!draft) return BUILD_TOOLTIP.NO_CHANGES;
    if (hasAssistantValidationErrors) return BUILD_TOOLTIP.MANDATORY_MISSING;
    if (isBuilding) return BUILD_TOOLTIP.IN_PROGRESS;

    return "";
  };

  const getSetLiveTooltip = () => {
    if (isPublishingTriplets || isReadOnly) return SET_LIVE_TOOLTIP.IN_PROGRESS;
    if (isPublishDisabled) return SET_LIVE_TOOLTIP.BUILD_NEEDED;

    return "";
  };

  const handleClickPublish = async ({ changeDescription }) => {
    setSnackbarAlert({
      open: true,
      message: "Publishing changes",
      severity: SEVERITY_VARIANTS.SUCCESS
    });
    await publishCurrentTripletsToDraft(changeDescription);
    setPublishConfirmationOpen(false);
    setInfoPopovers(DONT_SHOW_AGAIN_STORAGE.SET_LIVE, true);
    handleCloseSnackbar(setSnackbarAlert);
  };

  const handleCloseSnackbar = () => setSnackbarAlert({ ...snackbarAlert, open: false });

  const handleExitClick = () => {
    if (collectionConfigChanged) {
      setIsExitDialogOpen(true);
    } else {
      setSystemMode(SYSTEM_MODES.READ_ONLY);
    }
  };

  const handleDiscard = () => {
    setIsExitDialogOpen(false);
    setSystemMode(SYSTEM_MODES.READ_ONLY);
  };

  const handleSubmit = async () => {
    await updateCollection(selectedCollection, updateCollectionMutation);
    setCollectionConfigChanged(false);
    setIsExitDialogOpen(false);
    setSystemMode(SYSTEM_MODES.READ_ONLY);
  };

  const handleBuild = async () => {
    try {
      setFetchingStatus(true);
      const statusResponse = await post(`/assistants/${selectedAssistantId}/build`,
        { branchName: draft?.branchName }, undefined, { pageName });
      const notifications = await get(`/assistants/${selectedAssistantId}/notifications`);
      setNotifications(notifications);
      setBuildDraftResponse(statusResponse);
      setShouldRecreatePreview(true);
      setFetchingStatus(false);
      const popover = unassigned.length > 0 ? DONT_SHOW_AGAIN_STORAGE.UNASSIGNED : DONT_SHOW_AGAIN_STORAGE.BUILD_DRAFT;
      setInfoPopovers(popover, true);
    } catch (e) {
      console.error(e);
      setBuildDraftResponse({ status: "", url: "" });
      setDialogError(true);
      setFetchingStatus(false);
    }
  };

  const handleGotIt = (type, checked) => {
    checked
      ? localStorage.setItem(type, "true")
      : localStorage.removeItem(type);
    setInfoPopovers(type, checked);
  };

  const draftErrorsDialog = useDraftPublishHeader();

  return <>
    <Stack direction='row'>
      <Stack sx={{ width: 216 }} direction='row'>
        <Button
          data-testid="exit-edit-mode-button"
          onClick={handleExitClick}
          startIcon={<ArrowLeftIcon />}
          sx={{ ml: "-12px", px: "12px", lineHeight: "20px" }}>
          Exit edit mode
        </Button>
        <StyledDivider sx={{ ml: "auto" }} orientation="vertical" flexItem />
      </Stack>
      <AccountsMenu />
      <Stack direction='row' sx={{ ml: "auto" }} alignItems={"center"} justifyContent='center' gap={1}>
        {selectedAssistant ? <>
          <PreviewButton isButton />
          <div ref={actionButtonRef}>
            {isBuildDisplayed ?
              <Tooltip title={getBuildTooltip()} testId="build-button-tooltip">
                <StyledButton
                  data-testid="page-build-button"
                  extraPadding={!isBuilding}
                  loading={fetchingStatus}
                  loadingPosition="start"
                  startIcon={<CubeIcon disabled={isBuildDisabled || forceDisableBuild} />}
                  onClick={handleBuild}
                  disabled={forceDisableBuild || (!fetchingStatus && isBuildDisabled)}
                  variant="outlined"
                >
                  {isBuilding ? "Building..." : "Build"}
                </StyledButton>
              </Tooltip>
              :
              <Tooltip title={getSetLiveTooltip()} testId="set-live-button-tooltip">
                <StyledButton
                  disabled={isPublishDisabled}
                  variant="contained"
                  startIcon={<StarIcon disabled={isPublishDisabled} />} color='secondary'
                  onClick={() => {
                    notifyPublishChanges("Publish");
                    setPublishConfirmationOpen(true);
                  }}
                >
                  Set live
                </StyledButton>
              </Tooltip>}

          </div>
          <NotificationsPopover ref={notificationButtonRef} setFeedbackConfig={setFeedbackConfig} />
        </> : null}
        <UserMenu />
      </Stack>
    </Stack>
    <PublishDialog
      open={publishConfirmationOpen}
      onClose={() => setPublishConfirmationOpen(false)}
      handleSubmit={handleClickPublish}
    />
    {draftErrorsDialog.open ? <Dialog
      open={draftErrorsDialog.open}
      title={draftErrorsDialog.title}
      submitButtonText={REFRESH_PAGE}
      handleSubmit={() => location.reload()}
      handleCancel={draftErrorsDialog.handleCancel}
      variant={DIALOG_VARIANTS.transactional}
    >
      <Typography>{draftErrorsDialog.text}</Typography>
    </Dialog> : null}
    <InfoPopover
      open={infoPopovers.buildDraft && !dontShowPopovers.buildDraft}
      data={POPOVERS.BUILD_DRAFT}
      anchorRef={actionButtonRef}
      onGotIt={(checked) => handleGotIt(DONT_SHOW_AGAIN_STORAGE.BUILD_DRAFT, checked)} />
    <InfoPopover
      open={infoPopovers.unassigned}
      data={POPOVERS.UNASSIGNED}
      enableCheckbox={false}
      slots={{
        body: <div>
          <Typography variant='body2'>{POPOVERS.UNASSIGNED.body}</Typography>
          <ul style={{ margin: "8px 0", padding: "0 16px" }}>
            {unassigned?.map(({ fieldType, amount }) => <li style={{ ...theme.typography.body2 }} key={fieldType}>
              {amount} {capitalize(amount > 1 ? fieldType : fieldType.slice(0, -1))}
            </li>)}
          </ul>
        </div>
      }}
      anchorRef={actionButtonRef}
      onGotIt={() => setInfoPopovers(DONT_SHOW_AGAIN_STORAGE.UNASSIGNED, false)} />
    <InfoPopover
      open={infoPopovers.setLive && !dontShowPopovers.setLive}
      data={POPOVERS.SET_LIVE}
      anchorRef={notificationButtonRef}
      onGotIt={(checked) => handleGotIt(DONT_SHOW_AGAIN_STORAGE.SET_LIVE, checked)}
    />
    <Dialog
      handleClose={() => setIsExitDialogOpen(false)}
      handleCancel={handleDiscard}
      handleSubmit={handleSubmit}
      open={isExitDialogOpen}
      cancelButtonText={EXIT_EDIT_MODE_DIALOG.CANCEL_BUTTON_TEXT}
      submitButtonText={EXIT_EDIT_MODE_DIALOG.SUBMIT_BUTTON_TEXT}
      submitButtonLoading={isSavingChanges}
      title={EXIT_EDIT_MODE_DIALOG.TITLE}
      variant={DIALOG_VARIANTS.transactional}
      showCloseButton
    >
      <Typography>{EXIT_EDIT_MODE_DIALOG.TEXT}</Typography>
    </Dialog>
  </>;
};

export default EditModeHeader;
